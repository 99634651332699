import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';
import * as fromReducer from './customer.reducer';

export const selectModuleState = createSelector(getRootState, rootState => rootState.customer);
export const { selectAll } = fromReducer.adapter.getSelectors(selectModuleState);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectCustomer = createSelector(selectModuleState, state =>
  state.selectedCustomerId ? state.entities[state.selectedCustomerId] : null,
);
