import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

export const selectModuleState = createSelector(getRootState, rootState => rootState.newsArticles);

export const selectPromotedArticlesLoading = createSelector(selectModuleState, state => state.promotedArticlesLoading);
export const selectPromotedNewsArticlesList = createSelector(selectModuleState, state => state.promotedNewsArticles ?? []);
export const selectPromotedNewsArticlesCount = createSelector(selectModuleState, state => state.promotedNewsArticles.length);

export const selectFilteredArticlesLoading = createSelector(selectModuleState, state => state.filteredArticlesLoading);
export const selectFilteredNewsArticlesList = createSelector(selectModuleState, state => state.filteredNewsArticles ?? []);

export const selectOpenedNewsArticle = createSelector(selectModuleState, state => state.openedNewsArticle);
export const selectIsOpenedNewsArticleLoading = createSelector(selectModuleState, state => state.isOpenedNewsArticleLoading);

export const selectCreateNewsArticleCommentInProgress = createSelector(selectModuleState, state => state.createCommentInProgress);

export const selectNewsArticleComments = createSelector(selectModuleState, state => state.comments);
export const selectNewsArticleCommentsLoading = createSelector(selectModuleState, state => state.commentsLoading);
export const selectIsReactionLoading = createSelector(selectModuleState, state => state.isReactionLoading);
