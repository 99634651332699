import { getDateWithZeroTime } from '@1clickfactory/common/helpers/date.helpers';
import { downloadBlobUrlFileToBrowser } from '@1clickfactory/common/helpers/file.helpers';
import { NotificationMessage, showNotification } from '@1clickfactory/notifications';
import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { DocumentResponse, IDocument } from '../document.model';
import * as fromActions from './documents-list.actions';
import { DocumentsService } from './documents-list.service';

@Injectable()
export class DocumentsListEffects {
  onGetDocumentsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.query),
      switchMap(({ range }) => this.documentsService.getAll(range).pipe(filter(documentsList => !!documentsList))),
      map(documentsList => documentsList.map(doc => this.mapDocumentStringDates(doc))),
      map(documentsList => fromActions.queryComplete({ documentsList })),
      catchError(({ error }) => of(fromActions.queryError({ err: error }))),
    ),
  );

  onGetDocumentsListError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.queryError),
      map(({ err }) =>
        showCustomNotification({
          message: `Status: ${err.errorCode ?? 'Unknown'}. Message: ${err.errorMessage ?? 'Unknown error occured.'}`,
        }),
      ),

    ),
  );

  onCopyExternalLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.copyExternalLink),
      map(({ userFriendlyName }) => {
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        this.clipboard.copy(`${baseUrl}/documents/${userFriendlyName}/download`);
        return showNotification({ message: NotificationMessage.CopyExternalLinkSuccess });
      }),
    ),
  );

  onDownloadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadFile),
      mergeMap(({ id }) =>
        this.documentsService.getDownloadUrlById(id).pipe(
          map(({ URL }) => fromActions.downloadFileComplete({ url: URL })),
          catchError(() => of(fromActions.downloadFileLinkError())),
        ),
      ),
    ),
  );

  onDownloadFileComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.downloadFileComplete),
        map(({ url }) => downloadBlobUrlFileToBrowser(url)),
      ),
    { dispatch: false },
  );

  onDownloadFileError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadFileLinkError),
      map(() => showNotification({ message: NotificationMessage.DownloadError })),
    ),
  );

  onShareDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.shareDocument),
      mergeMap(({ userFriendlyName }) =>
        this.documentsService.shareDocument(userFriendlyName).pipe(
          map(({ URL }) => fromActions.shareDocumentComplete({ url: URL })),
          catchError(() => of(fromActions.shareDocumentError())),
        ),
      ),
    ),
  );

  onShareDocumentComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.shareDocumentComplete),
        map(({ url }) => downloadBlobUrlFileToBrowser(url)),
      ),
    { dispatch: false },
  );

  onShareDocumentError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.shareDocumentError),
      map(() => showNotification({ message: NotificationMessage.DownloadError })),
    ),
  );

  constructor(private actions$: Actions, private documentsService: DocumentsService, private clipboard: Clipboard) {}

  private mapDocumentStringDates = (doc: DocumentResponse): IDocument => {
    return {
      ...doc,
      validFrom: getDateWithZeroTime(doc.validFrom),
      validTo: getDateWithZeroTime(doc.validTo),
      publishingDate: getDateWithZeroTime(doc.publishingDate),
    };
  };
}
