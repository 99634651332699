import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import * as fromActions from './actions'
import { StateService } from './state.service'

@Injectable()
export class StateEffects {
  onQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.queryStates),
      mergeMap(() =>
        this.stateService.getAll().pipe(
          map(states => fromActions.queryStatesComplete({ states })),
          catchError(() => of(fromActions.queryStatesError())),
        ),
      ),
    ),
  )

  constructor(private actions$: Actions, private stateService: StateService) {}
}
