import { HttpClient } from '@angular/common/http';
import { identitySelectors } from '@appState';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core';
import { Observable, filter, switchMap } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';

export class AskookTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  getTranslation(lang: string): Observable<any> {
    return this.store.pipe(
      select(identitySelectors.selectIsAuthenticated),
      filter(isAuthenticated => isAuthenticated),
      switchMap(() =>
        this.http.get<any>(
          `${environment.highlanderBffUrl}/gateway/umbraco/translations/GetForLocaleNormalized/?baseKey=partner&lang=en-us`,
        ),
      ),
    );
  }
}

export class AskookMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    if (environment.showMissingTranslations) {
      // eslint-disable-next-line no-console
      console.info(`Missing ${params.key} translations`);
      return `!${params.key}!`;
    } else {
      return '';
    }
  }
}

export const translationsRootConfig: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useClass: AskookTranslateLoader,
    deps: [HttpClient, Store],
  },
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: AskookMissingTranslationHandler,
  },
  useDefaultLang: true,
  defaultLanguage: 'en',
};
