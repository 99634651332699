export const backend = {
  Cms: {
    PageContent: 'PageContent',
    TourContent: 'FeatureIntroduction',
  },
  Users: {
    Accounts: 'AccountsAPI',
    Partners: 'PartnerApi',
    ResetPassword: 'ResetPasswordsAPI',
    ChangePassword: 'ChangePasswordsAPI',
    EmailValidator: 'EmailValidator',
    UserProfile: 'UserProfilesApi',
    Agreements: 'AcceptedAgreementsApi',
    Companies: 'CompaniesApi',
    CompanyAdministrators: 'CompanyAdministratorsApi',
    PriceList: 'PriceList',
    UserApproval: 'ApprovalApi',
  },
  Fiscal: {
    Credits: 'CreditsApi',
    Invoices: 'InvoicesApi',
  },
  Services: {
    Service: 'servicesapi',
    Generic: {
      TryForFree: 'ServiceTryForFreeApi',
    },
    NAVUpgrade: {
      Service: 'NAVUpgradeService',
      Plans: 'NAVUpgradePlansApi',
      AddOns: 'NAVUpgradeAddOnsApi',
      Simulate: 'NAVUpgradeSimulateApi',
      ScopeComments: 'NAVUpgradeScopeCommentsApi',
      ActionLog: 'NAVUpgradeActionLogApi',
      Reports: 'NAVUpgradeReportsApi',
      Copy: 'NAVUpgradeCopyApi',
      License: 'SubscriptionLicenseApi',
      Calculate: 'SubscriptionLicenseCalculateApi',
      DownloadRequest: 'NAVUpgradePlanReportApi',
      MoreInformation: 'moreinformation',
    },
    AXUpgrade: {
      Service: 'AXUpgradeApi',
      MoreInformation: 'moreinformation',
      Copy: 'AXUpgradeCopyApi',
    },
    NavCalToExtensionReport: {
      Service: 'NAVCalToExtensionReportServiceApi',
      Options: 'NAVCalToExtensionReportServiceOptionsApi',
    },
    NavExtensionsMaintenance: {
      Service: 'NAVExtensionsMaintenanceApi',
    },
    NAVDevelopmentService: {
      Service: 'NAVDevelopmentService',
    },
    NAVAddOnExtensionUpgrade: {
      Service: 'NAVAddOnExtensionUpgrade',
    },
    AXDevelopmentService: {
      Service: 'AXDevelopmentService',
    },
    AXBestPath: {
      Service: 'AXBestPathApi',
    },
  },
  Location: {
    Countries: 'CountriesApi',
    States: 'StatesApi',
  },
  Support: {
    Feedback: 'FeedbackApi',
  },
  Files: {
    CloudFiles: 'CloudFilesApi',
  },
};
