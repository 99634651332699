import { AssessmentActions } from '@appState';
import { createSelector } from '@ngrx/store';
import { getRootState } from '../../../app.store';

export const selectModuleState = createSelector(getRootState, rootState => rootState.assessmentsList);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectAssessmentListIsLoading = createSelector(selectModuleState, state => state.assessmentListIsLoading);
export const selectCustomerCreationIsLoading = createSelector(selectModuleState, state => state.customerCreationIsLoading);
export const selectFileDownloadIsLoading = createSelector(selectModuleState, state => state.fileDownloadIsLoading);
export const selectToggle = createSelector(selectModuleState, state => state.toggle);
export const selectAssessmentList = createSelector(selectModuleState, state => state.assessments);
export const selectSelectedAssessment = createSelector(selectModuleState, state => state.selectedAssessment);
export const selectSelectedAssessmentId = createSelector(selectModuleState, state => state.selectedAssessment?.id ?? null);
export const selectSelectedAssessmentPartnerId = createSelector(selectModuleState, state => state.selectedAssessment?.partnerId ?? null);
export const selectSelectedAssessmentActions = createSelector(selectModuleState, state => state.selectedAssessment?.actions ?? []);
export const selectSelectedAssessmentFiles = createSelector(selectModuleState, state => state.selectedAssessment?.files ?? []);
export const selectAssessmentSaveCompleted = createSelector(selectModuleState, state => state.assessmentSaveCompleted);
export const selectCreatedCustomer = createSelector(selectModuleState, state => state.createdCustomer);
export const selectAssessmentGenericFiles = createSelector(selectModuleState, state => state.assessmentGenericFiles);
export const selectAssessmentGenericFilesIsLoading = createSelector(selectModuleState, state => state.assessmentGenericFilesLoading);

export const selectHasAction = (requiredAction: AssessmentActions) =>
  createSelector(selectSelectedAssessmentActions, actions => actions.includes(requiredAction));
