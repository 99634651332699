import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as fromActions from './partners.actions';
import { PartnerBusinessDataResponse, PartnerResponse } from './partners.model';
import { PartnersService } from './partners.service';

@Injectable()
export class PartnersEffects {
  onGetPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPartner),
      mergeMap(() =>
        this.partnersService.get().pipe(
          map((partner: PartnerResponse) => fromActions.getPartnerComplete({ partner })),
          catchError(({ error }) => of(fromActions.getPartnerError({ error }))),
        ),
      ),
    ),
  );

  onSendUpdateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sendUpdateRequest),
      mergeMap(({ model }) =>
        this.partnersService.sendUpdateRequest(model).pipe(
          map(requestTicketNumber => fromActions.sendUpdateRequestComplete(requestTicketNumber)),
          catchError(({ error }) => of(fromActions.sendUpdateRequestError({ error }))),
        ),
      ),
    ),
  );

  onSendUpdateRequestComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sendUpdateRequestComplete),
      tap(() => this.router.navigate(['settings'])),
      map(({ requestTicketNumber }) => showCustomNotification({ message: `Request #${requestTicketNumber} has been created.` })),
    ),
  );

  onGetBusinessDataPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPartnerBusinessData),
      mergeMap(() =>
        this.partnersService.getBusinessData().pipe(
          map((businessData: PartnerBusinessDataResponse) => fromActions.getPartnerBusinessDataComplete({ businessData })),
          catchError(({ error }) => of(fromActions.getPartnerBusinessDataError({ error }))),
        ),
      ),
    ),
  );

  onShowError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sendUpdateRequestError, fromActions.getPartnerError, fromActions.getPartnerBusinessDataError),
      map(({ error }) => showCustomNotification({ message: error.errorMessage })),
    ),
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private partnersService: PartnersService,
  ) {}
}
