import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as fromActions from './common-data.action';
import { CommonDataService } from './common-data.service';

@Injectable()
export class CommonDataEffects {
  onGetCommonDataList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCommonDataList),
      mergeMap(({ dataType }) =>
        this.commonDataService.getAll(dataType).pipe(
          map(commonDataList => fromActions.getCommonDataListComplete({ commonDataList })),
          catchError(() => of(fromActions.getCommonDataListError())),
        ),
      ),
    ),
  );

  onGetAllCommonData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllCommonData),
      switchMap(() =>
        this.commonDataService.getAllWithoutType().pipe(
          map(commonDataList => fromActions.getAllCommonDataComplete({ commonDataList })),
          catchError(() => of(fromActions.getAllCommonDataError())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonDataService: CommonDataService,
  ) {}
}
