import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PartnerBusinessDataResponse, PartnerResponse, PartnerUpdateRequest, SendUpdatePartnerResponse } from './partners.model';

@Injectable()
export class PartnersService {
  private readonly path = (url = ''): string => `partners/${url}`;

  constructor(private highlanderService: HighlanderService) {}

  get(): Observable<PartnerResponse> {
    return this.highlanderService.get<PartnerResponse>(this.path());
  }
  sendUpdateRequest(model: PartnerUpdateRequest): Observable<SendUpdatePartnerResponse> {
    return this.highlanderService.post<PartnerUpdateRequest, SendUpdatePartnerResponse>('notifications/send-partner-update-request', model);
  }

  getBusinessData(): Observable<PartnerBusinessDataResponse> {
    return this.highlanderService.get<PartnerBusinessDataResponse>(this.path('business-data'));
  }
}
