import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import * as fromActions from './actions'
import { CountryService } from './country.service'

@Injectable()
export class CountryEffects {
  onQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.queryCountries),
      mergeMap(() =>
        this.countryService.getAll().pipe(
          map(countries => fromActions.queryCountriesSuccess({ countries })),
          catchError(() => of(fromActions.queryCountriesError())),
        ),
      ),
    ),
  )

  constructor(private actions$: Actions, private countryService: CountryService) {}
}
