import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { OcfFileIconComponent } from '@1clickfactory/simple-ui/components/ocf-file-icon/ocf-file-icon.component'

@NgModule({
  imports: [MatIconModule],
  declarations: [OcfFileIconComponent],
  exports: [OcfFileIconComponent],
})
export class OcfFileIconModule {}
