import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { userValidationActions } from '@appState';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

@Injectable()
export class UserValidationService {
  constructor(private readonly highlanderService: HighlanderService, private readonly store: Store) {}

  handleEmailValidation(email: string): Observable<boolean> {
    this.store.dispatch(userValidationActions.emailValidation({ isChecking: true }));
    return this.checkEmail(email).pipe(
      map(status => {
        this.store.dispatch(userValidationActions.emailValidation({ isChecking: false }));
        return status;
      })
    );
  }

  hasCrmAccessProperties(): Observable<boolean> {
    return this.highlanderService.get<boolean>('user-validation/crm-access');
  }

  private checkEmail(email: string): Observable<boolean> {
    return this.highlanderService.post<{ email: string }, boolean>('user-validation/email-exists', { email });
  }
}
