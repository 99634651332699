import { OldHighlanderService } from '@1clickfactory/data-access';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentTokenService {
  constructor(private oldHighlanderService: OldHighlanderService) {}

  get(): Observable<string> {
    return this.oldHighlanderService.get('paymentToken', '');
  }
}
