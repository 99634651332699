import { createReducer, on } from '@ngrx/store';
import * as fromActions from './user-validation.actions';

export interface State {
  isChecking: boolean;
}

export const initialState: State = {
  isChecking: false,
};

export const userValidationReducer = createReducer(
  initialState,
  on(fromActions.emailValidation, (state, { isChecking }) => ({ ...state, isChecking }))
);
