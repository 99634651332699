import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

export const selectModuleState = createSelector(getRootState, rootState => rootState.environments);

export const selectEnvironments = createSelector(selectModuleState, state => state.environments);
export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectTableFilters = createSelector(selectModuleState, state => state.tableFilters);

export const selectSelectedEnvironment = createSelector(selectModuleState, state => state.selectedEnvironment);
export const selectSelectedEnvironmentId = createSelector(selectModuleState, state => state.selectedEnvironment?.id ?? null);
export const selectIsSelectedEnvironmentLoading = createSelector(selectModuleState, state => state.isSelectedEnvironmentLoading);
