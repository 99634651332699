import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { OcfAlertModule } from '@1clickfactory/simple-ui';
import { OcfContactsListComponent } from './components/ocf-contacts-list/ocf-contacts-list.component';
import { OcfContactsFormComponent } from './components/ocf-contacts-form/ocf-contacts-form.component';
import { OcfContactsComponent } from './components/ocf-contacts/ocf-contacts.component';
import { OcfContactsMessagesComponent } from './components/ocf-contacts-messages/ocf-contacts-messages.component';
import { OcfFormModule } from '../ocf-form/ocf-form.module';
import { CuiButtonsModule, CuiInputComponent } from 'cui-components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,

    CuiButtonsModule,
    CuiInputComponent,
    OcfFormModule,
    OcfAlertModule,
  ],
  declarations: [OcfContactsListComponent, OcfContactsFormComponent, OcfContactsComponent, OcfContactsMessagesComponent],
  exports: [OcfContactsComponent, OcfContactsListComponent],
})
export class OcfContactsModule {}
