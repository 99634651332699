import { createReducer, on } from '@ngrx/store';
// noinspection ES6PreferShortImport
import { BillingInformation, CartBillingInformation } from './cart-billing-information.model';
// noinspection ES6PreferShortImport
import * as paymentTokenActions from '../payment-token/payment-token.actions';
import * as paymentInformationActions from './payment-information.action';
import { PaymentMethod } from './payment-method.model';

export interface State {
  cartBillingInformation: CartBillingInformation;
  paymentMethod: PaymentMethod;
  braintreeToken: string;
  company: BillingInformation | null;
}

export const initialState: State = {
  cartBillingInformation: {
    useCompanyInfoForInvoicing: false,
    email: '',
    companyName: '',
    registrationNo: '',
    address: '',
    city: '',
    countryId: NaN,
    countryName: '',
    zipCode: '',
  },
  paymentMethod: {
    braintreeNonce: '',
    paymentType: NaN,
    maskedCreditCardNumber: '',
  },
  braintreeToken: '',
  company: null,
};

export const paymentInformationReducer = createReducer(
  initialState,
  on(paymentInformationActions.saveBillingInformation, (state, { billingInformation }) => ({
    ...state,
    cartBillingInformation: billingInformation,
  })),
  on(paymentInformationActions.savePaymentInformation, (state, { paymentInformation }) => ({ ...state, ...paymentInformation })),
  on(paymentTokenActions.getBraintreeTokenComplete, (state, { braintreeToken }) => ({ ...state, braintreeToken })),
  on(paymentInformationActions.getCompanyComplete, (state, { company }) => ({ ...state, company })),
);
