import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { Credit } from '@appState'
import { CreditService } from './credit.service'
import * as fromActions from './credit.actions'

@Injectable()
export class CreditEffects {
  onGet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.get),
      mergeMap(() =>
        this.creditService.get().pipe(
          map((credit: Credit) => fromActions.getComplete({ credit })),
          catchError(() => of(fromActions.getError())),
        ),
      ),
    ),
  )

  constructor(private actions$: Actions, private creditService: CreditService) {}
}
