export function downloadFileToBrowser(file: File): void {
  // Normal browsers
  const objectUrl = URL.createObjectURL(file);
  const a = document.createElement('a') as HTMLAnchorElement;

  a.href = objectUrl;
  a.download = file.name;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(objectUrl);
}

export function downloadBlobUrlFileToBrowser(url: string): void {
  const elem = window.document.createElement('a');
  elem.href = url;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
}
