import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MMAgreementResponse } from './mm-util.model';

@Injectable()
export class MMUtilService {
  constructor(private highlanderService: HighlanderService) {}

  submitRequestToJoinMM(): Observable<MMAgreementResponse> {
    return this.highlanderService.post<undefined, MMAgreementResponse>('request-to-join-mm', undefined);
  }
}
