import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ChangeDeviceResponse,
  UserBlockingResponse,
  UserCreateRequest,
  UserResponse,
  UserUpdateRequest,
  VerifyCodeRequest,
  VerifyCodeResponse,
} from './user.model';

@Injectable()
export class UsersService {
  private readonly path = (url = ''): string => `users/${url}`;

  constructor(private highlanderService: HighlanderService) {}

  getAll(): Observable<UserResponse[]> {
    return this.highlanderService.get<UserResponse[]>(this.path());
  }

  get(id: string): Observable<UserResponse> {
    return this.highlanderService.get<UserResponse>(this.path(`${id}`));
  }

  create(model: UserCreateRequest): Observable<UserResponse> {
    return this.highlanderService.post<UserCreateRequest, UserResponse>(this.path(), model);
  }

  update(id: string, model: UserUpdateRequest): Observable<UserResponse> {
    return this.highlanderService.put<UserUpdateRequest, UserResponse>(this.path(`${id}`), model);
  }

  markforDeletion(id: string): Observable<UserResponse> {
    return this.highlanderService.post<string, UserResponse>(this.path(`${id}/mark-for-deletion`));
  }

  blocking(id: string, isBlocking: boolean): Observable<UserBlockingResponse> {
    return this.highlanderService.post<{ block: boolean }, UserBlockingResponse>(this.path(`${id}/blocking`), { block: isBlocking });
  }

  resetTwoFactoy(id: string): Observable<void> {
    return this.highlanderService.post<string, void>(this.path(`${id}/reset-two-factor`));
  }

  changeDevice(): Observable<ChangeDeviceResponse> {
    return this.highlanderService.post<undefined, ChangeDeviceResponse>(this.path(`change-device`));
  }

  verifyCode(code: VerifyCodeRequest): Observable<VerifyCodeResponse> {
    return this.highlanderService.post<VerifyCodeRequest, VerifyCodeResponse>(this.path(`verify-code`), code);
  }
}
