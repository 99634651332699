import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    if (!url) {
      return super.parse(url);
    }

    const urlParts = url.split('?');
    return super.parse([urlParts[0].toLowerCase(), ...urlParts.splice(1)].join('?'));
  }
}
