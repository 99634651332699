import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CommentRequest,
  NewsArticle,
  NewsArticleCommentListItem,
  NewsArticleFilterParams,
  NewsArticleListItem,
  NewsArticleSortTypes,
  ReactRequest,
} from './news-article.model';
import { State } from './news-articles.reducer';

const NAMESPACE = '[News Articles]';

export const getPromotedNewsArticles = createAction(`${NAMESPACE} GetPromotedNewsArticles`, props<{ filters: NewsArticleFilterParams }>());
export const getPromotedNewsArticlesComplete = createAction(
  `${NAMESPACE} GetPromotedNewsArticlesComplete`,
  props<{ newsArticles: NewsArticleListItem[]; regionId: string }>(),
);
export const getPromotedNewsArticlesError = createAction(`${NAMESPACE} GetPromotedNewsArticlesError`, props<{ err: HttpErrorResponse }>());

export const reOrderFilteredNewsArticles = createAction(
  `${NAMESPACE} ReOrderFilteredNewsArticles`,
  props<{ sortType: NewsArticleSortTypes; regionId: string }>(),
);

export const getFilteredNewsArticles = createAction(`${NAMESPACE} GetFilteredNewsArticles`, props<{ filters: NewsArticleFilterParams }>());
export const getFilteredNewsArticlesComplete = createAction(
  `${NAMESPACE} GetFilteredNewsArticlesComplete`,
  props<{ newsArticles: NewsArticleListItem[]; regionId: string }>(),
);
export const getFilteredNewsArticlesError = createAction(`${NAMESPACE} GetFilteredNewsArticlesError`, props<{ err: HttpErrorResponse }>());

export const getOpenedNewsArticle = createAction(`${NAMESPACE} GetOpenedNewsArticle`, props<{ id: string }>());
export const getOpenedNewsArticleComplete = createAction(
  `${NAMESPACE} GetOpenedNewsArticleComplete`,
  props<{ newsArticle: NewsArticle }>(),
);
export const getOpenedNewsArticleError = createAction(`${NAMESPACE} GetOpenedNewsArticleError`, props<{ err: HttpErrorResponse }>());

export const goToArticleList = createAction(`${NAMESPACE} GoToArticleList`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());

export const reactOnNewsArticle = createAction(`${NAMESPACE} ReactOnNewsArticle`, props<{ articleId: string; req: ReactRequest }>());
export const reactOnNewsArticleComplete = createAction(`${NAMESPACE} ReactOnNewsArticleComplete`, props<{ newsArticle: NewsArticle }>());
export const reactOnNewsArticleError = createAction(`${NAMESPACE} ReactOnNewsArticleError`, props<{ err: HttpErrorResponse }>());

export const getAllNewsArticleComments = createAction(
  `${NAMESPACE} GetAllNewsArticleComments`,
  props<{ articleId: string; regionId: string }>(),
);
export const getAllNewsArticleCommentsComplete = createAction(
  `${NAMESPACE} GetAllNewsArticleCommentsComplete`,
  props<{ comments: NewsArticleCommentListItem[] }>(),
);
export const getAllNewsArticleCommentsError = createAction(
  `${NAMESPACE} GetAllNewsArticleCommentsError`,
  props<{ err: HttpErrorResponse }>(),
);

export const reactOnNewsArticleComment = createAction(
  `${NAMESPACE} ReactOnNewsArticleComment`,
  props<{ articleId: string; commentId: string; req: ReactRequest }>(),
);
export const reactOnNewsArticleCommentComplete = createAction(
  `${NAMESPACE} ReactOnNewsArticleCommentComplete`,
  props<{ comment: NewsArticleCommentListItem }>(),
);
export const reactOnNewsArticleCommentError = createAction(
  `${NAMESPACE} ReactOnNewsArticleCommentError`,
  props<{ err: HttpErrorResponse }>(),
);

export const createNewsArticleComment = createAction(
  `${NAMESPACE} CreateNewsArticleComment`,
  props<{ articleId: string; req: CommentRequest }>(),
);
export const createNewsArticleCommentComplete = createAction(
  `${NAMESPACE} CreateNewsArticleCommentComplete`,
  props<{ comment: NewsArticleCommentListItem }>(),
);
export const createNewsArticleCommentError = createAction(
  `${NAMESPACE} CreateNewsArticleCommentError`,
  props<{ err: HttpErrorResponse }>(),
);
