import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';

// noinspection ES6PreferShortImport
import { ROUTE_PARAMS } from '../route-params.constants';
// noinspection ES6PreferShortImport
import { RouterData } from './router-state.model';
import * as fromActions from './router.actions';

@Injectable()
export class RouterEffects {
  onRouterNavigatedScrollToTop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        map(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }),
      ),
    { dispatch: false },
  );

  onGoToReturnUrlOrUrl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.goToReturnUrlOrUrl),
        mergeMap(({ url }) =>
          this.activatedRoute.queryParams.pipe(
            first(),
            map(queryParams => {
              const queryParam = Object.keys(queryParams).find(k => k.toLowerCase() === ROUTE_PARAMS.returnUrl.toLowerCase());
              return !queryParam ? null : queryParams[queryParam];
            }),
            switchMap(returnUrl =>
              !!returnUrl ? this.router.navigateByUrl(decodeURIComponent(returnUrl)) : this.router.navigateByUrl(url),
            ),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  onReload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.reload),
        map(() => location.reload()),
      ),
    { dispatch: false },
  );

  onGoTo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goTo),
        switchMap(({ url }) => this.router.navigateByUrl(url)),
      ),
    { dispatch: false },
  );

  onGoToDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToDashboard),
      map(() => fromActions.goTo({ url: '/dashboard' })),
    ),
  );

  onGoToReturnUrlOrHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToReturnUrlOrHome),
      map(() => fromActions.goToReturnUrlOrUrl({ url: '/dashboard' })),
    ),
  );

  onGoToForbiddenError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToForbiddenError),
      map(() => fromActions.goTo({ url: '/page/403-forbidden' })),
    ),
  );

  onRouterNavigated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      tap((p: any) => {
        const gtag = (window as any).gtag;
        if (gtag) {
          // for DEV purposes use: UA-11790540-3
          gtag('config', 'UA-11790540-1', { page_path: p.payload.event.url.toLowerCase() });
        }
      }),
      map(p => (p as any).payload.routerState.data),
      filter((p: RouterData) => !!p.action),
      map((p: any) => new p.action()),
    ),
  );

  constructor(private actions$: Actions, private activatedRoute: ActivatedRoute, private router: Router) {}
}
