import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ocf-readonly-notice',
  template: ` <ocf-alert type="info" i18n> This service request has been submitted and cannot be edited anymore. </ocf-alert> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
        margin: var(--cui-small-space) 0;
      }
    `,
  ],
})
export class OcfReadonlyNoticeComponent {}
