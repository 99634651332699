import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { createAction, props } from '@ngrx/store';
import { UserModel } from './identity.models';

const NAMESPACE = '[Identity]';

export const checkAuth = createAction(`${NAMESPACE} CheckAuth`);
export const checkAuthCompleted = createAction(`${NAMESPACE} CheckAuthCompleted`, props<UserModel>());

export const refresh = createAction(`${NAMESPACE} Refresh`);
export const refreshCompleted = createAction(`${NAMESPACE} RefreshCompleted`, props<{ expires_at: number }>());
export const refreshError = createAction(`${NAMESPACE} RefreshError`, props<{ err: HighlanderCoreErrorResponse }>());

export const userUnauthorized = createAction(`${NAMESPACE} UserUnauthorized`);
