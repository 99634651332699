import { createAction, props } from '@ngrx/store'
// noinspection ES6PreferShortImport
import { PaymentInformation } from './payment-method.model'
// noinspection ES6PreferShortImport
import { BillingInformation, CartBillingInformation } from './cart-billing-information.model'

const NAMESPACE = '[PaymentInformation]'

export const savePaymentInformation = createAction(
  `${NAMESPACE} SavePaymentInformation`,
  props<{ paymentInformation: PaymentInformation }>(),
)

export const saveBillingInformation = createAction(
  `${NAMESPACE} SaveBillingInformation`,
  props<{ billingInformation: CartBillingInformation }>(),
)

export const confirmBraintreeCardError = createAction(`${NAMESPACE} ConfirmBraintreeCardError`)

export const getCompany = createAction(`${NAMESPACE} GetCompany`)
export const getCompanyComplete = createAction(`${NAMESPACE} GetCompanyComplete`, props<{ company: BillingInformation }>())
export const getCompanyError = createAction(`${NAMESPACE} GetCompanyError`)
