import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
// noinspection ES6PreferShortImport

import { OcfWizardComponent } from './ocf-wizard.component';
import { OcfWizardStepComponent } from './ocf-wizard-step.component';
import { OcfWizardButtonRowComponent } from './ocf-wizard-button-row.component';
// noinspection ES6PreferShortImport
import { OCFCommonModule } from '../../../common/common.module';
import { CuiButtonsModule } from 'cui-components';

@NgModule({
  imports: [CommonModule, MatStepperModule, OCFCommonModule, CuiButtonsModule],
  declarations: [OcfWizardComponent, OcfWizardStepComponent, OcfWizardButtonRowComponent],
  exports: [OcfWizardComponent, OcfWizardStepComponent],
})
export class OcfWizardModule {}
