import { ServiceBase } from '@1clickfactory/backend';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backend } from '@env/backend';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Account } from './account.model';

@Injectable()
export class AccountService extends ServiceBase<Account> {
  constructor(httpClient: HttpClient) {
    super(`${environment.highlanderBffUrl}/gateway/askook/${backend.Users.Accounts}`, httpClient);
  }

  get(id: string | number): Observable<Account> {
    return super.get(id);
  }

  put(id: string | number, model: Account[]): Observable<Account> {
    return super.put(id, model);
  }

  delete(id: string | number): Observable<void> {
    return super.delete(id);
  }
}
