import { ServiceBase } from '@1clickfactory/backend';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backend } from '@env/backend';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { State } from './models';

@Injectable()
export class StateService extends ServiceBase<State> {
  constructor(httpClient: HttpClient) {
    super(`${environment.highlanderBffUrl}/gateway/askook/${backend.Location.States}`, httpClient);
  }

  getAll(): Observable<State[]> {
    return super.getAll();
  }
}
