import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import * as actions from './actions'
import { Country } from './models'

export interface State extends EntityState<Country> {}

export const adapter: EntityAdapter<Country> = createEntityAdapter<Country>({
  selectId: (country: Country) => country.Id,
})

export const initialState: State = adapter.getInitialState({})

const countriesReducer = createReducer(
  initialState,
  on(actions.queryCountriesSuccess, (state, { countries }) => adapter.addMany(countries, state)),
)

export function reducer(state: State | undefined, action: Action): State {
  return countriesReducer(state, action)
}
