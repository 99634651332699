import { createSelector } from '@ngrx/store'
import * as formReducers from './form.reducer'
import { getFeatureStore } from '../simple-forms.state'

const selectModuleState = createSelector(getFeatureStore, s => s && s.forms)

export const { selectEntities: selectFormEntities } = formReducers.adapter.getSelectors(selectModuleState)

/**
 * Selects Form object from store by ID
 */
export const selectEntity = (id: string) => createSelector(selectFormEntities, e => e && e[id])

/**
 * Selects Form DTO from store
 */
export const selectForm = (id: string) => createSelector(selectEntity(id), e => e && e.Form)

/**
 * Selects IsProcessing for specific form (by ID)
 */
export const selectIsProcessing = (id: string) => createSelector(selectEntity(id), e => e && e.IsProcessing)

/**
 * Selects IsValid for specific form (by ID). VALUE MAY BE OUT-DATED.
 */
export const selectIsValid = (id: string) => createSelector(selectEntity(id), e => e && e.IsValid)

/**
 * Selects IsValidatable for specific form (by ID). VALUE MAY BE OUT-DATED.
 */
export const selectIsValidatable = (id: string) => createSelector(selectEntity(id), e => e && e.IsValidatable)

/**
 * Selects a combination of validation values, returns true if validation error should be displayed.
 */
export const selectIsValidationPassing = (id: string) =>
  createSelector(selectIsValid(id), selectIsValidatable(id), (isValid, isValidatable) => (isValidatable ? isValid : true))

/**
 * Selects IsSubmitted for specific form (by ID).
 */
export const selectIsSubmitted = (id: string) => createSelector(selectEntity(id), e => e && e.IsSubmitted)

export const selectIsNotSubmitted = (id: string) => createSelector(selectIsSubmitted(id), isSubmitted => !isSubmitted)

/**
 * Selects HasBackEndErrors for specific form (by ID)
 */
export const selectHasBackEndErrors = (id: string) => createSelector(selectEntity(id), e => e && e.HasBackendErrors)

export const selectAskookErrorCode = (id: string) => createSelector(selectEntity(id), e => e && e.AskookErrorCode)
