export function parseToNullOrNumber(value: number | string | null): number | null {
  if (value === null) return null;

  return isNaN(parseInt(value.toString())) ? null : Number(value);
}

export function convertUTCDateToLocalDate(date: string | number | Date) {
  let utcDate: Date;

  if (typeof date === 'string' || typeof date === 'number') {
    utcDate = new Date(date);
  } else {
    utcDate = date;
  }

  return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000);
}

/** Use in getQuickFilterText
 * @returns '' to exclude column from search
 */
export function excludeColumnFromSearch(): string {
  return '';
}

export const isGUID = (val: string): boolean => {
  const guidRegxp = /^[0-9a-fA-F]{8}(?:-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}$/;
  return guidRegxp.test(val);
};
