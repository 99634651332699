import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { CommonData, CommonDataType } from '@appState';
import { Observable, of, tap } from 'rxjs';

export type CommonDataObj = Record<CommonDataType, CommonData<CommonDataType>[]>;

@Injectable()
export class CommonDataService {
  private readonly path: string = `common-data`;
  private readonly commonCache: CommonDataObj = {
    audience: [],
    region: [],
    membershipLevel: [],
    language: [],
    workload: [],
    country: [],
    state: [],
    topic: [],
  };

  constructor(private highlanderService: HighlanderService) {}

  getAll(type: CommonDataType): Observable<CommonData[]> {
    if (this.commonCache[type].length) {
      return of(this.commonCache[type]);
    } else {
      return this.highlanderService.getAll<CommonData>(this.path, { params: { type } }).pipe(tap(data => (this.commonCache[type] = data)));
    }
  }

  getAllWithoutType(): Observable<CommonData[]> {
    return this.highlanderService.getAll<CommonData>(this.path).pipe(
      tap(data => {
        const audience: CommonData[] = [];
        const region: CommonData[] = [];
        const membershipLevel: CommonData[] = [];
        const language: CommonData[] = [];
        const country: CommonData[] = [];
        const state: CommonData[] = [];
        const workload: CommonData[] = [];
        const topic: CommonData[] = [];
        data.forEach(cData => {
          switch (cData.type) {
            case 'audience':
              audience.push(cData);
              break;
            case 'region':
              region.push(cData);
              break;
            case 'membershipLevel':
              membershipLevel.push(cData);
              break;
            case 'language':
              language.push(cData);
              break;
            case 'country':
              country.push(cData);
              break;
            case 'state':
              state.push(cData);
              break;
            case 'workload':
              workload.push(cData);
              break;
            case 'topic':
              topic.push(cData);
              break;
            default:
              break;
          }
        });
        this.commonCache.audience = audience;
        this.commonCache.region = region;
        this.commonCache.membershipLevel = membershipLevel;
        this.commonCache.language = language;
        this.commonCache.country = country;
        this.commonCache.workload = workload;
        this.commonCache.topic = topic;
        this.commonCache.state = state;
      }),
    );
  }
}
