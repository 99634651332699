import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as userApprovalActions from '../user/user-approval/user-approval.actions';

import * as fromActions from './account.actions';
// noinspection ES6PreferShortImport
import { Account } from './account.model';

export interface State extends EntityState<Account> {
  /**
   * Id of currently logged-in user's account
   */
  IsLoaded: boolean;
  /**
   * Id of an account that is currently being viewed in "UserProfile" page.
   */
  SelectedAccountId: string | null;
}

export const adapter: EntityAdapter<Account> = createEntityAdapter<Account>({
  selectId: (account: Account) => account.UId,
});

export const initialState: State = adapter.getInitialState({
  IsLoaded: false,
  SelectedAccountId: null,
});

export const accountReducer = createReducer(
  initialState,
  on(fromActions.getComplete, (state, { account }) => ({ ...adapter.upsertOne(account, state), IsLoaded: true })),
  on(fromActions.selectAccount, (state, { id }) => ({ ...state, SelectedAccountId: id })),
  on(fromActions.deleteAccountComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(userApprovalActions.save, state => adapter.updateOne({ id: state.SelectedAccountId!, changes: { IsProcessing: true } }, state)),
);

export function reducer(state = initialState, action: Action): State {
  return accountReducer(state, action);
}
