import { CommonData, CommonDataType } from '@appState';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Common Data]';

export const getCommonDataList = createAction(`${NAMESPACE} GetCommonDataList`, props<{ dataType: CommonDataType }>());
export const getCommonDataListComplete = createAction(`${NAMESPACE} GetCommonDataListComplete`, props<{ commonDataList: CommonData[] }>());
export const getCommonDataListError = createAction(`${NAMESPACE} GetCommonDataListError`);

export const getAllCommonData = createAction(`${NAMESPACE} GetAllCommonData`);
export const getAllCommonDataComplete = createAction(`${NAMESPACE} GetAllCommonDataComplete`, props<{ commonDataList: CommonData[] }>());
export const getAllCommonDataError = createAction(`${NAMESPACE} GetAllCommonDataError`);
