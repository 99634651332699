import { LowerCaseUrlSerializer, MaterialDesignModule, OCFCommonModule } from '@1clickfactory/common';
import { GlobalErrorHandler } from '@1clickfactory/common/helpers/global-error.handler';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { ROOT_STATE_EFFECTS, ROOT_STATE_SERVICES } from '@appState';
import { environmentSpecificImports } from '@env/instrument';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';

import { StoreModule } from '@ngrx/store';

import { AnonymousModule } from '../app/components/anonymous/anonymous.module';
import { AppRoutingModule } from './app-routing.module';
import { appReducers, metaReducers } from './app-state/app.store';
import { CustomRouterStateSerializer } from './app-state/router-state/router-state.serializer';
import { AuthenticatedTemplateComponent } from './components/authenticated-template/authenticated-template.component';

import { ConfigService } from '@1clickfactory/common/config/app-config.service';
import { TokenExpirationInterceptor } from '@1clickfactory/common/interceptors/token.interceptor';
import { LoggingService, loggingInitializer } from '@1clickfactory/common/logging/logging.service';
import { DataAccessModule } from '@1clickfactory/data-access/data-access.module';
import { ConfirmationModalModule } from '@1clickfactory/simple-ui/confirmation-modal/confirmation-modal.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '@env/environment';
import {
  CuiAppContainerComponent,
  CuiButtonsModule,
  CuiLibraryModule,
  CuiPageTitleComponent,
  CuiSideNavModule,
  CuiTopMenuModule,
} from 'cui-components';
import { HttpHighlanderInterceptor } from '../lib/@1clickfactory/common/interceptors/highlander-http.interceptor';
import { AfterSignInComponent } from './components/after-sign-in/after-sign-in.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MainComponent } from './components/main/main.component';

@NgModule({
  declarations: [MainComponent, AuthenticatedTemplateComponent, AfterSignInComponent, DashboardComponent],
  imports: [
    CuiSideNavModule,
    CuiTopMenuModule,
    CuiAppContainerComponent,
    BrowserModule,
    CuiPageTitleComponent,
    CuiButtonsModule,
    BrowserAnimationsModule,
    CommonModule,
    MaterialDesignModule,
    DataAccessModule,
    FormsModule,
    AppRoutingModule,
    OCFCommonModule,
    CuiLibraryModule.forRoot({ apiUrl: environment.highlanderBffUrl }),
    StoreRouterConnectingModule.forRoot({ serializer: CustomRouterStateSerializer, stateKey: 'router' }),
    StoreModule.forRoot(appReducers, { metaReducers, runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
    EffectsModule.forRoot(ROOT_STATE_EFFECTS),
    ...environmentSpecificImports,
    AnonymousModule,
    ConfirmationModalModule,
    HttpClientModule,
    MatSidenavModule,
  ],
  providers: [
    ...ROOT_STATE_SERVICES,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: TokenExpirationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpHighlanderInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' } as MatFormFieldDefaultOptions,
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: APP_INITIALIZER,
      useFactory: loggingInitializer,
      deps: [ConfigService, LoggingService],
      multi: true,
    },
  ],
  bootstrap: [MainComponent],
})
export class AppModule {}
