import { Component, OnInit } from '@angular/core';
import { announcementsSelectors } from '@appState';
import { Store, select } from '@ngrx/store';
import { DialogService, SubscribingBase } from 'cui-components';
import { filter, take } from 'rxjs';
import { Announcement } from 'src/app/app-state/announcements/announcements.model';
import { AppState } from 'src/app/app-state/app.store';
import { AnnouncementDialogComponent } from './announcement/announcement.component';
@Component({
  selector: 'ocf-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent extends SubscribingBase implements OnInit {
  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(
      this.store.pipe(
        select(announcementsSelectors.selectNotShownAnnouncements),
        filter(ann => !!ann.length),
        take(1),
      ),
      announcements => {
        if (announcements.length > 1) {
          this.openDialogsSequentially(announcements, 0);
        } else {
          this.dialogService.openDialog<{ announcement: Announcement }, AnnouncementDialogComponent>(
            AnnouncementDialogComponent,
            {
              announcement: announcements[0],
            },
            { autoFocus: false },
          );
        }
      },
    );
  }

  private openDialogsSequentially(announcements: Announcement[], currentIndex: number): void {
    if (currentIndex < announcements.length) {
      const currentAnnouncement = announcements[currentIndex];

      const dialogRef = this.dialogService.openDialog<{ announcement: Announcement }, AnnouncementDialogComponent>(
        AnnouncementDialogComponent,
        {
          announcement: currentAnnouncement,
        },
      );

      dialogRef.closed.subscribe(() => {
        this.openDialogsSequentially(announcements, currentIndex + 1);
      });
    }
  }
}
