import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerCreateRequest, CustomerResponse, CustomerUpdateRequest } from './customer.model';

@Injectable()
export class CustomersService {
  private readonly path = (url = ''): string => `customers/${url}`;

  constructor(private highlanderService: HighlanderService) {}

  getAll(): Observable<CustomerResponse[]> {
    return this.highlanderService.get<CustomerResponse[]>(this.path());
  }

  get(id: string): Observable<CustomerResponse> {
    return this.highlanderService.get<CustomerResponse>(this.path(`${id}`));
  }

  create(model: CustomerCreateRequest): Observable<CustomerResponse> {
    return this.highlanderService.post<CustomerCreateRequest, CustomerResponse>(this.path(), model);
  }

  update(id: string, model: CustomerUpdateRequest): Observable<CustomerResponse> {
    return this.highlanderService.put<CustomerUpdateRequest, CustomerResponse>(this.path(`${id}`), model);
  }
}
