import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import * as formActions from './form.actions';
import * as formSelectors from './form.selectors';

@Injectable()
export class FormEffects {
  /*
  OnTry submit logic:
  1. Wait for Try submit form action
  2. "Subscribe" to latest form values of the form being submitted
  3. Debounce values - this is needed for two reasons:
  3.1. If "submit" is the very first interaction a user performs on page after load, and auto-fill has already fired, the values
       will not be in model and only start coming in after a few milliseconds, therefore we need to debounce until values stop coming in.
  3.2. Allow time for validation to fire one last time before submitting.
  4. Take only 1 value and terminate the stream so it doesn't keep firing when form values change
  5. If validation is passing, we submit the form. Otherwise we set form to "touched and dirty" through store.
   */

  onTrySubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formActions.Actions.TrySubmitForm),
      switchMap(a =>
        this.store.pipe(
          select(formSelectors.selectFormEntities),
          map(e => e[(a as any).payload]),
          debounceTime(50),
          take(1),
        ),
      ),
      map(dto => (dto && dto.IsValid ? new formActions.SubmitForm(dto) : new formActions.SetValidatable(dto?.UId ?? ''))),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
  ) {}
}
