import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromActions from './user-anonymous.actions';
import { UserAnonymousService } from './user-anonymous.service';

@Injectable()
export class UserAnonymousEffects {
  onSubmitApplicationForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitApplicationForm),
      switchMap(({ model, humanCheckToken }) =>
        this.userApplicationService.applicate(model, humanCheckToken).pipe(
          map(() => fromActions.submitApplicationFormComplete()),
          catchError(({ error }) => of(fromActions.submitApplicationFormError({ err: error }))),
        ),
      ),
    ),
  );

  onSubmitApplicationFormComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.submitApplicationFormComplete),
        map(() => {
          this.router.navigate(['anonymous', 'application', 'success']);
        }),
      ),
    { dispatch: false },
  );

  onSubmitForgotPasswordForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitForgotPasswordForm),
      switchMap(({ model, humanCheckToken }) =>
        this.userApplicationService.sendResetPasswordLink(model, humanCheckToken).pipe(
          map(() => fromActions.submitForgotPasswordFormComplete()),
          catchError(({ error }) => of(fromActions.submitForgotPasswordFormError({ err: error }))),
        ),
      ),
    ),
  );

  onSubmitResetPasswordForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitResetPasswordForm),
      switchMap(({ model, humanCheckToken, resetPasswordToken }) =>
        this.userApplicationService.resetPassword(model, humanCheckToken, resetPasswordToken).pipe(
          map(() => fromActions.submitResetPasswordFormComplete()),
          catchError(({ error }) => of(fromActions.submitResetPasswordFormError({ err: error }))),
        ),
      ),
    ),
  );

  onSubmitFormError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitForgotPasswordFormError, fromActions.submitResetPasswordFormError, fromActions.submitApplicationFormError),
      map(({ err }) => showCustomNotification({ message: err?.errorMessage ?? 'An error has occured' })),
    ),
  );

  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly userApplicationService: UserAnonymousService,
  ) {}
}
