import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { routerActions } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ConfirmationModalComponent } from '../confirmation-modal.component';
import * as fromActions from './confirmation-modal.actions';

@Injectable()
export class ConfirmationModalEffects {
  onShowConfirmationModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.showConfirmationModal),
      switchMap(({ data }) =>
        this.dialog
          .open(ConfirmationModalComponent, {
            data: { ...data },
            autoFocus: false,
            panelClass: 'custom-modal-container',
          })
          .afterClosed()
          .pipe(
            take(1),
            filter(value => value !== undefined && value !== null),
            map(wantToLeave => {
              if (wantToLeave) return routerActions.goTo({ url: data.url });
              else return { type: '' };
            }),
          ),
      ),
    ),
  );
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
  ) {}
}
