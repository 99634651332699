import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { isGUID } from '@highlander/common/helpers/util';
import { NotificationsService } from 'cui-components';

@Injectable()
export class GuidGuard {
  constructor(
    private router: Router,
    private notificationService: NotificationsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const guidToCheck = route.paramMap.get(route.paramMap.keys[0]);
    if (guidToCheck && !isGUID(guidToCheck)) {
      this.notificationService.showNotification('Entity identifier in URL is not a valid GUID', 'Dismiss', { duration: 10000 });
      this.router.navigate(['page', 'page-not-found']);
      return false;
    }
    return true;
  }
}
