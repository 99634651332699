import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OcfFormComponent } from '@1clickfactory/forms2/ocf-form/ocf-form.component';
import { OcfFormButtonRowModule } from '@1clickfactory/simple-ui';
import { OcfAlertModule } from '@1clickfactory/simple-ui/components/alert/alert.module';
import { CommonModule } from '@angular/common';
import { OcfNoticesModule } from '@1clickfactory/common/notices/ocf-notices.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OcfFormButtonRowModule,
    OcfAlertModule,
    OcfNoticesModule,
    MatProgressSpinnerModule,
  ],
  declarations: [OcfFormComponent],
  exports: [OcfFormComponent],
})
export class OcfFormModule {}
