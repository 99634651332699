import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

const emailBlockedDomainRegExp = new RegExp('(?<=@)[^.]+(?=.)');
const integerRegExp = new RegExp('^0$|^-?[1-9][0-9]*$');
const allowedExpirationDateFormatRegExp = new RegExp('^(0[1-9]|1[0-2])/([0-9]{2}|[0-9]{2})$');
const phoneNumberRegExp = new RegExp(
  /(^$|^[+](9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$)/,
);
const latinLettersAndNumbersRegExp = new RegExp('^[a-zA-Z0-9]*$');
const specialSymbolRegExp = new RegExp(/[$-/:-?{-~!"^_#@`\[\]]/);
const upperCaseRegExp = new RegExp(/[A-Z]+/);
const lowerCaseRegExp = new RegExp(/[a-z]+/);
const numericRegExp = new RegExp(/[0-9]+/);

/**
 *
 * @param fieldToCompareName - Field to which you will compare
 * @param fieldToCheckName  - Field that needs to be checked and errored if needed
 *
 * Use this on form group level, DO NOT use this on specific formControl
 */
export const fieldsRepeated = (fieldToCompareName: string, fieldToCheckName: string) => {
  return (c: AbstractControl) => {
    const fieldToCompare = c.get(fieldToCompareName);
    const fieldToCheck = c.get(fieldToCheckName);
    if (fieldToCheck && fieldToCompare && fieldToCompare.dirty && fieldToCheck.dirty) {
      if (fieldToCompare.value !== fieldToCheck.value) {
        fieldToCheck.setErrors({ confirmationerror: true });
      } else if (fieldToCheck.hasError('confirmationerror')) {
        fieldToCheck.setErrors(null);
      }
    }
    return null;
  };
};

export function checkAsyncValidator(check: (data: string) => Observable<boolean>, error: string): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.valueChanges) {
      return of(null);
    } else {
      return control.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(value => check(value)),
        map(status => (status ? { [error]: true } : null)),
        first(),
      );
    }
  };
}

// ToDo: when doing FileUploader fix to be unnecessary
export function fileRequired(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isEmpty = control.value.length < 1;
    return isEmpty ? { required: '' } : null;
  };
}

export const integersOnly = (control: AbstractControl): { [key: string]: boolean } | null => {
  const isValid = !control.value || integerRegExp.test(control.value);
  return isValid ? null : { integerError: true };
};

export const notEqualToZero = (control: AbstractControl): { [key: string]: boolean } | null => {
  const isValid = !control.value || +control.value !== 0;
  return isValid ? null : { integerEqualToZeroError: true };
};

export const notEqualToCurrentValue = (currentValue: number): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: number } | null => {
    const isValid = +control.value !== currentValue;
    return isValid ? null : { valueEqualToCurrentValue: currentValue };
  };
};

export function latinLettersAndNumbersOnly(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const isValid = !control.value || latinLettersAndNumbersRegExp.test(control.value);
    return isValid ? null : { latinLettersAndNumbersError: true };
  };
}

export function confirmTermsAndConditions(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    return control.value ? null : { confirmTermsAndConditions: true };
  };
}

export function confirmAdditionalStatement(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    return control.value ? null : { confirmAdditionalStatement: true };
  };
}

export function emailBlockedDomain(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const blockedDomains = ['gmail', 'yahoo', 'yandex', 'icloud', 'gmx', 'proton', 'aol', 'inbox'];
    const domainRegExpValue = emailBlockedDomainRegExp.exec(control.value);

    if (domainRegExpValue) {
      const isValid = !control.value || !blockedDomains.includes(domainRegExpValue[0]);
      return isValid ? null : { emailBlockedDomain: true };
    }

    return null;
  };
}

export function noEmptyStrings(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const isValid = !control.value || control.value.trim();
    return isValid ? null : { emptyStringError: true };
  };
}

export function phoneNumber(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const isPhoneNumberValid = phoneNumberRegExp.test(control.value);
    return isPhoneNumberValid ? null : { invalidPhoneError: true };
  };
}

export function creditCardExpiresDate(control: AbstractControl): { [key: string]: boolean } | null {
  const date: string = control.value;
  if (!date) {
    return null;
  }
  const isValidFormat = allowedExpirationDateFormatRegExp.test(date);
  if (!isValidFormat) {
    return { invalidExpirationDateFormatError: true };
  }
  const inputValues = date.split('/');
  const inputMonth = Number(inputValues[0]);
  const inputYear = Number(inputValues[1]);
  return isValidExpirationDate(inputYear, inputMonth) ? null : { invalidExpirationDateError: true };
}

/**
 * @param expirationYearTwoDigits Ex.: 21 (if year 2021)
 * @param expirationMonth Ex.: 1 (if January)
 */
function isValidExpirationDate(expirationYearTwoDigits: number, expirationMonth: number): boolean {
  // currentYear result example: (2021) => "21"
  const currentYearTwoDigits = Number(new Date().getFullYear().toString().slice(-2));
  const currentMonth = Number(new Date().getMonth() + 1);
  if (currentYearTwoDigits > expirationYearTwoDigits) {
    // Expired in previous years
    return false;
  }
  if (currentYearTwoDigits === expirationYearTwoDigits && currentMonth > expirationMonth) {
    // Expired in previous months
    return false;
  }
  if (expirationYearTwoDigits > currentYearTwoDigits + 20) {
    // User entered an expiration year that is more than 20 years in the future
    return false;
  }
  return true;
}

export function passwordStrength(control: AbstractControl): { [key: string]: boolean } | null {
  const password = control.value;

  if (!password) {
    return null;
  }

  const hasUpperCase = upperCaseRegExp.test(password);
  const hasLowerCase = lowerCaseRegExp.test(password);
  const hasNumeric = numericRegExp.test(password);
  const hasSymbol = specialSymbolRegExp.test(password);

  const isPasswordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSymbol;

  return isPasswordValid ? null : { weakPassword: true };
}
