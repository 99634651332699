import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Agreements } from '@env/agreements';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, skipWhile, switchMap } from 'rxjs/operators';

import { AppState } from '../../app.store';
import * as fromSelectors from '../identity.selectors';

@Injectable()
export class AgreementsGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const alwaysRequiredAgreements = [Agreements.PrivacyPolicy, Agreements.TermsOfService];
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const routeSpecifiedAgreements = route.data?.['agreements'] ?? [];
    const requiredAgreements = [...alwaysRequiredAgreements, ...routeSpecifiedAgreements].map(a => a.replaceAll('-', ''));

    return this.store.select(fromSelectors.selectIsLoading).pipe(
      filter(isLoading => !isLoading),
      first(),
      switchMap(() =>
        this.store.select(fromSelectors.selectAgreements).pipe(
          skipWhile(userAgreements => userAgreements === null),
          map(userAgreements => requiredAgreements.every(requiredAgreement => userAgreements.includes(requiredAgreement))),
          first(),
          map(hasAllAgreements => {
            // eslint-disable-next-line no-console
            console.assert(hasAllAgreements, 'Unauthorized - agreement requirement not met. Access denied.');
            if (!hasAllAgreements) {
              this.router.navigate(['/', 'page', '403-forbidden']);
            }

            return hasAllAgreements;
          }),
        ),
      ),
    );
  }
}
