import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { NotificationMessage, showNotification } from '@1clickfactory/notifications'
import { PaymentTokenService } from './payment-token.service'
import * as fromActions from './payment-token.actions'

@Injectable()
export class PaymentTokenEffects {

  onGetBraintreeToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getBraintreeToken),
      switchMap(() =>
        this.paymentTokenService.get().pipe(
          map(braintreeToken => fromActions.getBraintreeTokenComplete({ braintreeToken })),
          catchError(() => of(fromActions.getBraintreeTokenError())),
        ),
      ),
    ),
  )

  onGetBraintreeTokenError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getBraintreeTokenError),
      map(() => showNotification({ message: NotificationMessage.BraintreeError })),
    ),
  )

  constructor(private actions$: Actions, private paymentTokenService: PaymentTokenService) {}
}
