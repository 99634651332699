import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { GroupedRole } from '@appState';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Role Groups]';

export const getUserGroupedRoles = createAction(`${NAMESPACE} GetUserGroupedRoles`, props<{ userId: string; partnerId: string }>());
export const getAuthenticatedUserGroupedRoles = createAction(`${NAMESPACE} GetAuthenticatedUserGroupedRoles`);
export const getUserGroupedRolesComplete = createAction(
  `${NAMESPACE} GetUserGroupedRolesComplete`,
  props<{ groupedRoles: GroupedRole[] }>(),
);
export const getUserGroupedRolesError = createAction(
  `${NAMESPACE} GetUserGroupedRolesError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);
