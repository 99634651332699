import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';
import * as routerSelectors from '../router-state/router-state.selectors';
import { PanelText } from './active-page-panel-text.model';
import * as activePageReducers from './active-page.reducer';

/**
 * Defines actions to read project entities
 */
export const selectModuleState = createSelector(getRootState, p => p?.activePages);
export const { selectEntities, selectIds } = activePageReducers.adapter.getSelectors(selectModuleState);
export const selectCurrentId = createSelector(routerSelectors.selectCurrentUrlWithoutParams, url => url && convertUrlToId(url));
export const getCurrentPage = createSelector(selectEntities, selectCurrentId, (p, id) => p?.[id]);
export const getTopText = createSelector(getCurrentPage, p => p?.Text ?? '');
export const getBottomText = createSelector(getCurrentPage, p => p?.BottomText ?? '');

export const getPanelText = (panelIndex: number, topText: boolean) =>
  createSelector(getCurrentPage, p => {
    if (!p?.WizardTexts) {
      return '';
    }
    const searchText = `__${panelIndex}__${topText ? 'top' : 'bottom'}`;
    const panelText = p.WizardTexts.find(({ Key }) => Key === searchText);
    return (panelText ? panelText.Content : '') || '';
  });

export const getPanelsText = createSelector(getCurrentPage, (p): Record<string, string> | undefined =>
  p?.WizardTexts?.reduce((map: Record<string, string>, text: PanelText) => {
    map[text.Key] = text.Content;
    return map;
  }, {}),
);

const convertUrlToId = (url: string): string => {
  const sanitizedUri = url.replace(/\//g, '.').toLowerCase().substr(1);
  return encodeURI(sanitizedUri);
};
