import { Action } from '@ngrx/store'
import { Update } from '@ngrx/entity/src/models'
import { Form, FormAskookErrorCode } from './form.model'

export enum Actions {
  CreateForm = '[Form] CreateForm',
  UpdateForm = '[Form] UpdateForm',
  TrySubmitForm = '[Form] TrySubmitForm',
  SetValidatable = '[Form] SetValidatable',
  SubmitForm = '[Form] SubmitForm',
  SubmitFormComplete = '[Form] SubmitFormComplete',
  SubmitFormError = '[Form] SubmitFormError',
  UpdateData = '[Form] UpdateData',
  PostData = '[Form] PostData',
  SetAskookErrorCode = '[Form] SetAskookErrorCode',
}

export class CreateForm implements Action {
  readonly type = Actions.CreateForm

  constructor(public payload: Form) {}
}

export class UpdateForm implements Action {
  readonly type = Actions.UpdateForm

  constructor(public payload: Update<Form>) {}
}

export class SubmitForm implements Action {
  readonly type = Actions.SubmitForm

  constructor(public payload: Form) {}
}

export class TrySubmitForm implements Action {
  readonly type = Actions.TrySubmitForm

  constructor(public payload: string) {}
}

export class SetValidatable implements Action {
  readonly type = Actions.SetValidatable

  constructor(public payload: string) {}
}

export class SubmitFormComplete implements Action {
  readonly type = Actions.SubmitFormComplete

  constructor(public payload: string) {}
}

export class SubmitFormError implements Action {
  readonly type = Actions.SubmitFormError

  constructor(public payload: string) {}
}

export class UpdateData implements Action {
  readonly type = Actions.UpdateData

  constructor(public payload: Form) {}
}

export class PostData implements Action {
  readonly type = Actions.PostData

  constructor(public payload: Form) {}
}

export class SetAskookErrorCode implements Action {
  readonly type = Actions.SetAskookErrorCode

  constructor(public payload: FormAskookErrorCode) {}
}

export type All =
  | CreateForm
  | UpdateForm
  | SetValidatable
  | TrySubmitForm
  | SubmitForm
  | SubmitFormComplete
  | SubmitFormError
  | UpdateData
  | PostData
  | SetAskookErrorCode
