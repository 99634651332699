import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { createAction, props } from '@ngrx/store';
import { ChangeDeviceResponse, ChangePasswordRequest, UserCreateRequest, UserResponse, UserUpdateRequest } from './user.model';

const NAMESPACE = '[Users]';

export const getUsersList = createAction(`${NAMESPACE} GetUserList`);
export const getUsersListComplete = createAction(`${NAMESPACE} GetUsersListComplete`, props<{ users: UserResponse[] }>());
export const getUserListError = createAction(`${NAMESPACE} GetUsersListError`, props<{ error: HighlanderCoreErrorResponse }>());

export const getUser = createAction(`${NAMESPACE} GetUser`, props<{ id: string }>());
export const getUserComplete = createAction(`${NAMESPACE} GetUserComplete`, props<{ user: UserResponse }>());
export const getUserError = createAction(`${NAMESPACE} GetUserError`, props<{ error: HighlanderCoreErrorResponse }>());

export const createUser = createAction(`${NAMESPACE} CreateUser`, props<{ model: UserCreateRequest }>());
export const createUserComplete = createAction(
  `${NAMESPACE} CreateUserComplete`,
  props<{ createdUser: UserResponse; redirectUrl: string }>(),
);
export const createUserError = createAction(`${NAMESPACE} CreateUserError`, props<{ error: HighlanderCoreErrorResponse }>());

export const updateUser = createAction(`${NAMESPACE} UpdateUser`, props<{ id: string; model: UserUpdateRequest }>());
export const updateUserComplete = createAction(`${NAMESPACE} UpdateUserComplete`, props<{ updatedUser: UserResponse }>());
export const updateUserError = createAction(`${NAMESPACE} UpdateUserError`, props<{ error: HighlanderCoreErrorResponse }>());

export const changePassword = createAction(`${NAMESPACE} ChangePassword`, props<{ model: ChangePasswordRequest }>());
export const changePasswordComplete = createAction(`${NAMESPACE} ChangePasswordComplete`, props<{ redirectUrl: string }>());
export const changePasswordError = createAction(`${NAMESPACE} ChangePasswordError`, props<{ error: HighlanderCoreErrorResponse }>());

export const markforDeletion = createAction(`${NAMESPACE} MarkforDeletion`, props<{ id: string }>());
export const markforDeletionComplete = createAction(`${NAMESPACE} MarkforDeletionComplete`, props<{ redirectUrl: string }>());
export const markforDeletionError = createAction(`${NAMESPACE} MarkforDeletionError`, props<{ error: HighlanderCoreErrorResponse }>());

export const blockUnblockUser = createAction(`${NAMESPACE} BlockUnblockUser`, props<{ id: string; isBlocking: boolean }>());
export const blockUnblockUserError = createAction(`${NAMESPACE} BlockUnblockUserError`, props<{ error: HighlanderCoreErrorResponse }>());

export const resetTwoFactor = createAction(`${NAMESPACE} resetTwoFactor`, props<{ id: string }>());
export const resetTwoFactorComplete = createAction(`${NAMESPACE} resetTwoFactorComplete`, props<{ redirectUrl: string }>());
export const resetTwoFactorError = createAction(`${NAMESPACE} resetTwoFactorError`, props<{ error: HighlanderCoreErrorResponse }>());

export const changeDevice = createAction(`${NAMESPACE} ChangeDevice`);
export const changeDeviceComplete = createAction(`${NAMESPACE} ChangeDeviceComplete`, props<{ data: ChangeDeviceResponse }>());
export const changeDeviceError = createAction(`${NAMESPACE} ChangeDeviceError`, props<{ error: HighlanderCoreErrorResponse }>());

export const verifyCode = createAction(`${NAMESPACE} VerifyCode`, props<{ code: string }>());
export const verifyCodeComplete = createAction(`${NAMESPACE} VerifyCodeComplete`, props<{ redirectUrl: string }>());
export const verifyCodeError = createAction(`${NAMESPACE} VerifyCodeError`, props<{ error: HighlanderCoreErrorResponse }>());

export const resetSelectedUserId = createAction(`${NAMESPACE} ResetSelectedUserId`);
