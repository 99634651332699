import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Observable, tap } from 'rxjs';
import { AppConfig, ConfigService } from '../config/app-config.service';

export const loggingInitializer = (configService: ConfigService, loggingService: LoggingService): (() => Observable<AppConfig>) => {
  return () => configService.loadConfig().pipe(tap(config => loggingService.init(config)));
};

@Injectable({ providedIn: 'root' })
export class LoggingService {
  appInsights!: ApplicationInsights;
  constructor() {}

  logPageView(name?: string, url?: string): void {
    if (!this.isInitialized) return;
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    if (!this.isInitialized) return;
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    if (!this.isInitialized) return;
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, properties?: { [key: string]: any }, severityLevel?: number): void {
    if (!this.isInitialized) return;
    this.appInsights.trackException({ exception: exception, properties: properties, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    if (!this.isInitialized) return;
    this.appInsights.trackTrace({ message: message }, properties);
  }

  init(config: AppConfig): void {
    if (!config['FRONTEND_APPINSIGHTS_INSTRUMENTATIONKEY']) return;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: config['FRONTEND_APPINSIGHTS_INSTRUMENTATIONKEY'],
        loggingLevelTelemetry: 2,
        loggingLevelConsole: 2,
        enableRequestHeaderTracking: true,
        disableExceptionTracking: true,
      },
    });

    this.appInsights.addTelemetryInitializer(envelope => {
      if (envelope.baseType === 'RemoteDependencyData') {
        const request = envelope!.baseData!;
        envelope.data = {
          CorrelationId: request['properties'].requestHeaders['x-highlander-correlation-id'],
          RenderedMessage: `FE Portal: ${envelope.baseData!['name']}`,
        };
        if (request.type === 'Ajax' && request.success === false && !navigator.onLine) {
          return false;
        }
      }
    });

    this.appInsights.loadAppInsights();

    if (config['FRONTEND_HOST'] === 'https://partner.companial.com') {
      this.addGoogleAnalytics(config);
    }
  }

  private get isInitialized(): boolean {
    return !!this.appInsights;
  }

  private addGoogleAnalytics(config: AppConfig): void {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');

    script1.async = true;
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-33QW9FEZP1';

    script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-33QW9FEZP1');
    `;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }
}
