import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttachmentURL } from 'cui-components';
import { Observable, map, of, tap } from 'rxjs';
import { CategoryResponse, ICategory, ISeverity, IType, SeverityResponse, TypeResponse } from './support-setup.model';
import {
  CreateNoteRequest,
  CreateNoteResponse,
  CreateTicketRequest,
  DownloadAttachment,
  IAttachment,
  Note,
  ResolveCase,
  Ticket,
  TicketListItem,
} from './support.model';

@Injectable()
export class SupportService {
  private readonly path = (url = ''): string => `support/${url}`;
  private readonly setupCache: Record<string, unknown[]> = {
    categories: [],
    severities: [],
    types: [],
  };

  constructor(private highlanderService: HighlanderService) {}

  getAll(includeAllCompanyTickets = false): Observable<TicketListItem[]> {
    return this.highlanderService.getAll<TicketListItem>(this.path('tickets'), {
      params: { includeAllCompanyTickets },
      headers: { 'api-version': '3' },
    });
  }

  get(id: string): Observable<Ticket> {
    return this.highlanderService.get<Ticket>(this.path(`tickets/${id}`), { headers: { 'api-version': '3' } });
  }

  create(model: CreateTicketRequest): Observable<Ticket> {
    return this.highlanderService.post<CreateTicketRequest, Ticket>(this.path('tickets'), model, {
      headers: { 'api-version': '3' },
    });
  }

  addNote(model: CreateNoteRequest, id: string): Observable<CreateNoteResponse> {
    return this.highlanderService.post<CreateNoteRequest, CreateNoteResponse>(this.path(`tickets/${id}/notes`), model, {
      headers: { 'api-version': '3' },
    });
  }

  resolveCase(id: string): Observable<ResolveCase> {
    return this.highlanderService.post<undefined, ResolveCase>(this.path(`tickets/${id}/resolve-ticket`), undefined, {
      headers: { 'api-version': '3' },
    });
  }

  getNote(id: string): Observable<Note> {
    return this.highlanderService.get<Note>(this.path(`notes/${id}`));
  }

  getAttachmentUploadUrl(amountOfAttachments: number): Observable<AttachmentURL[]> {
    return this.highlanderService.get<AttachmentURL[]>(this.path(`attachment-upload-url`), {
      params: { amountOfAttachments },
      headers: { 'api-version': '3' },
    });
  }

  downloadAttachments(attachment: IAttachment): Observable<DownloadAttachment> {
    return this.highlanderService
      .download<HttpResponse<Blob>>(this.path(`notes/${attachment.noteId}/attachments/${attachment.id}/download`), {
        headers: { 'api-version': '3' },
      })
      .pipe(map(res => ({ blob: res.body, fileName: attachment.fileName }) as DownloadAttachment));
  }

  getCategories(): Observable<ICategory[]> {
    return this.getSetup<CategoryResponse>('categories');
  }

  getSeverities(): Observable<ISeverity[]> {
    return this.getSetup<SeverityResponse>('severities');
  }

  getTypes(): Observable<IType[]> {
    return this.getSetup<TypeResponse>('types');
  }

  private getSetup<T>(field: string): Observable<T[]> {
    if (this.setupCache[field].length) {
      return of(this.setupCache[field] as T[]);
    } else {
      return this.highlanderService
        .getAll<T>(this.path(field), { headers: { 'api-version': '3' } })
        .pipe(tap(data => (this.setupCache[field] = data)));
    }
  }
}
