import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AgreementContent, PublicAgreement, SignedAgreemenVersion, SignedAgreementListItem } from './agreement.model';

@Injectable()
export class AgreementsService {
  private readonly path = (url = ''): string => `agreements/${url}`;

  constructor(private highlanderService: HighlanderService) {}

  getSignedAgreements(): Observable<SignedAgreementListItem[]> {
    return this.highlanderService.getAll<SignedAgreementListItem>(this.path('signed'));
  }

  getSignedAgreementVersion(agreementId: string, versionId: string): Observable<SignedAgreemenVersion> {
    return this.highlanderService.get<SignedAgreemenVersion>(this.path(`signed/${agreementId}/version/${versionId}`));
  }

  getPublishedAgreement(publicLink: string): Observable<PublicAgreement> {
    return this.highlanderService.get<PublicAgreement>(this.path(`${publicLink}`));
  }

  getAgreementContent(agreementId: string): Observable<AgreementContent> {
    return this.highlanderService.get<AgreementContent>(this.path(`content/${agreementId}`));
  }
}
