import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IChangeDevice, IUser } from './user.model';
import * as fromActions from './users.actions';

export interface State extends EntityState<IUser> {
  isLoading: boolean;
  selectedUserId: string | null;
  changeDevice: IChangeDevice | null;
  isVerifyingCode: boolean;
}

export const adapter: EntityAdapter<IUser> = createEntityAdapter<IUser>();

const initialState: State = adapter.getInitialState({
  isLoading: false,
  selectedUserId: null,
  changeDevice: null,
  isVerifyingCode: false,
});

export const usersReducer = createReducer(
  initialState,

  on(fromActions.getUsersList, state => ({ ...state, isLoading: true })),
  on(fromActions.getUsersListComplete, (state, { users }) => adapter.setAll(users, { ...state, isLoading: false })),
  on(fromActions.getUserListError, state => ({ ...state, isLoading: false })),

  on(fromActions.getUser, (state, { id }) => ({ ...state, selectedUserId: id, isLoading: true })),
  on(fromActions.getUserComplete, (state, { user }) => adapter.setOne(user, { ...state, isLoading: false })),
  on(fromActions.getUserError, state => ({ ...state, isLoading: false })),

  on(fromActions.createUser, state => ({ ...state, isLoading: true })),
  on(fromActions.createUserComplete, (state, { createdUser }) =>
    adapter.addOne(createdUser, { ...state, selectedUserId: createdUser.id, isLoading: false }),
  ),
  on(fromActions.createUserError, state => ({ ...state, isLoading: false })),

  on(fromActions.updateUser, state => ({ ...state, isLoading: true })),
  on(fromActions.updateUserComplete, (state, { updatedUser }) => adapter.upsertOne(updatedUser, { ...state, isLoading: false })),
  on(fromActions.updateUserError, state => ({ ...state, isLoading: false })),

  on(fromActions.changePassword, state => ({ ...state, isLoading: true })),
  on(fromActions.changePasswordComplete, fromActions.changePasswordError, state => ({ ...state, isLoading: false })),

  on(fromActions.markforDeletion, (state, { id }) => adapter.removeOne(id, { ...state, isLoading: true })),
  on(fromActions.markforDeletionComplete, fromActions.markforDeletionError, state => ({ ...state, isLoading: false })),

  on(fromActions.blockUnblockUser, state => ({ ...state, isLoading: true })),
  on(fromActions.blockUnblockUserError, state => ({ ...state, isLoading: false })),

  on(fromActions.resetTwoFactor, state => ({ ...state, isLoading: true })),
  on(fromActions.resetTwoFactorComplete, fromActions.resetTwoFactorError, state => ({ ...state, isLoading: false })),

  on(fromActions.changeDevice, state => ({ ...state, isLoading: true })),
  on(fromActions.changeDeviceComplete, (state, { data }) => ({
    ...state,
    changeDevice: { ...data },
    isLoading: false,
  })),
  on(fromActions.changeDeviceError, state => ({ ...state, changeDevice: null, isLoading: false })),

  on(fromActions.verifyCode, state => ({ ...state, isVerifyingCode: true })),
  on(fromActions.verifyCodeComplete, state => ({ ...state, changeDevice: null, isVerifyingCode: false })),
  on(fromActions.verifyCodeError, state => ({ ...state, isVerifyingCode: false })),

  on(fromActions.resetSelectedUserId, state => ({ ...state, selectedUserId: null })),
);
