import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import * as actions from './actions'
import { State } from './models'

export interface StateState extends EntityState<State> {}

export const adapter: EntityAdapter<State> = createEntityAdapter<State>({
  selectId: (state: State) => state.Id,
})

export const initialState: StateState = adapter.getInitialState({})

const statesReducer = createReducer(
  initialState,
  on(actions.queryStatesComplete, (state, { states }) => adapter.addMany(states, state)),
)

export function reducer(state, action: Action): StateState {
  return statesReducer(state, action)
}
