import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment, EnvironmentListItem, EnvironmentListItemResponse, EnvironmentResponse } from './environment.model';

@Injectable()
export class EnvironmentsService {
  private readonly url = 'environments';
  constructor(private highlanderService: HighlanderService) {}

  getAll(coreCustomerId?: string): Observable<EnvironmentListItem[]> {
    return this.highlanderService.get<EnvironmentListItemResponse[]>(this.url, { params: coreCustomerId ? { coreCustomerId } : {} });
  }
  getOne(environmentId: string): Observable<Environment> {
    return this.highlanderService.get<EnvironmentResponse>(`${this.url}/${environmentId}`);
  }
}
