import { NgModule } from '@angular/core';

import { FromNowPipe } from './from-now.pipe';
import { MoneyPipe } from './money.pipe';
import { NotEmptyPipe } from './not-empty.pipe';
import { ParseHtmlPipe } from './parse-html.pipe';
import { PrefixPipe } from './prefix.pipe';
import { SuffixPipe } from './suffix.pipe';
import { TakePipe } from './take.pipe';
import { TruncatePipe } from './truncate.pipe';
import { QuantityHintPipe } from '@1clickfactory/common/pipes/quantity-hint.pipe';
import { AbsoluteNumberPipe } from '@1clickfactory/common/pipes/absolute-number.pipe';
import { IsoDateWithTimePipe } from './iso-date-with-time.pipe';

const exportedDeclarations = [
  FromNowPipe,
  IsoDateWithTimePipe,
  MoneyPipe,
  NotEmptyPipe,
  ParseHtmlPipe,
  PrefixPipe,
  TakePipe,
  TruncatePipe,
  SuffixPipe,
  QuantityHintPipe,
  AbsoluteNumberPipe,
];

@NgModule({
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class PipesModule {}
