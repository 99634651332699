import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { OcfAlertModule } from '@1clickfactory/simple-ui/components/alert/alert.module'
// noinspection ES6PreferShortImport
import { OcfFailingValidationNoticeComponent } from './ocf-failing-validation-notice.component'
// noinspection ES6PreferShortImport
import { OcfFormPendingNoticesComponent } from './ocf-form-pending-notices.component'
// noinspection ES6PreferShortImport
import { OcfReadonlyNoticeComponent } from './ocf-readonly-notice.component'

const RE_EXPORTED_DECLARATIONS = [OcfFormPendingNoticesComponent, OcfFailingValidationNoticeComponent, OcfReadonlyNoticeComponent]

@NgModule({
  declarations: RE_EXPORTED_DECLARATIONS,
  exports: RE_EXPORTED_DECLARATIONS,
  imports: [CommonModule, OcfAlertModule],
})
export class OcfNoticesModule {}
