import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';

@Pipe({
  name: 'fromNow',
  pure: true,
})
export class FromNowPipe implements PipeTransform {
  transform(value: Date | string): string {
    return formatDistanceToNow(new Date(value), { addSuffix: true });
  }
}
