import { baseEnvironment } from './environment.base'

// This file is used for development, uat, and production builds

export const environment = {
  ...baseEnvironment,
  debugMode: false,
  production: true,
  showMissingTranslations: false,
}
