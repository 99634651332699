import { AskookErrorCode } from '@1clickfactory/backend/askook-error-code';
import { Action } from '@ngrx/store';

export interface DTO<T> {
  /**
   * Primary key of action to perform; will be used as ID param in queries
   */
  UId?: string;

  /**
   * Secondary key of action to perform; can be used when listening to completion events
   */
  Tag?: string;

  /**
   * Serves both as a tag for observing and controller name to perform action on
   */
  Target: string;

  /**
   * Data to send
   */
  Data?: T;

  /**
   * Route parameters
   */
  RouteParams?: Array<[string, string | number | boolean]>;

  /**
   * If not null, will emit this event !!instead!! of standard "complete" on success.
   * Most common use case will be to "submit form and forget"
   */
  OverrideCompleteAction?: (data: DTO<T>) => Action;

  /**
   * If not null, will emit this event !!instead!! of standard "error" on error
   * Most common use case will be to "submit form and forget"
   */
  OverrideErrorAction?: (data: DTO<T>, e: AskookErrorCode) => Action;
}

export class RequestDTO<T> implements DTO<T> {
  UId: string;

  Tag: string;

  Data!: T;
  RouteParams?: Array<[string, string | number | boolean]>;
  OverrideCompleteAction!: (data: DTO<T>) => Action;
  OverrideErrorAction!: (data: DTO<T>, e: AskookErrorCode) => Action;
  Target: string;

  constructor(uid: string, backEndPoint: string) {
    this.RouteParams = [];
    this.UId = uid;
    this.Tag = uid;
    this.Target = backEndPoint;
  }

  static create<T>(uid: string, backEndPoint: string): RequestDTO<T> {
    return new RequestDTO<T>(uid, backEndPoint);
  }

  addTag(tag: string): RequestDTO<T> {
    this.Tag = tag;
    return this;
  }

  addRouteParam(name: string, value: string | number | boolean): RequestDTO<T> {
    this.RouteParams = [...this.RouteParams!, [name, value]];
    return this;
  }

  addData(data: T): RequestDTO<T> {
    this.Data = data;
    return this;
  }

  onCompleted(action: (data: DTO<T>) => Action): RequestDTO<T> {
    this.OverrideCompleteAction = action;
    return this;
  }

  onError(action: (data: DTO<T>, e: AskookErrorCode) => Action): RequestDTO<T> {
    this.OverrideErrorAction = action;
    return this;
  }
}
