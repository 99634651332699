import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { usersSelectors } from '@appState';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/app-state/app.store';

@Injectable()
export class TwoFaGuard implements CanActivate {
  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(select(usersSelectors.selectCurrentUser)).pipe(
      map(user => {
        if (user && user.isTwoFactorEnabled) {
          return true;
        } else {
          this.router.navigate(['/', 'page', '403-forbidden']);
          return false;
        }
      }),
    );
  }
}
