import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'suffix',
  pure: true,
})
export class SuffixPipe implements PipeTransform {
  transform(value: string, ...args: string[]): string {
    return [value, ...args].join('.')
  }
}
