import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { AppState } from '../app.store';
import * as fromActions from './active-page.actions';
import * as fromSelectors from './active-page.selectors';
import { ActivePageService } from './active-page.service';

@Injectable()
export class PageEffects {
  onRouterNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      switchMap(() =>
        combineLatest([this.store.pipe(select(fromSelectors.selectCurrentId)), this.store.pipe(select(fromSelectors.selectIds))]).pipe(
          filter(([id, knownIds]) => !!id && (knownIds as string[]).indexOf(id) === -1),
          take(1),
        ),
      ),
      // This is for preventing an effect from firing Askook service on rewrited pages
      filter(([id]) => ['project', 'end-customer'].includes(id.split('.')[0])),
      mergeMap(([id]) =>
        this.activePageService.get(id).pipe(
          map(activePage => fromActions.getComplete({ activePage })),
          catchError(() => of(fromActions.getError({ id }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private activePageService: ActivePageService,
  ) {}
}
