import { Cookies } from '@1clickfactory/common';
import { Injectable } from '@angular/core';

@Injectable()
export class ImpersonationCookiesService {
  private readonly partnerCookieKey = 'partner_ID_override';
  private readonly partnerNameCookieKey = 'partner_ID_name_override';

  getSelectedPartner(): { id: string; name: string } | null {
    const id = Cookies.get(this.partnerCookieKey);
    const name = Cookies.get(this.partnerNameCookieKey);

    if (!id || !name) {
      return null;
    }

    return { id, name };
  }

  setSelectedPartner(partnerId: string, partnerName: string): void {
    if (!partnerId || !partnerName) {
      return;
    }

    Cookies.set(this.partnerCookieKey, partnerId);
    Cookies.set(this.partnerNameCookieKey, partnerName);
  }

  clearSelectedPartner(): void {
    Cookies.remove(this.partnerCookieKey);
    Cookies.remove(this.partnerNameCookieKey);
  }
}
