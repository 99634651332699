import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { identitySelectors, mmUtilActions, mmUtilSelectors, partnersSelectors } from '@appState';
import { select, Store } from '@ngrx/store';
import {
  CuiAlertComponent,
  CuiButtonComponent,
  CuiButtonCtaComponent,
  CuiCardModule,
  CuiCheckboxComponent,
  CuiInputComponent,
  isNotNullOrUndefined,
  SubscribingBase,
} from 'cui-components';
import { combineLatest, filter, map, Subscription, take, tap } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';
import { JoinMMProgramForm, JoinMMProgramFormBuilder } from './join-mm-program-form.builder';
@Component({
  standalone: true,
  imports: [
    CuiButtonCtaComponent,
    CuiButtonComponent,
    CuiCardModule,
    CuiInputComponent,
    CuiCheckboxComponent,
    ReactiveFormsModule,
    CommonModule,
    CuiAlertComponent,
  ],
  providers: [JoinMMProgramFormBuilder],
  selector: 'ocf-join-mm-program',
  styleUrls: ['./join-mm-program.component.scss'],
  templateUrl: './join-mm-program.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JoinMMProgramComponent extends SubscribingBase implements OnDestroy {
  isLoading$ = combineLatest({
    joinRequestInProgress: this.store.pipe(select(mmUtilSelectors.selectJoinMMRequestIsLoading)),
    partnersLoading: this.store.pipe(select(partnersSelectors.selectIsLoading)),
  }).pipe(map(({ joinRequestInProgress, partnersLoading }) => joinRequestInProgress || partnersLoading));

  showAlert$ = this.store.pipe(select(mmUtilSelectors.selectShowAlert));

  valueChangeSubscription!: Subscription;

  data$ = combineLatest({
    partner: this.store.pipe(select(partnersSelectors.selectCurrentPartner), filter(isNotNullOrUndefined)),
    user: this.store.pipe(select(identitySelectors.selectIdentity), filter(isNotNullOrUndefined)),
  }).pipe(
    tap(({ partner, user }) => {
      this.form = this.formBuilder.build(user, partner);

      this.valueChangeSubscription = this.form.controls.agreement.valueChanges.pipe(take(1)).subscribe(() => {
        this.form.controls.agreement.markAsTouched();
      });
    }),
  );

  agreementLabel = `I accept Companial Business <a href="/agreements/MMProgram" target="_blank">Central Migration and Modernization Program Agreement</a>`;

  form!: FormGroup<JoinMMProgramForm>;

  constructor(
    private readonly store: Store<AppState>,
    private readonly formBuilder: JoinMMProgramFormBuilder,
    private readonly router: Router,
  ) {
    super();
  }

  onSubmit(): void {
    this.store.dispatch(mmUtilActions.sendRequestToJoinMMProgram());
    this.form.reset();
  }

  onCancel(): void {
    this.router.navigate(['dashboard']);
  }

  ngOnDestroy(): void {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }
}
