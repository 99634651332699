import { stringSortFn } from '@1clickfactory/common/helpers/sort.helpers';

export interface Country {
  id: number;
  name: string;
  countryCode: string;
  hasStates?: boolean;
  zipCodeRegexp?: RegExp;
  vatIsOptional: boolean;
}

/**
 * ATTENTION!
 * NAV also uses this list - please notify them before making any changes!
 */
export const CountryRepository: Country[] = [
  {
    id: 1,
    name: 'Afghanistan',
    countryCode: 'AF',
    vatIsOptional: true,
  },
  {
    id: 2,
    name: 'Aland Islands',
    countryCode: 'AX',
    vatIsOptional: true,
  },
  {
    id: 3,
    name: 'Albania',
    countryCode: 'AL',
    vatIsOptional: true,
  },
  {
    id: 4,
    name: 'Algeria',
    countryCode: 'DZ',
    vatIsOptional: true,
  },
  {
    id: 5,
    name: 'American Samoa',
    countryCode: 'AS',
    vatIsOptional: true,
  },
  {
    id: 6,
    name: 'Andorra',
    countryCode: 'AD',
    vatIsOptional: true,
    zipCodeRegexp: new RegExp('^[Aa][Dd]\\d{3}$'),
  },
  {
    id: 7,
    name: 'Angola',
    countryCode: 'AO',
    vatIsOptional: true,
  },
  {
    id: 8,
    name: 'Anguilla',
    countryCode: 'AI',
    vatIsOptional: true,
  },
  {
    id: 9,
    name: 'Antarctica',
    countryCode: 'AQ',
    vatIsOptional: true,
  },
  {
    id: 10,
    name: 'Antigua and Barbuda',
    countryCode: 'AG',
    vatIsOptional: true,
  },
  {
    id: 11,
    name: 'Argentina',
    countryCode: 'AR',
    vatIsOptional: true,
  },
  {
    id: 12,
    name: 'Armenia',
    countryCode: 'AM',
    vatIsOptional: true,
  },
  {
    id: 13,
    name: 'Aruba',
    countryCode: 'AW',
    vatIsOptional: true,
  },
  {
    id: 15,
    name: 'Australia',
    countryCode: 'AU',
    hasStates: true,
    vatIsOptional: true,
  },
  {
    id: 16,
    name: 'Austria',
    countryCode: 'AT',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^[1-9]\\d{3}$'),
  },
  {
    id: 17,
    name: 'Azerbaijan',
    countryCode: 'AZ',
    vatIsOptional: true,
  },
  {
    id: 18,
    name: 'Bahamas',
    countryCode: 'BS',
    vatIsOptional: true,
  },
  {
    id: 19,
    name: 'Bahrain',
    countryCode: 'BH',
    vatIsOptional: true,
  },
  {
    id: 20,
    name: 'Bangladesh',
    countryCode: 'BD',
    vatIsOptional: true,
  },
  {
    id: 21,
    name: 'Barbados',
    countryCode: 'BB',
    vatIsOptional: true,
  },
  {
    id: 22,
    name: 'Belarus',
    countryCode: 'BY',
    vatIsOptional: true,
  },
  {
    id: 23,
    name: 'Belgium',
    countryCode: 'BE',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{4}$'),
  },
  {
    id: 24,
    name: 'Belize',
    countryCode: 'BZ',
    vatIsOptional: true,
  },
  {
    id: 25,
    name: 'Benin',
    countryCode: 'BJ',
    vatIsOptional: true,
  },
  {
    id: 26,
    name: 'Bermuda',
    countryCode: 'BM',
    vatIsOptional: true,
  },
  {
    id: 27,
    name: 'Bhutan',
    countryCode: 'BT',
    vatIsOptional: true,
  },
  {
    id: 28,
    name: 'Bolivia',
    countryCode: 'BO',
    vatIsOptional: true,
  },
  {
    id: 29,
    name: 'Bonaire, Sint Eustatius, and Saba',
    countryCode: 'BQ',
    vatIsOptional: true,
  },
  {
    id: 30,
    name: 'Bosnia and Herzegovina',
    countryCode: 'BA',
    vatIsOptional: true,
  },
  {
    id: 31,
    name: 'Botswana',
    countryCode: 'BW',
    vatIsOptional: true,
  },
  {
    id: 32,
    name: 'Bouvet Island',
    countryCode: 'BV',
    vatIsOptional: true,
  },
  {
    id: 33,
    name: 'Brazil',
    countryCode: 'BR',
    hasStates: true,
    vatIsOptional: true,
  },
  {
    id: 34,
    name: 'British Indian Ocean Territory',
    countryCode: 'IO',
    vatIsOptional: true,
  },
  {
    id: 35,
    name: 'British Virgin Islands',
    countryCode: 'VG',
    vatIsOptional: true,
  },
  {
    id: 36,
    name: 'Brunei',
    countryCode: 'BN',
    vatIsOptional: true,
  },
  {
    id: 37,
    name: 'Bulgaria',
    countryCode: 'BG',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^[1-9]\\d{3}$'),
  },
  {
    id: 38,
    name: 'Burkina Faso',
    countryCode: 'BF',
    vatIsOptional: true,
  },
  {
    id: 39,
    name: 'Burundi',
    countryCode: 'BI',
    vatIsOptional: true,
  },
  {
    id: 44,
    name: 'Cabo Verde',
    countryCode: 'CV',
    vatIsOptional: true,
  },
  {
    id: 40,
    name: 'Cambodia',
    countryCode: 'KH',
    vatIsOptional: true,
  },
  {
    id: 41,
    name: 'Cameroon',
    countryCode: 'CM',
    vatIsOptional: true,
  },
  {
    id: 42,
    name: 'Canada',
    countryCode: 'CA',
    vatIsOptional: true,
  },
  {
    id: 45,
    name: 'Cayman Islands',
    countryCode: 'KY',
    vatIsOptional: true,
  },
  {
    id: 46,
    name: 'Central African Republic',
    countryCode: 'CF',
    vatIsOptional: true,
  },
  {
    id: 48,
    name: 'Chad',
    countryCode: 'TD',
    vatIsOptional: true,
  },
  {
    id: 49,
    name: 'Chile',
    countryCode: 'CL',
    vatIsOptional: true,
  },
  {
    id: 50,
    name: 'China',
    countryCode: 'CN',
    vatIsOptional: true,
  },
  {
    id: 51,
    name: 'Christmas Island',
    countryCode: 'CX',
    vatIsOptional: true,
  },
  {
    id: 53,
    name: 'Cocos (Keeling) Islands',
    countryCode: 'CC',
    vatIsOptional: true,
  },
  {
    id: 54,
    name: 'Colombia',
    countryCode: 'CO',
    vatIsOptional: true,
  },
  {
    id: 55,
    name: 'Comoros',
    countryCode: 'KM',
    vatIsOptional: true,
  },
  {
    id: 56,
    name: 'Congo',
    countryCode: 'CG',
    vatIsOptional: true,
  },
  {
    id: 57,
    name: 'Congo (DRC)',
    countryCode: 'CD',
    vatIsOptional: true,
  },
  {
    id: 58,
    name: 'Cook Islands',
    countryCode: 'CK',
    vatIsOptional: true,
  },
  {
    id: 59,
    name: 'Costa Rica',
    countryCode: 'CR',
    vatIsOptional: true,
  },
  {
    id: 60,
    name: `Côte d'Ivoire`,
    countryCode: 'CI',
    vatIsOptional: true,
  },
  {
    id: 61,
    name: 'Croatia',
    countryCode: 'HR',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^[1-9]\\d{4}$'),
  },
  {
    id: 62,
    name: 'Cuba',
    countryCode: 'CU',
    vatIsOptional: true,
  },
  {
    id: 63,
    name: 'Curacao',
    countryCode: 'CW',
    vatIsOptional: true,
  },
  {
    id: 64,
    name: 'Cyprus',
    countryCode: 'CY',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^[1-9]\\d{3}$'),
  },
  {
    id: 65,
    name: 'Czechia',
    countryCode: 'CZ',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{3} \\d{2}$'),
  },
  {
    id: 66,
    name: 'Denmark',
    countryCode: 'DK',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^[1-9]\\d{3}$'),
  },
  {
    id: 68,
    name: 'Djibouti',
    countryCode: 'DJ',
    vatIsOptional: true,
  },
  {
    id: 69,
    name: 'Dominica',
    countryCode: 'DM',
    vatIsOptional: true,
  },
  {
    id: 70,
    name: 'Dominican Republic',
    countryCode: 'DO',
    vatIsOptional: true,
  },
  {
    id: 71,
    name: 'Ecuador',
    countryCode: 'EC',
    vatIsOptional: true,
  },
  {
    id: 72,
    name: 'Egypt',
    countryCode: 'EG',
    vatIsOptional: true,
  },
  {
    id: 73,
    name: 'El Salvador',
    countryCode: 'SV',
    vatIsOptional: true,
  },
  {
    id: 74,
    name: 'Equatorial Guinea',
    countryCode: 'GQ',
    vatIsOptional: true,
  },
  {
    id: 75,
    name: 'Eritrea',
    countryCode: 'ER',
    vatIsOptional: true,
  },
  {
    id: 76,
    name: 'Estonia',
    countryCode: 'EE',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^[1-9]\\d{4}$'),
  },
  {
    id: 223,
    name: 'Eswatini',
    countryCode: 'SZ',
    vatIsOptional: true,
  },
  {
    id: 77,
    name: 'Ethiopia',
    countryCode: 'ET',
    vatIsOptional: true,
  },
  {
    id: 78,
    name: 'European Union',
    countryCode: 'EU',
    vatIsOptional: true,
  },
  {
    id: 79,
    name: 'Falkland Islands',
    countryCode: 'FK',
    vatIsOptional: true,
  },
  {
    id: 80,
    name: 'Faroe Islands',
    countryCode: 'FO',
    vatIsOptional: true,
    zipCodeRegexp: new RegExp('^\\d{3}$'),
  },
  {
    id: 81,
    name: 'Fiji',
    countryCode: 'FJ',
    vatIsOptional: true,
  },
  {
    id: 82,
    name: 'Finland',
    countryCode: 'FI',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{5}$'),
  },
  {
    id: 83,
    name: 'France',
    countryCode: 'FR',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{5}$'),
  },
  {
    id: 84,
    name: 'French Guiana',
    countryCode: 'GF',
    vatIsOptional: true,
  },
  {
    id: 85,
    name: 'French Polynesia',
    countryCode: 'PF',
    vatIsOptional: true,
  },
  {
    id: 86,
    name: 'French Southern Territories',
    countryCode: 'TF',
    vatIsOptional: true,
  },
  {
    id: 87,
    name: 'Gabon',
    countryCode: 'GA',
    vatIsOptional: true,
  },
  {
    id: 88,
    name: 'Gambia',
    countryCode: 'GM',
    vatIsOptional: true,
  },
  {
    id: 89,
    name: 'Georgia',
    countryCode: 'GE',
    vatIsOptional: true,
  },
  {
    id: 90,
    name: 'Germany',
    countryCode: 'DE',
    hasStates: true,
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{5}$'),
  },
  {
    id: 91,
    name: 'Ghana',
    countryCode: 'GH',
    vatIsOptional: true,
  },
  {
    id: 92,
    name: 'Gibraltar',
    countryCode: 'GI',
    vatIsOptional: true,
    zipCodeRegexp: new RegExp('^\\d{5}$'),
  },
  {
    id: 93,
    name: 'Greece',
    countryCode: 'GR',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{5}$'),
  },
  {
    id: 94,
    name: 'Greenland',
    countryCode: 'GL',
    vatIsOptional: true,
  },
  {
    id: 95,
    name: 'Grenada',
    countryCode: 'GD',
    vatIsOptional: true,
  },
  {
    id: 96,
    name: 'Guadeloupe',
    countryCode: 'GP',
    vatIsOptional: true,
  },
  {
    id: 97,
    name: 'Guam',
    countryCode: 'GU',
    vatIsOptional: true,
  },
  {
    id: 98,
    name: 'Guatemala',
    countryCode: 'GT',
    vatIsOptional: true,
  },
  {
    id: 99,
    name: 'Guernsey',
    countryCode: 'GG',
    vatIsOptional: true,
  },
  {
    id: 100,
    name: 'Guinea',
    countryCode: 'GN',
    vatIsOptional: true,
  },
  {
    id: 101,
    name: 'Guinea-Bissau',
    countryCode: 'GW',
    vatIsOptional: true,
  },
  {
    id: 102,
    name: 'Guyana',
    countryCode: 'GY',
    vatIsOptional: true,
  },
  {
    id: 103,
    name: 'Haiti',
    countryCode: 'HT',
    vatIsOptional: true,
  },
  {
    id: 104,
    name: 'Heard Island and McDonald Islands',
    countryCode: 'HM',
    vatIsOptional: true,
  },
  {
    id: 105,
    name: 'Honduras',
    countryCode: 'HN',
    vatIsOptional: true,
  },
  {
    id: 106,
    name: 'Hong Kong',
    countryCode: 'HK',
    vatIsOptional: true,
  },
  {
    id: 107,
    name: 'Hungary',
    countryCode: 'HU',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{4}$'),
  },
  {
    id: 108,
    name: 'Iceland',
    countryCode: 'IS',
    vatIsOptional: true,
    zipCodeRegexp: new RegExp('^[1-9]\\d{2}$'),
  },
  {
    id: 109,
    name: 'India',
    countryCode: 'IN',
    hasStates: true,
    vatIsOptional: true,
  },
  {
    id: 110,
    name: 'Indonesia',
    countryCode: 'ID',
    vatIsOptional: true,
  },
  {
    id: 111,
    name: 'Iran',
    countryCode: 'IR',
    vatIsOptional: true,
  },
  {
    id: 112,
    name: 'Iraq',
    countryCode: 'IQ',
    vatIsOptional: true,
  },
  {
    id: 113,
    name: 'Ireland',
    countryCode: 'IE',
    vatIsOptional: false,
  },
  {
    id: 114,
    name: 'Isle of Man',
    countryCode: 'IM',
    vatIsOptional: true,
  },
  {
    id: 115,
    name: 'Israel',
    countryCode: 'IL',
    vatIsOptional: true,
  },
  {
    id: 116,
    name: 'Italy',
    countryCode: 'IT',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{5}$'),
  },
  {
    id: 117,
    name: 'Jamaica',
    countryCode: 'JM',
    vatIsOptional: true,
  },
  {
    id: 118,
    name: 'Japan',
    countryCode: 'JP',
    vatIsOptional: true,
  },
  {
    id: 119,
    name: 'Jersey',
    countryCode: 'JE',
    vatIsOptional: true,
  },
  {
    id: 120,
    name: 'Jordan',
    countryCode: 'JO',
    vatIsOptional: true,
  },
  {
    id: 121,
    name: 'Kazakhstan',
    countryCode: 'KZ',
    vatIsOptional: true,
  },
  {
    id: 122,
    name: 'Kenya',
    countryCode: 'KE',
    vatIsOptional: true,
  },
  {
    id: 123,
    name: 'Kiribati',
    countryCode: 'KI',
    vatIsOptional: true,
  },
  {
    id: 260,
    name: 'Kosovo',
    countryCode: 'XK',
    vatIsOptional: true,
  },
  {
    id: 124,
    name: 'Kuwait',
    countryCode: 'KW',
    vatIsOptional: true,
  },
  {
    id: 125,
    name: 'Kyrgyzstan',
    countryCode: 'KG',
    vatIsOptional: true,
  },
  {
    id: 126,
    name: 'Laos',
    countryCode: 'LA',
    vatIsOptional: true,
  },
  {
    id: 127,
    name: 'Latvia',
    countryCode: 'LV',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^LV-\\d{4}$'),
  },
  {
    id: 128,
    name: 'Lebanon',
    countryCode: 'LB',
    vatIsOptional: true,
  },
  {
    id: 129,
    name: 'Lesotho',
    countryCode: 'LS',
    vatIsOptional: true,
  },
  {
    id: 130,
    name: 'Liberia',
    countryCode: 'LR',
    vatIsOptional: true,
  },
  {
    id: 131,
    name: 'Libya',
    countryCode: 'LY',
    vatIsOptional: true,
  },
  {
    id: 132,
    name: 'Liechtenstein',
    countryCode: 'LI',
    vatIsOptional: true,
    zipCodeRegexp: new RegExp('^[1-9]\\d{3}$'),
  },
  {
    id: 133,
    name: 'Lithuania',
    countryCode: 'LT',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^(LT-)?\\d{5}$'),
  },
  {
    id: 134,
    name: 'Luxembourg',
    countryCode: 'LU',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{4}$'),
  },
  {
    id: 135,
    name: 'Macao',
    countryCode: 'MO',
    vatIsOptional: true,
  },
  {
    id: 137,
    name: 'Madagascar',
    countryCode: 'MG',
    vatIsOptional: true,
  },
  {
    id: 138,
    name: 'Malawi',
    countryCode: 'MW',
    vatIsOptional: true,
  },
  {
    id: 139,
    name: 'Malaysia',
    countryCode: 'MY',
    vatIsOptional: true,
  },
  {
    id: 140,
    name: 'Maldives',
    countryCode: 'MV',
    vatIsOptional: true,
  },
  {
    id: 141,
    name: 'Mali',
    countryCode: 'ML',
    vatIsOptional: true,
  },
  {
    id: 142,
    name: 'Malta',
    countryCode: 'MT',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^[A-Z]{3} [0-9]{4}$'),
  },
  {
    id: 143,
    name: 'Marshall Islands',
    countryCode: 'MH',
    vatIsOptional: true,
  },
  {
    id: 144,
    name: 'Martinique',
    countryCode: 'MQ',
    vatIsOptional: true,
  },
  {
    id: 145,
    name: 'Mauritania',
    countryCode: 'MR',
    vatIsOptional: true,
  },
  {
    id: 146,
    name: 'Mauritius',
    countryCode: 'MU',
    vatIsOptional: true,
  },
  {
    id: 147,
    name: 'Mayotte',
    countryCode: 'YT',
    vatIsOptional: true,
  },
  {
    id: 148,
    name: 'Mexico',
    countryCode: 'MX',
    hasStates: true,
    vatIsOptional: true,
  },
  {
    id: 149,
    name: 'Micronesia',
    countryCode: 'FM',
    vatIsOptional: true,
  },
  {
    id: 150,
    name: 'Moldova',
    countryCode: 'MD',
    vatIsOptional: true,
  },
  {
    id: 151,
    name: 'Monaco',
    countryCode: 'MC',
    vatIsOptional: true,
    zipCodeRegexp: new RegExp('^\\d{5}$'),
  },
  {
    id: 152,
    name: 'Mongolia',
    countryCode: 'MN',
    vatIsOptional: true,
  },
  {
    id: 153,
    name: 'Montenegro',
    countryCode: 'ME',
    vatIsOptional: true,
  },
  {
    id: 154,
    name: 'Montserrat',
    countryCode: 'MS',
    vatIsOptional: true,
  },
  {
    id: 155,
    name: 'Morocco',
    countryCode: 'MA',
    vatIsOptional: true,
  },
  {
    id: 156,
    name: 'Mozambique',
    countryCode: 'MZ',
    vatIsOptional: true,
  },
  {
    id: 157,
    name: 'Myanmar',
    countryCode: 'MM',
    hasStates: true,
    vatIsOptional: true,
  },
  {
    id: 158,
    name: 'Namibia',
    countryCode: 'NA',
    vatIsOptional: true,
  },
  {
    id: 159,
    name: 'Nauru',
    countryCode: 'NR',
    vatIsOptional: true,
  },
  {
    id: 160,
    name: 'Nepal',
    countryCode: 'NP',
    vatIsOptional: true,
  },
  {
    id: 161,
    name: 'Netherlands',
    countryCode: 'NL',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{4} ?[A-Za-z]{2}$'),
  },
  {
    id: 163,
    name: 'New Caledonia',
    countryCode: 'NC',
    vatIsOptional: true,
  },
  {
    id: 164,
    name: 'New Zealand',
    countryCode: 'NZ',
    vatIsOptional: true,
  },
  {
    id: 165,
    name: 'Nicaragua',
    countryCode: 'NI',
    vatIsOptional: true,
  },
  {
    id: 166,
    name: 'Niger',
    countryCode: 'NE',
    vatIsOptional: true,
  },
  {
    id: 167,
    name: 'Nigeria',
    countryCode: 'NG',
    vatIsOptional: true,
  },
  {
    id: 168,
    name: 'Niue',
    countryCode: 'NU',
    vatIsOptional: true,
  },
  {
    id: 169,
    name: 'Norfolk Island',
    countryCode: 'NF',
    vatIsOptional: true,
  },
  {
    id: 170,
    name: 'North Korea',
    countryCode: 'KP',
    vatIsOptional: true,
  },
  {
    id: 136,
    name: 'North Macedonia',
    countryCode: 'MK',
    vatIsOptional: true,
  },
  {
    id: 171,
    name: 'Northern Mariana Islands',
    countryCode: 'MP',
    vatIsOptional: true,
  },
  {
    id: 172,
    name: 'Norway',
    countryCode: 'NO',
    vatIsOptional: true,
    zipCodeRegexp: new RegExp('^\\d{4}$'),
  },
  {
    id: 173,
    name: 'Oman',
    countryCode: 'OM',
    vatIsOptional: true,
  },
  {
    id: 174,
    name: 'Outlying Oceania',
    countryCode: 'QO',
    vatIsOptional: true,
  },
  {
    id: 175,
    name: 'Pakistan',
    countryCode: 'PK',
    vatIsOptional: true,
  },
  {
    id: 176,
    name: 'Palau',
    countryCode: 'PW',
    vatIsOptional: true,
  },
  {
    id: 177,
    name: 'Palestinian Territories',
    countryCode: 'PS',
    vatIsOptional: true,
  },
  {
    id: 178,
    name: 'Panama',
    countryCode: 'PA',
    vatIsOptional: true,
  },
  {
    id: 179,
    name: 'Papua New Guinea',
    countryCode: 'PG',
    vatIsOptional: true,
  },
  {
    id: 180,
    name: 'Paraguay',
    countryCode: 'PY',
    vatIsOptional: true,
  },
  {
    id: 181,
    name: 'Peru',
    countryCode: 'PE',
    vatIsOptional: true,
  },
  {
    id: 182,
    name: 'Philippines',
    countryCode: 'PH',
    vatIsOptional: true,
  },
  {
    id: 183,
    name: 'Pitcairn Islands',
    countryCode: 'PN',
    vatIsOptional: true,
  },
  {
    id: 184,
    name: 'Poland',
    countryCode: 'PL',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^[0-9]{2}-[0-9]{3}$'),
  },
  {
    id: 185,
    name: 'Portugal',
    countryCode: 'PT',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^[0-9]{4}-[0-9]{3}$'),
  },
  {
    id: 186,
    name: 'Puerto Rico',
    countryCode: 'PR',
    vatIsOptional: true,
  },
  {
    id: 187,
    name: 'Qatar',
    countryCode: 'QA',
    vatIsOptional: true,
  },
  {
    id: 188,
    name: 'Reunion',
    countryCode: 'RE',
    vatIsOptional: true,
  },
  {
    id: 189,
    name: 'Romania',
    countryCode: 'RO',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{6}$'),
  },
  {
    id: 190,
    name: 'Russia',
    countryCode: 'RU',
    vatIsOptional: true,
  },
  {
    id: 191,
    name: 'Rwanda',
    countryCode: 'RW',
    vatIsOptional: true,
  },
  {
    id: 192,
    name: 'Saint Barthelemy',
    countryCode: 'BL',
    vatIsOptional: true,
  },
  {
    id: 193,
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    countryCode: 'SH',
    vatIsOptional: true,
  },
  {
    id: 194,
    name: 'Saint Kitts and Nevis',
    countryCode: 'KN',
    vatIsOptional: true,
  },
  {
    id: 195,
    name: 'Saint Lucia',
    countryCode: 'LC',
    vatIsOptional: true,
  },
  {
    id: 196,
    name: 'Saint Martin',
    countryCode: 'MF',
    vatIsOptional: true,
  },
  {
    id: 197,
    name: 'Saint Pierre and Miquelon',
    countryCode: 'PM',
    vatIsOptional: true,
  },
  {
    id: 198,
    name: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
    vatIsOptional: true,
  },
  {
    id: 199,
    name: 'Samoa',
    countryCode: 'WS',
    vatIsOptional: true,
  },
  {
    id: 200,
    name: 'San Marino',
    countryCode: 'SM',
    vatIsOptional: true,
  },
  {
    id: 201,
    name: 'Sao Tome and Principe',
    countryCode: 'ST',
    vatIsOptional: true,
  },
  {
    id: 202,
    name: 'Saudi Arabia',
    countryCode: 'SA',
    vatIsOptional: true,
  },
  {
    id: 203,
    name: 'Senegal',
    countryCode: 'SN',
    vatIsOptional: true,
  },
  {
    id: 204,
    name: 'Serbia',
    countryCode: 'RS',
    vatIsOptional: true,
  },
  {
    id: 206,
    name: 'Seychelles',
    countryCode: 'SC',
    vatIsOptional: true,
  },
  {
    id: 207,
    name: 'Sierra Leone',
    countryCode: 'SL',
    vatIsOptional: true,
  },
  {
    id: 208,
    name: 'Singapore',
    countryCode: 'SG',
    vatIsOptional: true,
  },
  {
    id: 209,
    name: 'Sint Maarten',
    countryCode: 'SX',
    vatIsOptional: true,
  },
  {
    id: 210,
    name: 'Slovakia',
    countryCode: 'SK',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^[0-9]{3} [0-9]{2}$'),
  },
  {
    id: 211,
    name: 'Slovenia',
    countryCode: 'SI',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{4}$'),
  },
  {
    id: 212,
    name: 'Solomon Islands',
    countryCode: 'SB',
    vatIsOptional: true,
  },
  {
    id: 213,
    name: 'Somalia',
    countryCode: 'SO',
    vatIsOptional: true,
  },
  {
    id: 214,
    name: 'South Africa',
    countryCode: 'ZA',
    vatIsOptional: true,
  },
  {
    id: 215,
    name: 'South Georgia and the South Sandwich Islands',
    countryCode: 'GS',
    vatIsOptional: true,
  },
  {
    id: 216,
    name: 'South Korea',
    countryCode: 'KR',
    vatIsOptional: true,
  },
  {
    id: 217,
    name: 'South Sudan',
    countryCode: 'SS',
    vatIsOptional: true,
  },
  {
    id: 218,
    name: 'Spain',
    countryCode: 'ES',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^\\d{5}$'),
  },
  {
    id: 219,
    name: 'Sri Lanka',
    countryCode: 'LK',
    vatIsOptional: true,
  },
  {
    id: 220,
    name: 'Sudan',
    countryCode: 'SD',
    vatIsOptional: true,
  },
  {
    id: 221,
    name: 'Suriname',
    countryCode: 'SR',
    vatIsOptional: true,
  },
  {
    id: 222,
    name: 'Svalbard and Jan Mayen',
    countryCode: 'SJ',
    vatIsOptional: true,
  },
  {
    id: 224,
    name: 'Sweden',
    countryCode: 'SE',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^[1-9]\\d{2} \\d{2}$'),
  },
  {
    id: 225,
    name: 'Switzerland',
    countryCode: 'CH',
    vatIsOptional: true,
    zipCodeRegexp: new RegExp('^[1-9]\\d{3}$'),
  },
  {
    id: 226,
    name: 'Syria',
    countryCode: 'SY',
    vatIsOptional: true,
  },
  {
    id: 227,
    name: 'Taiwan',
    countryCode: 'TW',
    vatIsOptional: true,
  },
  {
    id: 228,
    name: 'Tajikistan',
    countryCode: 'TJ',
    vatIsOptional: true,
  },
  {
    id: 229,
    name: 'Tanzania',
    countryCode: 'TZ',
    vatIsOptional: true,
  },
  {
    id: 230,
    name: 'Thailand',
    countryCode: 'TH',
    vatIsOptional: true,
  },
  {
    id: 231,
    name: 'Timor-Leste',
    countryCode: 'TL',
    vatIsOptional: true,
  },
  {
    id: 232,
    name: 'Togo',
    countryCode: 'TG',
    vatIsOptional: true,
  },
  {
    id: 233,
    name: 'Tokelau',
    countryCode: 'TK',
    vatIsOptional: true,
  },
  {
    id: 234,
    name: 'Tonga',
    countryCode: 'TO',
    vatIsOptional: true,
  },
  {
    id: 235,
    name: 'Trinidad and Tobago',
    countryCode: 'TT',
    vatIsOptional: true,
  },
  {
    id: 237,
    name: 'Tunisia',
    countryCode: 'TN',
    vatIsOptional: true,
  },
  {
    id: 238,
    name: 'Turkey',
    countryCode: 'TR',
    vatIsOptional: true,
  },
  {
    id: 239,
    name: 'Turkmenistan',
    countryCode: 'TM',
    vatIsOptional: true,
  },
  {
    id: 240,
    name: 'Turks and Caicos Islands',
    countryCode: 'TC',
    vatIsOptional: true,
  },
  {
    id: 241,
    name: 'Tuvalu',
    countryCode: 'TV',
    vatIsOptional: true,
  },
  {
    id: 242,
    name: 'U.S. Minor Outlying Islands',
    countryCode: 'UM',
    vatIsOptional: true,
  },
  {
    id: 243,
    name: 'U.S. Virgin Islands',
    countryCode: 'VI',
    vatIsOptional: true,
  },
  {
    id: 244,
    name: 'Uganda',
    countryCode: 'UG',
    vatIsOptional: true,
  },
  {
    id: 245,
    name: 'Ukraine',
    countryCode: 'UA',
    vatIsOptional: true,
  },
  {
    id: 246,
    name: 'United Arab Emirates',
    countryCode: 'AE',
    vatIsOptional: true,
  },
  {
    id: 247,
    name: 'United Kingdom',
    countryCode: 'GB',
    vatIsOptional: false,
    zipCodeRegexp: new RegExp('^[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}$'),
  },
  {
    id: 248,
    name: 'United States',
    countryCode: 'US',
    hasStates: true,
    vatIsOptional: true,
  },
  {
    id: 249,
    name: 'Uruguay',
    countryCode: 'UY',
    vatIsOptional: true,
  },
  {
    id: 250,
    name: 'Uzbekistan',
    countryCode: 'UZ',
    vatIsOptional: true,
  },
  {
    id: 251,
    name: 'Vanuatu',
    countryCode: 'VU',
    vatIsOptional: true,
  },
  {
    id: 252,
    name: 'Vatican City',
    countryCode: 'VA',
    vatIsOptional: true,
  },
  {
    id: 253,
    name: 'Venezuela',
    countryCode: 'VE',
    vatIsOptional: true,
  },
  {
    id: 254,
    name: 'Vietnam',
    countryCode: 'VN',
    vatIsOptional: true,
  },
  {
    id: 255,
    name: 'Wallis and Futuna',
    countryCode: 'WF',
    vatIsOptional: true,
  },
  {
    id: 256,
    name: 'Western Sahara',
    countryCode: 'EH',
    vatIsOptional: true,
  },
  {
    id: 257,
    name: 'Yemen',
    countryCode: 'YE',
    vatIsOptional: true,
  },
  {
    id: 258,
    name: 'Zambia',
    countryCode: 'ZM',
    vatIsOptional: true,
  },
  {
    id: 259,
    name: 'Zimbabwe',
    countryCode: 'ZW',
    vatIsOptional: true,
  },
].sort((a, b) => stringSortFn(a.name, b.name));

export const getCountry = (id: number): Country => CountryRepository.find(country => country.id === id)!;
export const getCountryByName = (name: string): Country => CountryRepository.find(country => country.name === name)!;

export const allCountryCodes = CountryRepository.map(c => c.countryCode);
