import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as userApprovalActions from '../user/user-approval/user-approval.actions';

import * as fromActions from './account.actions';
import { AccountService } from './account.service';

@Injectable()
export class AccountEffects {
  onGet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.get),
      mergeMap(({ id }) =>
        this.accountService.get(id).pipe(
          map(account => fromActions.getComplete({ account })),
          catchError(() => of(fromActions.getError())),
        ),
      ),
    ),
  );

  onDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteAccount),
      mergeMap(({ id }) =>
        this.accountService.delete(id).pipe(
          map(() => fromActions.deleteAccountComplete({ id })),
          catchError(() => of(fromActions.deleteAccountError())),
        ),
      ),
    ),
  );

  onUserRejected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userApprovalActions.rejected),
      map(({ id }) => fromActions.deleteAccountComplete({ id })),
    ),
  );

  constructor(
    private actions$: Actions,
    private accountService: AccountService,
  ) {}
}
