import { UserPermission } from '../authorization/permissions/permissions.model';

export type Identity = {
  id: string;
  email: string;
  externalId: string;
  partnerId: string;
  cpid: string;
  companyId: string;
  isMapped: boolean;
  isTr: boolean;
  agreements: string[];
  familyName: string;
  name: string;
};

export type UserModel = {
  userinfo: ClaimsIdentity;
  permissions: UserPermission[];
  expires_at: number;
};

export type MappedUserModel = {
  userinfo: Identity;
  permissions: UserPermission[];
  expires_at: number;
};

export type ClaimsIdentity = {
  // m/dd/yyyy,guidwithoutdashes
  agrs: string[];
  // Company Id (typeof: {Guid})
  coid: string;
  // External user id (typeof: NavID)
  cpid: string;
  // External partner id (typeof: NavID)
  exid: string;
  // True.toString()
  isma: string;
  // True.toString()
  issr: string;
  // Email
  name: string;
  // NavId
  paid: string;
  // Phone no
  phon: string;
  // Roles
  role: string[];
  // UserId
  sub: string;
  // Last name
  family_name: string;
  // First name
  given_name: string;
};

export const mapClaimsIdentityToIdentity = (c: ClaimsIdentity): Identity => ({
  id: c.sub,
  email: c.name,
  companyId: c.coid,
  externalId: c.exid,
  partnerId: c.paid,
  cpid: c.cpid,
  isMapped: c.isma === 'True',
  isTr: c.issr === 'True',
  agreements: c.agrs,
  familyName: c.family_name,
  name: c.given_name,
});
