import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { AskookRouteParams, RouterData, RouterState } from './router-state.model';

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterState> {
  static getLastSegment(url: string): string {
    const segments = url.split('/');
    const realSegments = segments.filter(s => s.length);
    return realSegments[realSegments.length - 1];
  }

  static getUrl(baseUrl: string, url: UrlSegment[]): string {
    const routeURL: string = url.map(segment => segment.path).join('/');
    return `${baseUrl}/${routeURL}`;
  }

  static getReplacementUrl(routeData: Data): string {
    let replacementURL: string = routeData.replacementURL;
    if (replacementURL && replacementURL.length) {
      replacementURL = replacementURL.replace('{{id}}', routeData.id);
    }
    return replacementURL;
  }

  static getIsVirtual(routeData: Data): boolean {
    return (routeData.virtual || false).toString() === 'true';
  }

  static getIsHidden(routeData: Data): boolean {
    return (routeData.hidden || false).toString() === 'true';
  }

  static getParamMap(route: ActivatedRouteSnapshot): Partial<AskookRouteParams> {
    return route.children?.reduce((acc, r) => ({ ...acc, ...CustomRouterStateSerializer.getParamMap(r) }), { ...route.params });
  }

  serialize(routerState: RouterStateSnapshot): RouterState {
    let route = routerState.root;

    const paramMap = CustomRouterStateSerializer.getParamMap(routerState.root);

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params, data } = route;

    return {
      url,
      params,
      queryParams,
      data: data as RouterData,
      lastSegment: CustomRouterStateSerializer.getLastSegment(url),
      paramMap,
    };
  }
}
