import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { GroupedRole } from '@appState';
import { Observable } from 'rxjs';

@Injectable()
export class RoleGroupsService {
  private readonly path = (url = ''): string => `authorization/grouped-roles/${url}`;

  constructor(private highlanderService: HighlanderService) {}

  getAuthenticatedUserGroupedRoles(): Observable<GroupedRole[]> {
    return this.highlanderService.get<GroupedRole[]>(this.path());
  }

  getUserGroupedRoles(userId: string, partnerId: string): Observable<GroupedRole[]> {
    return this.highlanderService.get<GroupedRole[]>(this.path(`${userId}/${partnerId}`));
  }
}
