export enum AskookErrorCode {
  SystemUnknownError = 0,
  SystemArgumentsMissing = 1,

  //#region Validation
  ValidationUnknownError = 100,
  ValidationFieldIsRequired = 101,
  ValidationEmailInvalid = 102,
  //#endregion
}
