import { NotificationMessage, showNotification } from '@1clickfactory/notifications'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { CartItem, CartItemType } from './cart-item.model'
import { CartItemsBulkService } from './cart-items-bulk.service'
import * as fromActions from './cart-items.actions'
import { CartItemsService } from './cart-items.service'

@Injectable()
export class CartItemsEffects {
  onQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.query),
      mergeMap(() =>
        this.cartItemsService.getAll().pipe(
          map((cartItems: CartItem[]) => fromActions.queryComplete({ cartItems })),
          catchError(() => of(fromActions.queryError())),
        ),
      ),
    ),
  )

  onAddToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addToCart),
      mergeMap(({ cartItem }) =>
        this.cartItemsService.post(cartItem).pipe(
          map((item: CartItem) => fromActions.addToCartComplete({ cartItem: item })),
          catchError(() => of(fromActions.addToCartError({ cartItem }))),
        ),
      ),
    ),
  )

  onAddToCartBulk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addToCartBulk),
      mergeMap(({ cartItems }) =>
        this.cartItemBulkService.postAll(cartItems).pipe(
          map((items: CartItem[]) => fromActions.addToCartBulkComplete({ cartItems: items })),
          catchError(() => of(fromActions.addToCartBulkError())),
        ),
      ),
    ),
  )

  onAddToCartError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addToCartError, fromActions.addToCartBulkError),
      map(() => showNotification({ message: NotificationMessage.AddToCartError })),
    ),
  )

  onRemoveFromCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeFromCart),
      mergeMap(({ id }) =>
        this.cartItemsService.delete(id).pipe(
          map(() => fromActions.removeFromCartComplete()),
          catchError(() => of(fromActions.removeFromCartError())),
        ),
      ),
    ),
  )

  onAddToCartComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addToCartComplete, fromActions.addToCartBulkComplete),
        // Notify Askook's cart of the change to cart item count
        map(() => document.dispatchEvent(new Event('shoppingCartUpdated'))),
      ),
    { dispatch: false },
  )

  onGoToCartPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToCartPage),
        switchMap(() => this.router.navigate(['/', 'cart'])),
      ),
    { dispatch: false },
  )

  onGoToCheckoutPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToCheckoutPage),
        switchMap(() => this.router.navigate(['/', 'cart', 'checkout'])),
      ),
    { dispatch: false },
  )

  onGoToCartItemPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToCartItemPage),
        map(({ cartItem: { itemType, projectId, serviceId } }) =>
          itemType === CartItemType.Invoice ? ['/', 'user', 'billing'] : ['/', 'project', projectId, 'service', 'cartservices', serviceId],
        ),
        switchMap(route => this.router.navigate(route)),
      ),
    { dispatch: false },
  )

  constructor(
    private actions$: Actions,
    private router: Router,
    private cartItemsService: CartItemsService,
    private cartItemBulkService: CartItemsBulkService,
  ) {}
}
