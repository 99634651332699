import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';
import * as partnerReducer from './partner.reducer';

/**
 * Defines actions to read project entities
 */
export const selectModuleState = createSelector(getRootState, p => p?.partner);

export const { selectEntities, selectAll } = partnerReducer.adapter.getSelectors(selectModuleState);

export const selectIsLoaded = createSelector(selectModuleState, p => p?.isLoaded ?? false);
