import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { createAction, props } from '@ngrx/store';
import { UserApplication, UserForgotPassword, UserResetPassword } from './user-anonymous.model';

const NAMESPACE = '[User Anonymous]';

export const submitApplicationForm = createAction(
  `${NAMESPACE} SubmitApplicationForm`,
  props<{ model: UserApplication; humanCheckToken: string }>(),
);

export const submitApplicationFormComplete = createAction(`${NAMESPACE} SubmitApplicationFormComplete`);
export const submitApplicationFormError = createAction(
  `${NAMESPACE} SubmitApplicationFormError`,
  props<{ err: HighlanderCoreErrorResponse }>(),
);

export const submitForgotPasswordForm = createAction(
  `${NAMESPACE} SubmitForgotPasswordForm`,
  props<{ model: UserForgotPassword; humanCheckToken: string }>(),
);
export const submitForgotPasswordFormComplete = createAction(`${NAMESPACE} SubmitForgotPasswordFormComplete`);
export const submitForgotPasswordFormError = createAction(
  `${NAMESPACE} SubmitForgotPasswordFormError`,
  props<{ err: HighlanderCoreErrorResponse }>(),
);

export const submitResetPasswordForm = createAction(
  `${NAMESPACE} SubmitResetPasswordForm`,
  props<{ model: UserResetPassword; humanCheckToken: string; resetPasswordToken: string }>(),
);
export const submitResetPasswordFormComplete = createAction(`${NAMESPACE} SubmitResetPasswordFormComplete`);
export const submitResetPasswordFormError = createAction(
  `${NAMESPACE} SubmitResetPasswordFormError`,
  props<{ err: HighlanderCoreErrorResponse }>(),
);

export const tokenValidationError = createAction(`${NAMESPACE} TokenValidationError`);
