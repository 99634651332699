import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterState } from './router-state.model';

export const getRouterRootState = createFeatureSelector<RouterReducerState<RouterState>>('router');

export const getRouterState = createSelector(getRouterRootState, s => s?.state);
export const selectCurrentUrl = createSelector(getRouterState, s => s?.url);
export const selectRouteData = createSelector(getRouterState, s => s?.data);
export const selectPageWizardStep = createSelector(selectRouteData, s => (s && s.pageWizardStep ? s.pageWizardStep : 0));
export const selectRouteParams = createSelector(getRouterState, s => s?.paramMap || {});
export const selectRequiredRoles = createSelector(selectRouteData, p => p?.roles || []);
export const selectRequiredAgreements = createSelector(selectRouteData, p => p?.agreements || []);

export const selectCurrentUrlWithoutParams = createSelector(
  selectRouteParams,
  selectCurrentUrl,
  (params, url) =>
    url &&
    Object.keys(params)
      .map(k => params[k])
      ?.reduce((acc, id) => acc.replace(`/${id}`, ''), url)
      .split('?')[0],
);
