import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { validators } from '@1clickfactory/forms';
import { Identity, PartnerResponse } from '@appState';

export interface JoinMMProgramForm {
  companyName: FormControl<string>;
  firstName: FormControl<string>;
  surname: FormControl<string>;
  email: FormControl<string>;
  agreement: FormControl<boolean>;
}

@Injectable()
export class JoinMMProgramFormBuilder {
  constructor(private readonly formBuilder: FormBuilder) {}

  build({ name, familyName, email }: Identity, { companyName }: PartnerResponse): FormGroup<JoinMMProgramForm> {
    return this.formBuilder.group<JoinMMProgramForm>({
      companyName: new FormControl({ value: companyName, disabled: true }, { nonNullable: true }),
      firstName: new FormControl({ value: name, disabled: true }, { nonNullable: true }),
      surname: new FormControl({ value: familyName, disabled: true }, { nonNullable: true }),
      email: new FormControl(
        { value: email, disabled: true },
        {
          nonNullable: true,
        },
      ),
      agreement: new FormControl(false, {
        nonNullable: true,
        validators: [validators.confirmTermsAndConditions()],
      }),
    });
  }
}
