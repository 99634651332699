import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './roles.actions';
import { UserRole, UserWithAdminRole } from './roles.model';

export interface State extends EntityState<UserRole> {
  isLoading: boolean;
  admins: UserWithAdminRole[];
}

export const adapter: EntityAdapter<UserRole> = createEntityAdapter<UserRole>();

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  admins: [],
});

export const rolesReducer = createReducer(
  initialState,
  on(fromActions.getUserRoles, state => ({ ...state, isLoading: true })),
  on(fromActions.getAuthenticatedUserRoles, state => ({ ...state, isLoading: true })),
  on(fromActions.getUserRolesComplete, (state, { userRoles }) => ({ ...adapter.setMany(userRoles, { ...state, isLoading: false }) })),
  on(fromActions.getUserRolesError, state => ({ ...state, isLoading: false })),

  on(fromActions.updateUserRoles, state => ({ ...state, isLoading: true })),
  on(fromActions.updateUserRolesComplete, (state, { updatedUserRoles }) => ({
    ...adapter.updateMany(updatedUserRoles, { ...state, isLoading: false }),
  })),
  on(fromActions.updateUserRolesError, state => ({ ...state, isLoading: false })),

  on(fromActions.getUserAdmins, state => ({ ...state, isLoading: true })),
  on(fromActions.getUserAdminsComplete, (state, { admins }) => ({ ...state, admins, isLoading: false })),
  on(fromActions.getUserAdminsError, state => ({ ...state, admins: [], isLoading: false })),
);
