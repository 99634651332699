import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import * as fromActions from './environments.actions';
import { EnvironmentsService } from './environments.service';

@Injectable()
export class EnvironmentsEffects {
  onGetEnvironments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllEnvironments),
      switchMap(({ coreCustomerId }) =>
        this.environmentsService.getAll(coreCustomerId).pipe(
          map(environments => fromActions.getAllEnvironmentsComplete({ environments })),
          catchError(err => of(fromActions.getAllEnvironmentsError({ err }))),
        ),
      ),
    ),
  );

  onSelectEnvironment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectEnvironment),
      switchMap(({ environmentId }) =>
        this.environmentsService.getOne(environmentId).pipe(
          map(environment => fromActions.selectEnvironmentComplete({ environment })),
          catchError(err => of(fromActions.selectEnvironmentError({ err }))),
        ),
      ),
    ),
  );

  onGoToList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToList),
      tap(() => this.router.navigate(['environments'])),
      map(() => fromActions.resetState({ selectedEnvironment: null })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly environmentsService: EnvironmentsService,
    private readonly router: Router,
  ) {}
}
