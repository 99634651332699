<div class="greeting">
  <h1>Welcome to Companial Partner Portal</h1>
  <h2>Thank you for being part of the world’s largest Dynamics Community.</h2>
  <h4>
    Our mission is to empower every Dynamics partner to exceed expectations. You and your customers are at the center of our people, we are
    here to help you to improve your operation, your commercial success, and your effectiveness in digitalizing the business of your
    customers. One step on our journey together is to support you with the information and tools you need for your day2day business.
  </h4>
</div>
<div class="services">
  <h2>One Portal for all Companial services:</h2>
  <ul>
    <li>
      <h4>Access technical <strong>Project services</strong>, such as Upgrade and Development services.</h4>
    </li>

    <li>
      <h4>Order and manage <strong>Self provisioning</strong> service for hosting BC/NAV on Azure.<br /></h4>
    </li>

    <h4>
      You have no access to our Project services and Self provisioning offering yet? Please write to our
      <a href="mailto:loginsupport@companial.com">Partner Portal support</a>.
    </h4>

    <li>
      <h4>
        As membership partner you can access exclusive content in the <strong>Document library</strong>, participate in
        <strong>Microsoft Migration and Modernization program</strong> and reach out to our <strong>Partner Support</strong> team that helps
        you on any licensing, operational or order query.<br />
      </h4>
    </li>
    <h4>
      Not a membership partner yet? Here you can find further information about the Companial membership offering.
      <a href="https://companial.com/why-a-membership-pays-off">Why a Membership pays-off – Companial</a>.
    </h4>
    <h4>
      Have membership but not participating in Microsoft Migration and Modernization program? Join
      <a href="/join-mm-program">Here</a>.
    </h4>

    <li>
      <h4>Develop sales, consulting, or technical competencies with the <a href="https://companial.com/academy">Companial Academy</a>.</h4>
    </li>
  </ul>
  <h4>
    If you would like to know more, need assistance, or have any other questions, please contact your local
    <a href="https://companial.com/contact-us/">Companial representative</a> or get in touch with our
    <a href="mailto:loginsupport@companial.com">Partner Portal support</a>.
  </h4>
</div>
