import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class CustomPathsActivator implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const serviceRegexp = new RegExp('^\\/project\\/(.+)\\/service(\\/)?$');
    if (serviceRegexp.test(state.url)) {
      const projectId = serviceRegexp.exec(state.url)![1];
      this.router.navigate(['/project', projectId]);
    }

    return true;
  }
}
