import { ENDPOINTS } from '@1clickfactory/data-access/backend';
import { HighlanderErrorCode } from '@1clickfactory/data-access/highlander-error-code';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DownloadRequestOptions } from '@1clickfactory/backend/services/request-options';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { RequestOptions, RequestParams } from '../backend';
import { HighlanderCoreErrorResponse, HighlanderEndpoint, HighlanderResponse } from './highlander.types';

/**
 * @deprecated This service is only used for endpoints that are not yet migrated to BFF.
 * Do not implement new functionality here, implement it in HighlanderService instead.
 */
@Injectable()
export class OldHighlanderService {
  constructor(private client: HttpClient) {}

  getAll<TResponse, TRequestParams = {}>(endpoint: HighlanderEndpoint, path?: string, params?: TRequestParams): Observable<TResponse[]> {
    return this.client.get<HighlanderResponse<TResponse[]>>(this.getUrl(endpoint, path), this.getOptions(params)).pipe(
      map(data => this.processResponse<TResponse[]>(data)),
      catchError((error: HighlanderCoreErrorResponse) => throwError(error)),
    );
  }

  get<TResponse, TRequestParams>(endpoint: HighlanderEndpoint, path?: string, params?: TRequestParams): Observable<TResponse> {
    return this.client.get<HighlanderResponse<TResponse>>(this.getUrl(endpoint, path), this.getOptions(params)).pipe(
      map(data => this.processResponse<TResponse>(data)),
      // Here it might not be HttpErrorResponse - backend needs to confirm if they send such structure
      // because in the method above on fail scenario backend returns: HighlanderCoreErrorResponse
      catchError((error: HighlanderCoreErrorResponse) => throwError(error)),
    );
  }

  download(url: string): Observable<{ blob: Blob; fileName: string }> {
    const options: DownloadRequestOptions = {
      headers: { 'Content-Type': 'application/octet-stream' },
      observe: 'response',
      responseType: 'blob',
      params: {},
    };
    return this.client.request('GET', `${environment.highlanderBffUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => ({
        blob: res.body,
        fileName: this.extractFileName(res.headers.get('content-disposition') ?? ''),
      })),
      catchError(({ error }: HttpErrorResponse) => throwError(error)),
    );
  }

  post<TRequest, TResponse>(
    endpoint: HighlanderEndpoint,
    model: TRequest,
    path?: string,
    params: RequestParams = {},
  ): Observable<TResponse> {
    return this.client.post<HighlanderResponse<TResponse>>(this.getUrl(endpoint, path), model, this.getOptions(params)).pipe(
      map(data => this.processResponse<TResponse>(data)),
      catchError(({ error }: HttpErrorResponse) => throwError(error)),
    );
  }

  put<TRequest, TResponse>(
    endpoint: HighlanderEndpoint,
    model: TRequest,
    path?: string,
    params: RequestParams = {},
  ): Observable<TResponse> {
    return this.client.put<HighlanderResponse<TResponse>>(this.getUrl(endpoint, path), model, this.getOptions(params)).pipe(
      map(data => this.processResponse<TResponse>(data)),
      catchError(({ error }: HttpErrorResponse) => throwError(error)),
    );
  }

  patch<TRequest, TResponse>(
    endpoint: HighlanderEndpoint,
    model: TRequest,
    path?: string,
    params: RequestParams = {},
  ): Observable<TResponse> {
    return this.client.patch<HighlanderResponse<TResponse>>(this.getUrl(endpoint, path), model, this.getOptions(params)).pipe(
      map(data => this.processResponse<TResponse>(data)),
      catchError(({ error }: HttpErrorResponse) => throwError(error)),
    );
  }

  delete(endpoint: HighlanderEndpoint, path?: string, params?: RequestParams): Observable<void> {
    return this.client.delete<HighlanderResponse<void>>(this.getUrl(endpoint, path), this.getOptions(params)).pipe(
      map(() => {}),
      catchError(({ error }: HttpErrorResponse) => throwError(error)),
    );
  }

  protected getUrl(endpoint: HighlanderEndpoint, path: string | null = null): string {
    // When using openId Connect - call resource server directly
    return path === null
      ? `${environment.highlanderBffUrl}/gateway/highlander/${ENDPOINTS[endpoint]}`
      : `${environment.highlanderBffUrl}/gateway/highlander/${ENDPOINTS[endpoint]}/${path}`;
  }

  protected getOptions<TRequestParams>(params: TRequestParams): RequestOptions {
    return {
      headers: {
        'Content-Type': 'application/json',
      },
      params: params || {},
    };
  }

  protected processResponse<TResponse>(response: HighlanderResponse<TResponse>, throwIfNull: boolean = false): TResponse {
    if (response === null) {
      if (throwIfNull) {
        throw Error('Null response');
      } else {
        return response;
      }
    }

    if (response['hasError'] !== undefined) {
      if (response.hasError || (response.statusCode !== HighlanderErrorCode.Ok && response.statusCode !== HighlanderErrorCode.Created)) {
        throw Error(`${response.statusCode}:::${response.message}`);
      }
      return response.data as TResponse;
    } else {
      if (response['errorCode']) {
        throw Error(`${response['errorCode']}:::${response['errorDescription']}`);
      }

      return response as unknown as TResponse;
    }
  }

  private extractFileName(contentDisposition: string): string {
    // Regular expression to match the filename value
    const filenameRegex = /filename\*?=([^;]+)/i;

    // Match the first occurrence of filename or filename*
    const match = contentDisposition.match(filenameRegex);

    if (match && match[1]) {
      // Remove any leading 'UTF-8'' if present
      let fileName = match[1].trim().replace(/UTF-8''/i, '');

      // Decode any percent-encoded characters
      fileName = decodeURIComponent(fileName);

      return fileName;
    }

    // Return null if no filename is found
    return 'UnknownFile';
  }
}
