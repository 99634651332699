import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// noinspection ES6PreferShortImport
import { MaterialDesignModule } from '../common/material-design.module';
// noinspection ES6PreferShortImport
import { OCFCommonModule } from '../common/common.module';
import { OcfNoticesModule } from '../common/notices';

import { AlertComponent } from './components/alert/alert.component';
import { OcfAlertModule } from './components/alert/alert.module';
import { OcfFormButtonRowModule } from './components/form-button-row/ocf-form-button-row.module';
import { LoaderIndicatorComponent } from './components/loader-indicator/loader-indicator';
import { PageWizardStepComponent } from './components/page-wizard/page-wizard-step.component';
import { PageWizardComponent } from './components/page-wizard/page-wizard.component';

@NgModule({
  declarations: [LoaderIndicatorComponent, PageWizardComponent, PageWizardStepComponent],
  exports: [
    OcfFormButtonRowModule,
    AlertComponent,
    LoaderIndicatorComponent,

    PageWizardComponent,
    PageWizardStepComponent,
    OcfNoticesModule,
  ],
  imports: [
    CommonModule,
    MaterialDesignModule,
    RouterModule,
    // Needed because of app-translate. Refactor-out whenever possible
    OCFCommonModule,
    OcfAlertModule,
    OcfNoticesModule,
  ],
})
export class OCFSimpleUIModule {}
