import { createAction, props } from '@ngrx/store'
// noinspection ES6PreferShortImport
import { CartItem } from './cart-item.model'

const NAMESPACE = '[CartItems]'

export const query = createAction(`${NAMESPACE} Query`)
export const queryError = createAction(`${NAMESPACE} QueryError`)
export const queryComplete = createAction(`${NAMESPACE} QueryComplete`, props<{ cartItems: CartItem[] }>())

export const addToCart = createAction(`${NAMESPACE} AddToCart`, props<{ cartItem: CartItem }>())
export const addToCartError = createAction(`${NAMESPACE} AddToCartError`, props<{ cartItem: CartItem }>())
export const addToCartComplete = createAction(`${NAMESPACE} AddToCartComplete`, props<{ cartItem: CartItem }>())

export const addToCartBulk = createAction(`${NAMESPACE} AddToCartBulk`, props<{ cartItems: CartItem[] }>())
export const addToCartBulkError = createAction(`${NAMESPACE} AddToCartBulkError`)
export const addToCartBulkComplete = createAction(`${NAMESPACE} AddToCartBulkComplete`, props<{ cartItems: CartItem[] }>())

export const removeFromCart = createAction(`${NAMESPACE} RemoveFromCart`, props<{ id: string }>())
export const removeFromCartError = createAction(`${NAMESPACE} RemoveFromCartError`)
export const removeFromCartComplete = createAction(`${NAMESPACE} RemoveFromCartComplete`)

export const goToCheckoutPage = createAction(`${NAMESPACE} GoToCheckoutPage`)
export const goToCartItemPage = createAction(`${NAMESPACE} GoToCartItemPage`, props<{ cartItem: CartItem }>())

export const goToCartPage = createAction(`${NAMESPACE} GoToCartPage`)
