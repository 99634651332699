import { HighlanderErrorCode } from '@1clickfactory/data-access/highlander-error-code';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DownloadRequestOptions } from '@1clickfactory/backend/services/request-options';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { RequestOptions } from '../backend';
import { HighlanderResponse } from './highlander.types';

@Injectable()
export class HighlanderService {
  constructor(private client: HttpClient) {}

  getAll<TResponse>(path: string, options?: RequestOptions): Observable<TResponse[]> {
    return this.client.get<HighlanderResponse<TResponse[]>>(this.getUrl(path), this.getOptions(options)).pipe(
      map(data => this.processResponse<TResponse[]>(data)),
      catchError((error: HttpErrorResponse) => throwError(error)),
    );
  }

  get<TResponse>(path: string, options?: RequestOptions): Observable<TResponse> {
    return this.client.get<HighlanderResponse<TResponse>>(this.getUrl(path), this.getOptions(options)).pipe(
      map(data => this.processResponse<TResponse>(data)),
      // Here it might not be HttpErrorResponse - backend needs to confirm if they send such structure
      // because in the method above on fail scenario backend returns: HighlanderCoreErrorResponse
      catchError((error: HttpErrorResponse) => throwError(error)),
    );
  }

  download<TResponse>(path: string, options?: DownloadRequestOptions): Observable<TResponse> {
    const downloadOptions: DownloadRequestOptions = {
      headers: { 'Content-Type': 'application/octet-stream', ...options?.headers },
      observe: 'response',
      responseType: 'blob',
      params: {},
    };
    return this.client.request('GET', this.getUrl(path), downloadOptions).pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  post<TRequest, TResponse>(path: string, model?: TRequest, options?: RequestOptions): Observable<TResponse> {
    return this.client.post<HighlanderResponse<TResponse>>(this.getUrl(path), model, this.getOptions(options)).pipe(
      map(data => this.processResponse<TResponse>(data)),
      catchError((error: HttpErrorResponse) => throwError(error)),
    );
  }

  put<TRequest, TResponse>(path: string, model: TRequest, options?: RequestOptions): Observable<TResponse> {
    return this.client.put<HighlanderResponse<TResponse>>(this.getUrl(path), model, this.getOptions(options)).pipe(
      map(data => this.processResponse<TResponse>(data)),
      catchError((error: HttpErrorResponse) => throwError(error)),
    );
  }

  patch<TRequest, TResponse>(path: string, model: TRequest, options?: RequestOptions): Observable<TResponse> {
    return this.client.patch<HighlanderResponse<TResponse>>(this.getUrl(path), model, this.getOptions(options)).pipe(
      map(data => this.processResponse<TResponse>(data)),
      catchError((error: HttpErrorResponse) => throwError(error)),
    );
  }

  delete(path: string, options?: RequestOptions): Observable<void> {
    return this.client.delete<HighlanderResponse<void>>(this.getUrl(path), this.getOptions(options)).pipe(
      map(() => {}),
      catchError((error: HttpErrorResponse) => throwError(error)),
    );
  }

  protected getUrl(path: string): string {
    return `${environment.highlanderBffUrl}/${path}`;
  }

  protected getOptions(options?: RequestOptions): RequestOptions {
    return {
      headers: {
        'Content-Type': 'application/json',
        ...options?.headers,
      },
      params: options?.params || {},
    };
  }

  protected processResponse<TResponse>(response: HighlanderResponse<TResponse>, throwIfNull: boolean = false): TResponse {
    if (response === null) {
      if (throwIfNull) {
        throw Error('Null response');
      } else {
        return response;
      }
    }

    if (response['hasError'] !== undefined) {
      if (response.hasError || (response.statusCode !== HighlanderErrorCode.Ok && response.statusCode !== HighlanderErrorCode.Created)) {
        throw Error(`${response.statusCode}:::${response.message}`);
      }
      return response.data as TResponse;
    } else {
      if (response['errorCode']) {
        throw Error(`${response['errorCode']}:::${response['errorDescription']}`);
      }

      return response as unknown as TResponse;
    }
  }
}
