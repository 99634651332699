const baseUrl = `${window.location.protocol}//${window.location.host}`;

export const baseEnvironment = {
  baseUrl,
  appInsightsInstrumentationKey: null as unknown as string,
  askookUrl: '/1clickfactory',
  debugMode: false,
  highlanderBffUrl: '/api/v2',
  humanCheckKey: '6LeMEMckAAAAAG4id-RVSx_rXDOhX0h-yxjqopA1',
  production: true,
  showMissingTranslations: false,
  useAppInsights: false,
};
