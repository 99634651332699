import { Action, ActionReducer, ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromFormReducer from './form/form.reducer';
import { State } from './form/form.reducer';

export interface SimpleFormsState {
  forms: fromFormReducer.State;
}

export interface SimpleFormsModuleState {
  simpleForms: SimpleFormsState;
}

export const reducers: ActionReducerMap<SimpleFormsState> = {
  forms: <ActionReducer<State, Action>>fromFormReducer.reducer,
};

export const getFeatureStore = createFeatureSelector<SimpleFormsState>('forms');
