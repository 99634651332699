import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { NewUserRole, UserRole, UserWithAdminRole } from './roles.model';

const NAMESPACE = '[Authorization_Roles]';

export const getUserRoles = createAction(`${NAMESPACE} GetUserRoles`, props<{ userId: string; partnerId: string }>());
export const getAuthenticatedUserRoles = createAction(`${NAMESPACE} GetAuthenticatedUserRoles`);
export const getUserRolesComplete = createAction(`${NAMESPACE} GetUserRolesComplete`, props<{ userRoles: UserRole[] }>());
export const getUserRolesError = createAction(`${NAMESPACE} GetUserRolesError`, props<{ error: HighlanderCoreErrorResponse }>());

export const updateUserRoles = createAction(
  `${NAMESPACE} UpdateUserRoles`,
  props<{ newUserRoles: NewUserRole[]; userId: string; partnerId: string }>(),
);
export const updateUserRolesComplete = createAction(
  `${NAMESPACE} UpdateUserRolesComplete`,
  props<{ updatedUserRoles: Update<UserRole>[] }>(),
);
export const updateUserRolesError = createAction(`${NAMESPACE} UpdateUserRolesError`, props<{ error: HighlanderCoreErrorResponse }>());

export const getUserAdmins = createAction(`${NAMESPACE} getUserAdmins`);
export const getUserAdminsComplete = createAction(`${NAMESPACE} getUserAdminsComplete`, props<{ admins: UserWithAdminRole[] }>());
export const getUserAdminsError = createAction(`${NAMESPACE} getUserAdminsError`, props<{ error: HighlanderCoreErrorResponse }>());
