import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { announcementsActions, announcementsSelectors, identitySelectors } from '@appState';
import { Store, select } from '@ngrx/store';
import { Observable, filter, map, switchMap } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';

@Injectable()
export class DashboardResolver implements Resolve<Observable<void>> {
  private wasFetchInitiated = false;
  constructor(private readonly store: Store<AppState>) {}

  resolve(): Observable<void> {
    return this.store.pipe(
      select(identitySelectors.selectUserPermissionName),
      filter(permNames => permNames.length !== 0),
      switchMap(permNames =>
        this.store.pipe(
          select(announcementsSelectors.selectAnnouncements),
          map(ann => {
            if (permNames.includes('Portal.Business.Announcement.Read') && !ann.length && !this.wasFetchInitiated) {
              this.store.dispatch(announcementsActions.get());
              this.wasFetchInitiated = true;
            }

            return undefined;
          }),
        ),
      ),
    );
  }
}
