import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { DownloadAttachment, IAttachment } from '@appState';
import { createAction, props } from '@ngrx/store';
import { AttachmentURL, UploadedAttachment } from 'cui-components';
import { ICategory, ISeverity, IType } from './support-setup.model';
import { CreateNoteRequest, CreateTicketRequest, Note, Ticket, TicketFilterStatus, TicketListItem } from './support.model';

const NAMESPACE = '[New Support]';

export const getTickets = createAction(`${NAMESPACE} GetTickets`);
export const getTicketsComplete = createAction(`${NAMESPACE} GetTicketsComplete`, props<{ tickets: TicketListItem[] }>());
export const getTicketsError = createAction(`${NAMESPACE} GetTicketsError`, props<{ error: HighlanderCoreErrorResponse }>());

export const getTicket = createAction(`${NAMESPACE} GetTicket`, props<{ id: string }>());
export const getTicketComplete = createAction(`${NAMESPACE} GetTicketComplete`, props<{ ticket: Ticket }>());
export const getTicketError = createAction(`${NAMESPACE} GetTicketError`, props<{ error: HighlanderCoreErrorResponse }>());

export const createNewTicket = createAction(`${NAMESPACE} CreateNewTicket`, props<{ newTicket: CreateTicketRequest }>());
export const createNewTicketComplete = createAction(`${NAMESPACE} CreateNewTicketComplete`, props<{ ticket: TicketListItem }>());
export const createNewTicketError = createAction(`${NAMESPACE} CreateNewTicketError`, props<{ error: HighlanderCoreErrorResponse }>());

export const getNote = createAction(`${NAMESPACE} GetNote`, props<{ id: string }>());
export const getNoteComplete = createAction(`${NAMESPACE} GetNoteComplete`, props<{ note: Note }>());
export const getNoteError = createAction(`${NAMESPACE} GetNoteError`, props<{ error: HighlanderCoreErrorResponse }>());

export const addNote = createAction(`${NAMESPACE} AddNote`, props<{ id: string; newNote: CreateNoteRequest }>());
export const addNoteComplete = createAction(`${NAMESPACE} AddNoteComplete`, props<{ id: string; newNote: Note }>());
export const addNoteError = createAction(`${NAMESPACE} AddNoteError`, props<{ error: HighlanderCoreErrorResponse }>());

export const filterByTicketStatus = createAction(`${NAMESPACE} FilterByTicketStatus`, props<{ status: TicketFilterStatus }>());

export const goToSupportPage = createAction(`${NAMESPACE} GoToSupportPage`);
export const goToUpdateTicketPage = createAction(`${NAMESPACE} GoToUpdateTicketPage`, props<{ id: string }>());

export const resolveCase = createAction(`${NAMESPACE} ResolveCase`, props<{ id: string }>());
export const resolveCaseError = createAction(`${NAMESPACE} ResolveCaseError`, props<{ error: HighlanderCoreErrorResponse }>());

export const getSupportSetup = createAction(`${NAMESPACE} GetSupportSetup`);
export const getSupportSetupComplete = createAction(
  `${NAMESPACE} GetSupportSetupComplete`,
  props<{ types: IType[]; categories: ICategory[]; severities: ISeverity[] }>(),
);
export const getSupportSetupError = createAction(`${NAMESPACE} GetSupportSetupError`);

export const getAttachmentsUploadUrls = createAction(`${NAMESPACE} GetAttachmentUploadUrls`, props<{ amountOfAttachments: number }>());
export const getAttachmentsUploadUrlsComplete = createAction(
  `${NAMESPACE} GetAttachmentUploadUrlsComplete`,
  props<{ attachmentsURL: AttachmentURL[] }>(),
);
export const getAttachmentsUploadUrlsError = createAction(
  `${NAMESPACE} GetAttachmentUploadUrlsError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const uploadAttachments = createAction(`${NAMESPACE} UploadAttachments`, props<{ attachments: UploadedAttachment[] }>());
export const clearAttachments = createAction(`${NAMESPACE} ClearAttachments`);

export const downloadAttachment = createAction(`${NAMESPACE} DownloadAttachment`, props<{ attachment: IAttachment }>());
export const downloadAttachmentComplete = createAction(
  `${NAMESPACE} DownloadAttachmentComplete`,
  props<{ attachment: DownloadAttachment }>(),
);
export const downloadAttachmentError = createAction(`${NAMESPACE} DownloadAttachmentError`);
