// noinspection JSUnusedGlobalSymbols

export enum HighlanderErrorCode {
  Unknown = 0,
  FailingValidation = 1,
  InvalidCredentials = 2,
  InvalidJwt = 3,
  LockedOut = 4,
  EmailNotVerified = 5,
  Ok = 200,
  Created = 201,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
}
