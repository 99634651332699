import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Router]';

export const goToReturnUrlOrUrl = createAction(`${NAMESPACE} GoToReturnUrlOrUrl`, props<{ url: string }>());
export const goTo = createAction(`${NAMESPACE} GoTo`, props<{ url: string }>());
export const goToDashboard = createAction(`${NAMESPACE} GoToDashboard`);
export const goToReturnUrlOrHome = createAction(`${NAMESPACE} GoToDashboardOrReturnUrl`);
export const reload = createAction(`${NAMESPACE} Reload`);
export const goToForbiddenError = createAction(`${NAMESPACE} GoToForbiddenError`);
export const goToSignOut = createAction(`${NAMESPACE} GoToSignOut`);
