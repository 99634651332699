import { NotificationMessage, showNotification } from '@1clickfactory/notifications';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CartBillingInformation,
  CartItem,
  CartLineValidation,
  CartPaymentRequest,
  PaymentMethod,
  cartItemsSelectors,
  paymentInformationSelectors,
} from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity/src/models';
import { Store, select } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AppState } from '../../app.store';
import * as fromActions from './cart-payment.actions';
import { CartPaymentService } from './cart-payment.service';

@Injectable()
export class CartPaymentEffects {
  onPayForCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.payForCart),
      switchMap(() =>
        combineLatest([
          this.store.pipe(select(cartItemsSelectors.selectCartItems)),
          this.store.pipe(select(paymentInformationSelectors.selectCartTotalAfterFees)),
          this.store.pipe(select(paymentInformationSelectors.selectPaymentMethod)),
          this.store.pipe(select(paymentInformationSelectors.selectCartBillingInformation)),
        ]).pipe(
          filter(([cartItems, _, paymentMethod, cartBillingInformation]) => !!cartItems && !!paymentMethod && !!cartBillingInformation),
          take(1),
        ),
      ),
      map(([cartItems, cartTotalAfterFees, paymentMethod, cartBillingInformation]) => ({
        cartItems,
        cartPaymentRequest: CartPaymentEffects.mapToCartPaymentRequest(paymentMethod, cartBillingInformation, cartTotalAfterFees),
      })),
      switchMap(({ cartItems, cartPaymentRequest }) =>
        this.cartPaymentService.post(cartPaymentRequest).pipe(
          map(paymentResponse =>
            paymentResponse.hasErrors
              ? fromActions.payForCartUnsuccessful({
                  cartPaymentError: paymentResponse.generalError,
                  faultyCartItems: CartPaymentEffects.mapErrorsToCartItems(cartItems, paymentResponse.cartLineValidations ?? []),
                })
              : fromActions.payForCartComplete(),
          ),
          catchError(() => of(fromActions.payForCartError())),
        ),
      ),
    ),
  );

  onPayForCartComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.payForCartComplete),
        switchMap(() => this.router.navigate(['/', 'cart', 'confirmation'])),
      ),
    { dispatch: false },
  );

  onPayForCartError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.payForCartError),
      map(() => showNotification({ message: NotificationMessage.GeneralError })),
    ),
  );

  private static mapToCartPaymentRequest(
    paymentMethod: PaymentMethod,
    billingInformation: CartBillingInformation,
    cartTotalAfterFees: number,
  ): CartPaymentRequest {
    return {
      paymentMethod: {
        paymentType: paymentMethod.paymentType,
        braintreeNonce: paymentMethod.braintreeNonce!,
        // Using `toFixed(2)` to avoid scenarios like: 520.3000000000001
        cartTotal: +cartTotalAfterFees.toFixed(2),
      },
      billingInformation,
    };
  }

  private static mapErrorsToCartItems(cartItems: CartItem[], itemValidations: CartLineValidation[]): Update<CartItem>[] {
    const faultyCartItems: Update<CartItem>[] = [];

    for (const itemValidation of itemValidations) {
      const cartItem = cartItems.find(x => x.id && itemValidation.uid && x.id.toLowerCase() === itemValidation.uid.toLowerCase())!;
      faultyCartItems.push({ id: cartItem.id, changes: { error: itemValidation.error } });
    }

    return faultyCartItems;
  }

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private cartPaymentService: CartPaymentService,
  ) {}
}
