import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AttachmentURL, UploadedAttachment } from 'cui-components';
import { ICategory, ISeverity, IType } from './support-setup.model';
import * as fromActions from './support.actions';
import { Note, Ticket, TicketFilterStatus, TicketListItem } from './support.model';

export interface State extends EntityState<TicketListItem | Ticket> {
  isLoading: boolean;
  isNoteLoading: boolean;
  ticket: Ticket | null;
  tickets: TicketListItem[];
  filterByTicketStatus: TicketFilterStatus;
  notes: Note[];
  note: Note | null;
  attachmentsURL: AttachmentURL[];
  attachments: UploadedAttachment[];
  supportSetup: {
    isLoading: boolean;
    categories: ICategory[];
    severities: ISeverity[];
    types: IType[];
  };
}

export const adapter: EntityAdapter<TicketListItem | Ticket> = createEntityAdapter<TicketListItem | Ticket>({
  selectId: (entity: TicketListItem | Ticket) => entity.id.toString().toLocaleLowerCase(),
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isNoteLoading: false,
  ticket: null,
  tickets: [],
  filterByTicketStatus: JSON.parse(localStorage.getItem('TABLE_FILTER_SETTINGS')!)?.toggleSearch ?? 'All',
  notes: [],
  note: null,
  attachmentsURL: [],
  attachments: [],
  supportSetup: {
    isLoading: false,
    categories: [],
    severities: [],
    types: [],
  },
});

export const supportReducer = createReducer(
  initialState,
  on(fromActions.getTickets, state => ({ ...state, isLoading: true })),
  on(fromActions.getTicketsComplete, (state, { tickets }) => ({ ...state, tickets: tickets, isLoading: false })),
  on(fromActions.getTicketsError, state => ({ ...state, tickets: [], isLoading: false })),

  on(fromActions.getTicket, state => ({ ...state, isLoading: true })),
  on(fromActions.getTicketComplete, (state, { ticket }) => ({
    ...state,
    ticket,
    isLoading: false,
  })),
  on(fromActions.getTicketError, state => ({ ...state, ticket: null, isLoading: false })),

  on(fromActions.createNewTicket, state => ({ ...state, isLoading: true })),
  on(fromActions.createNewTicketComplete, (state, { ticket }) => ({
    ...state,
    tickets: [ticket, ...state.tickets],
    isLoading: false,
  })),
  on(fromActions.createNewTicketError, state => ({ ...state, isLoading: false })),

  on(fromActions.getNote, state => ({ ...state, isNoteLoading: true })),
  on(fromActions.getNoteComplete, (state, { note }) => ({
    ...state,
    notes: [...state.notes, note],
    note,
    isNoteLoading: false,
  })),
  on(fromActions.getNoteError, state => ({ ...state, notes: [], isNoteLoading: false })),

  on(fromActions.addNote, (state, { newNote }) => {
    return {
      ...state,
      isNoteLoading: newNote.attachments?.length ? false : true,
      isLoading: newNote.attachments?.length ? true : false,
    };
  }),
  on(fromActions.addNoteComplete, (state, { newNote }) => ({
    ...state,
    ticket: { ...state.ticket!, notes: [newNote, ...state.ticket!.notes] },
    isNoteLoading: false,
  })),
  on(fromActions.addNoteError, state => ({ ...state, isNoteLoading: false })),

  on(fromActions.filterByTicketStatus, (state, { status }) => ({ ...state, filterByTicketStatus: status })),

  on(fromActions.resolveCase, state => ({ ...state, isLoading: true })),
  on(fromActions.resolveCaseError, state => ({ ...state, isLoading: false })),

  on(fromActions.getSupportSetup, state => ({ ...state, supportSetup: { ...state.supportSetup, isLoading: true } })),
  on(fromActions.getSupportSetupComplete, (state, { types, categories, severities }) => ({
    ...state,
    supportSetup: { types, categories, severities, isLoading: false },
  })),
  on(fromActions.getSupportSetupError, state => ({ ...state, supportSetup: { ...state.supportSetup, isLoading: false } })),

  on(fromActions.getAttachmentsUploadUrlsComplete, (state, { attachmentsURL }) => ({ ...state, attachmentsURL })),
  on(fromActions.uploadAttachments, (state, { attachments }) => ({ ...state, attachments })),
  on(fromActions.clearAttachments, state => ({
    ...state,
    attachmentsURL: [],
    attachments: [],
  })),
);
