import { CartBillingInformation } from './cart-billing-information.model'

export interface PaymentMethodRequest {
  paymentType: PaymentTypes
  braintreeNonce: string
  cartTotal: number
}

export interface PaymentMethod {
  paymentType: PaymentTypes
  braintreeNonce?: string
  maskedCreditCardNumber?: string
}

export interface PaymentInformation {
  cartBillingInformation: CartBillingInformation
  paymentMethod: PaymentMethod
}

export enum PaymentTypes {
  Free = 0,
  CreditCard = 1,
  Invoice = 2,
}
