import { ServiceBase } from '@1clickfactory/backend';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backend } from '@env/backend';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Partner } from './partner.model';

@Injectable()
export class PartnerService extends ServiceBase<Partner> {
  constructor(httpClient: HttpClient) {
    super(`${environment.highlanderBffUrl}/gateway/askook/${backend.Users.Partners}`, httpClient);
  }

  getAll(): Observable<Partner[]> {
    return super.getAll();
  }
}
