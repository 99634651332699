import { createReducer, on } from '@ngrx/store';
import * as fromActions from './role-groups.actions';
import { GroupedRole } from '../roles/roles.model';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export interface State extends EntityState<GroupedRole> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<GroupedRole> = createEntityAdapter<GroupedRole>();

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const roleGroupsReducer = createReducer(
  initialState,
  on(fromActions.getUserGroupedRoles, state => ({ ...state, isLoading: true })),
  on(fromActions.getAuthenticatedUserGroupedRoles, state => ({ ...state, isLoading: true })),
  on(fromActions.getUserGroupedRolesComplete, (state, { groupedRoles }) => ({
    ...adapter.setMany(groupedRoles, { ...state, isLoading: false }),
  })),
  on(fromActions.getUserGroupedRolesError, state => ({ ...state, isLoading: false })),
);
