import { manageCurrentAddressSessionStorage } from '@1clickfactory/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CookieAuth } from '@highlander/common/helpers/authentication';
import { Store } from '@ngrx/store';
import { isNotNullOrUndefined } from 'cui-components';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { agreementsActions, agreementsSelectors } from '../..';
import { AppState } from '../../app.store';
import * as identitySelectors from '../identity.selectors';

@Injectable()
export class PublicAgreementsGuard {
  constructor(private store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { publicLink } = route.params;
    this.store.dispatch(agreementsActions.getPublishedAgreement({ publicLink }));

    return combineLatest([
      this.store.select(agreementsSelectors.selectPublicAgreement),
      this.store.select(identitySelectors.selectIsAuthenticated),
    ]).pipe(
      filter(([agreement]) => isNotNullOrUndefined(agreement)),
      map(([agreement, isAuthenticated]) => {
        if (!agreement) {
          return false;
        }

        manageCurrentAddressSessionStorage(`agreements/${publicLink}`);

        if (!agreement.publiclyAvailable && !isAuthenticated) {
          CookieAuth.login();
          return false;
        }

        if (agreement.publiclyAvailable && !!agreement.publicLink) {
          return true;
        }

        if (agreement.publiclyAvailable && isAuthenticated) {
          return true;
        }

        return !!agreement.publicLink;
      }),
    );
  }
}
