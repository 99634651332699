import { OldHighlanderService } from '@1clickfactory/data-access';
import { Injectable } from '@angular/core';
import { CartItem } from '@appState';
import { Observable } from 'rxjs';

@Injectable()
export class CartItemsBulkService {
  constructor(private oldHighlanderService: OldHighlanderService) {}

  postAll(cartItems: CartItem[]): Observable<CartItem[]> {
    return this.oldHighlanderService.post<CartItem[], CartItem[]>('cartBulk', cartItems);
  }
}
