import { Observable, Subject, Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';

@Component({ template: '' })
export abstract class SubscribingComponent implements OnDestroy {
  protected destroyed$ = new Subject<void>();
  private subs: Subscription[] = [];

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  subscribe<T>(observable: Observable<T>, onSuccess: (value: T) => void, onFail?: () => void, onCompleted?: () => void): void {
    this.subs.push(observable.subscribe(onSuccess, onFail, onCompleted));
  }

  unsubscribe(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.subs.forEach(s => s.unsubscribe());
  }
}
