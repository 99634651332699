import { createReducer, on } from '@ngrx/store';
import { sortItems } from '../../helpers/misc';
import { Environment, EnvironmentListItem } from './environment.model';
import * as fromActions from './environments.actions';

export type FilterToApply = {
  column: string;
  type: string;
  values: { [key: string]: string | number };
};

export interface State {
  environments: EnvironmentListItem[];
  isLoading: boolean;
  tableFilters: FilterToApply[];

  selectedEnvironment: Environment | null;
  isSelectedEnvironmentLoading: boolean;
}

export const initialState: State = {
  environments: [],
  isLoading: false,
  tableFilters: [],

  selectedEnvironment: null,
  isSelectedEnvironmentLoading: false,
};

export const environmentsReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAllEnvironments, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllEnvironmentsComplete, (state, { environments }) => ({
    ...state,
    isLoading: false,
    environments: sortItems<EnvironmentListItem>(environments, (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.getAllEnvironmentsError, state => ({ ...state, isLoading: false })),

  on(fromActions.goToList, (state, { filters }) => ({
    ...state,
    tableFilters: filters,
  })),

  on(fromActions.selectEnvironment, state => ({ ...state, isSelectedEnvironmentLoading: true })),
  on(fromActions.selectEnvironmentComplete, (state, { environment }) => ({
    ...state,
    isSelectedEnvironmentLoading: false,
    selectedEnvironment: environment,
  })),
  on(fromActions.selectEnvironmentError, state => ({ ...state, isSelectedEnvironmentLoading: false })),
);
