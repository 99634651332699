import { manageCurrentAddressSessionStorage } from '@1clickfactory/common';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { identitySelectors, routerSelectors } from '@appState';
import { CookieAuth } from '@highlander/common/helpers/authentication';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, filter, first, map, switchMap } from 'rxjs';
import { AppState } from '../../app.store';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    return this.store.select(identitySelectors.selectIsLoading).pipe(
      filter(isLoading => !isLoading),
      first(),
      switchMap(() =>
        combineLatest([
          this.store.select(identitySelectors.selectIsAuthenticated),
          this.store.select(routerSelectors.selectCurrentUrl),
        ]).pipe(
          first(),
          map(([isAuthenticated, url]) => {
            console.assert(isAuthenticated, 'Unauthenticated. Access denied');
            manageCurrentAddressSessionStorage(url);

            if (!isAuthenticated) {
              CookieAuth.login();
            }

            return isAuthenticated;
          }),
        ),
      ),
    );
  }
}
