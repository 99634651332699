import { CommonData } from '@appState';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './common-data.action';

export interface State extends EntityState<CommonData> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<CommonData> = createEntityAdapter<CommonData>({
  selectId: (commonData: CommonData) => commonData.id.toLocaleLowerCase(),
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const commonDataReducer = createReducer(
  initialState,
  on(fromActions.getCommonDataList, state => ({ ...state, isLoading: true })),
  on(fromActions.getCommonDataListComplete, (state, { commonDataList }) =>
    adapter.upsertMany(commonDataList, { ...state, isLoading: false }),
  ),
  on(fromActions.getCommonDataListError, state => adapter.removeAll({ ...state, isLoading: false })),

  on(fromActions.getAllCommonData, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllCommonDataComplete, (state, { commonDataList }) =>
    adapter.upsertMany(commonDataList, { ...state, isLoading: false }),
  ),
  on(fromActions.getAllCommonDataError, state => adapter.removeAll({ ...state, isLoading: false })),
);
