import { NotificationMessage, showNotification } from '@1clickfactory/notifications';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import * as accountActions from '../../account/account.actions';
import * as accountSelectors from '../../account/account.selectors';
import { AppState } from '../../app.store';
import * as fromActions from './user-approval.actions';
import { UserApprovalService } from './user-approval.service';

@Injectable()
export class UserApprovalEffects {
  onSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.save),
      switchMap(({ userApproval }) =>
        this.store.pipe(
          select(accountSelectors.selectSelectedAccountId),
          take(1),
          mergeMap(id =>
            this.userApprovalService.post({ ...userApproval, Id: id! }).pipe(
              map(() => (userApproval.IsApproved ? fromActions.approved({ id: id! }) : fromActions.rejected({ id: id! }))),
              catchError(() => of(fromActions.saveError())),
            ),
          ),
        ),
      ),
    ),
  );

  onSaveError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveError),
      map(() => showNotification({ message: NotificationMessage.SaveError })),
    ),
  );

  onApproved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.approved),
      map(({ id }) => accountActions.get({ id })),
    ),
  );

  onRejected$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.rejected),
        switchMap(() => this.router.navigate(['/', 'user'])),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private userApprovalService: UserApprovalService,
  ) {}
}
