import { createSelector } from '@ngrx/store'
import { getRootState } from '../app.store'
import { selectIdentity } from '../identity/identity.selectors'
// noinspection ES6PreferShortImport
import * as fromAccountReducers from './account.reducer'

//#region Module state selectors
const selectModuleState = createSelector(getRootState, p => p?.accounts)
export const { selectEntities } = fromAccountReducers.adapter.getSelectors(selectModuleState)
export const selectSelectedAccountId = createSelector(selectModuleState, s => s && s.SelectedAccountId)
export const selectIsLoaded = createSelector(selectModuleState, s => s?.IsLoaded)
//#endregion
export const selectAccount = (id: string) => createSelector(selectEntities, entities => entities?.[id])

export const selectSelectedAccount = createSelector(
  selectEntities,
  selectSelectedAccountId,
  (entities, id) => entities && id && entities[id],
)
export const areSelectedAccountGroupsUpdated = createSelector(selectSelectedAccount, s => s && s.AreGroupsUpdated)
export const isSelectedAccountBeingProcessed = createSelector(selectSelectedAccount, s => s && s.IsProcessing)
export const isSelectedAccountUnapproved = createSelector(selectSelectedAccount, s => s && !s.IsMapped)

export const selectAccountGroupsThatCurrentUserCanEdit = createSelector(
  selectIdentity,
  selectEntities,
  (identity, accountEntities) => !!identity?.id && accountEntities?.[identity.id]?.Groups?.filter(group => group.IsAssignableByPartner),
)

export const selectSelectedAccountsGroupsThatCanBeEdited = createSelector(
  selectAccountGroupsThatCurrentUserCanEdit,
  selectSelectedAccount,
  (editableGroups, selectedAccount) =>
    editableGroups &&
    selectedAccount &&
    editableGroups.map(group => ({
      ...group,
      IsSelected: selectedAccount.Groups.some(selectedAccGroup => selectedAccGroup.Id === group.Id),
    })),
)
export const selectPortalGroupsThatSelectedUserCanEdit = createSelector(
  selectSelectedAccountsGroupsThatCanBeEdited,
  allGroups => allGroups && allGroups.filter(group => !group.IsSppGroup),
)

export const selectSPPGroupsThatSelectedUserCanEdit = createSelector(
  selectSelectedAccountsGroupsThatCanBeEdited,
  allGroups => allGroups && allGroups.filter(group => group.IsSppGroup),
)

export const selectPortalGroupsThatSelectedUserHas = createSelector(
  selectPortalGroupsThatSelectedUserCanEdit,
  portalGroups => portalGroups && portalGroups.filter(g => g.IsSelected),
)

export const selectSPPGroupsThatSelectedUserHas = createSelector(
  selectSPPGroupsThatSelectedUserCanEdit,
  sppGroups => sppGroups && sppGroups.filter(g => g.IsSelected),
)
