import { createReducer, on } from '@ngrx/store';
import * as actions from './credit.actions';

export interface State {
  isLoading: boolean;
  currencyCode: string | null;
  creditLimit: number | null;
  remainingCreditLimit: number | null;
}

export const initialState: State = {
  isLoading: false,
  currencyCode: null,
  creditLimit: null,
  remainingCreditLimit: null,
};

export const creditReducer = createReducer(
  initialState,
  on(actions.get, state => ({ ...state, isLoading: true })),
  on(actions.getComplete, (state, { credit }) => ({ ...state, ...credit, isLoading: false })),
  on(actions.getError, state => ({ ...state, isLoading: false })),
);
