import { Injectable } from '@angular/core';
import { partnersActions } from '@appState';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app-state/app.store';

@Injectable()
export class JoinMMProgramResolver {
  constructor(private readonly store: Store<AppState>) {}

  resolve(): void {
    this.store.dispatch(partnersActions.getPartner());
  }
}
