import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { NotificationMessage, showNotification } from '@1clickfactory/notifications'
import * as fromActions from './payment-information.action'
import { of } from 'rxjs'
import { UserCompanyService } from './user-company.service'
import { BillingInformation } from './cart-billing-information.model'

@Injectable()
export class PaymentInformationEffects {
  onBraintreeError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.confirmBraintreeCardError),
      map(() => showNotification({ message: NotificationMessage.BraintreeError })),
    ),
  )

  onGetCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCompany),
      mergeMap(() =>
        this.userCompanyService.get().pipe(
          map((company: BillingInformation) => fromActions.getCompanyComplete({ company })),
          catchError(() => of(fromActions.getCompanyError())),
        ),
      ),
    ),
  )

  constructor(private actions$: Actions, private userCompanyService: UserCompanyService) {}
}
