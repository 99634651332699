export enum NotificationMessage {
  GeneralError,
  SubmitError,
  SaveError,
  DownloadError,
  AddToCartError,
  BraintreeError,

  GeneralSuccess,
  SaveSuccess,
  SubmitSuccess,
  DeleteSuccess,
  ResetTwoFaSuccess,
  AddToCartSuccess,
  CopyExternalLinkSuccess,

  SubscriptionToggleAutoRenew,
  SubscriptionScheduleQuantityChange,
}
