import { HighlanderEntity } from '@1clickfactory/entities'

export interface CartItem extends HighlanderEntity {
  externalId: string
  serviceId?: string
  projectId?: string
  customerId?: string
  error?: string
  title: string
  subtitle?: string
  customerName?: string
  itemType: CartItemType
  price: number
  quantity?: number
  stock?: number
  minStock?: number
  currency?: string
  canPayByInvoice?: boolean
  canChangeQuantity?: boolean
}

export enum CartItemType {
  Invoice = 0,
  CalToExtensionAnalyze = 30,
  CspSubscription = 100,
}
