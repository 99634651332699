import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { commonDataActions, newsArticlesActions, partnersActions, partnersSelectors } from '@appState';
import { select, Store } from '@ngrx/store';
import { isNotNullOrUndefined } from 'cui-components';
import { filter, map, tap } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';

@Injectable()
export class NewsArticleViewResolver {
  constructor(private readonly store: Store<AppState>) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('articleId')!;
    this.store.dispatch(newsArticlesActions.getOpenedNewsArticle({ id }));
    this.store.dispatch(commonDataActions.getCommonDataList({ dataType: 'topic' }));
    return this.store.pipe(
      select(partnersSelectors.selectCurrentPartner),
      tap(partner => {
        if (!partner) this.store.dispatch(partnersActions.getPartner());
      }),
      filter(isNotNullOrUndefined),
      map(partner => {
        this.store.dispatch(newsArticlesActions.getAllNewsArticleComments({ articleId: id, regionId: partner.region }));
      }),
    );
  }
}
