<cui-card *ngIf="data$ | async as data" [isLoading]="(isLoading$ | async)!">
  <cui-card-header>
    <h2>Migration and Modernization Program</h2>
  </cui-card-header>
  <cui-card-body>
    <p>
      The <a href="https://companial.com/migration-and-modernization-program/" target="_blank">Migration and Modernization Program</a> for
      Microsoft Dynamics NAV/365 Business Central, powered by Microsoft and Companial, provides free access to unlimited online assessments,
      specialized training, and technical tools to support the seamless transition of Dynamics NAV on-premises clients to the cloud.
      Additionally, it helps prepare for Microsoft Partner incentives through MCI activity-based rewards. To join the program, please submit
      this form.
    </p>

    <form [formGroup]="form">
      <cui-input formControlName="companyName" label="Company name"></cui-input>
      <cui-input formControlName="firstName" label="First name"></cui-input>
      <cui-input formControlName="surname" label="Surname"></cui-input>
      <cui-input formControlName="email" label="Email address"></cui-input>
      <p>
        Please note that Migration and Modernization Program is available exclusively to Companial Members. If you are not yet a member, we
        invite you to join our <a href="https://companial.com/why-a-membership-pays-off/" target="_blank">Membership</a> specifically
        designed for Microsoft Partners working with Dynamics 365 and Power Platform
      </p>
      <cui-checkbox formControlName="agreement" [html]="agreementLabel"></cui-checkbox>
      <p>
        Your personal data will be processed in accordance with
        <a href="https://companial.com/privacy-policy/" target="_blank">Companial Privacy Policy</a>
      </p>
    </form>
    <div *ngIf="showAlert$ | async as alert" class="alert">
      <cui-alert *ngIf="alert.show" [type]="alert.type!" [message]="alert.message"> </cui-alert>
    </div>
  </cui-card-body>
  <cui-card-footer>
    <cui-button (clicked)="onCancel()">Cancel</cui-button>
    <cui-button-cta [disabled]="!form.valid || form.disabled" (clicked)="onSubmit()">Submit</cui-button-cta>
  </cui-card-footer>
</cui-card>
