import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { identityActions } from '@appState';
import { Store } from '@ngrx/store';
import { NotificationsService } from 'cui-components';
import { catchError, Observable, of } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';
import { v4 } from 'uuid';

@Injectable()
export class HttpHighlanderInterceptor implements HttpInterceptor {
  constructor(
    private notificationsService: NotificationsService,
    private store: Store<AppState>,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const clonedRequest = request.clone({ setHeaders: { 'x-highlander-correlation-id': v4() } });

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.headers.has('x-skip-interceptor')) {
          throw error;
        }

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 && error.error.errorCode !== '401-999') {
            // 401-999 means that refresh token failed and this step needs to be skipped.
            this.store.dispatch(identityActions.userUnauthorized());
            return of();
          }
        }

        if (Object.entries(error).length) {
          this.notificationsService.showNotification(
            `Status: ${error.status}. Message: ${error.error.errorMessage ?? error.error.message}`,
          );
          throw error;
        } else {
          return of();
        }
      }),
    );
  }
}
