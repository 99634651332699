import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { ImpersonationCookiesService } from './impersonation-cookies.service';

import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { isNotNullOrUndefined } from 'cui-components';
import * as fromActions from './impersonation.actions';
import { ImpersonationService } from './impersonation.service';

@Injectable()
export class ImpersonationEffects {
  onLoadPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPartner),
      map(() => this.impersonationCookiesService.getSelectedPartner()),
      filter(isNotNullOrUndefined),
      map(({ id, name }) => fromActions.setPartner({ id, name })),
    ),
  );

  onSetPartner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.setPartner),
        tap(({ id, name }) => this.impersonationCookiesService.setSelectedPartner(id, name)),
      ),
    { dispatch: false },
  );

  onClearPartner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.clearPartner),
        tap(() => this.impersonationCookiesService.clearSelectedPartner()),
      ),
    { dispatch: false },
  );

  onQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.queryPartners),
      mergeMap(() =>
        this.impersonationService.getUserAdditionalPartners().pipe(
          map(partners => fromActions.queryPartnersSuccess({ partners })),
          catchError(error => of(fromActions.queryPartnersError(error))),
        ),
      ),
    ),
  );

  onQueryPartnersError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.queryPartnersError),
      map(({ error }) => showCustomNotification({ message: error.errorMessage })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly impersonationService: ImpersonationService,
    private readonly impersonationCookiesService: ImpersonationCookiesService,
  ) {}
}
