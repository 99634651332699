import { createReducer, on } from '@ngrx/store';
import { AlertMessage, AlertType } from '../../user/user-anonymous/user-anonymous.reducer';
import * as fromActions from './mm-util.actions';

export interface State {
  joinMMRequestInProgress: boolean;
  alert: {
    show: boolean;
    type: AlertType | undefined;
    message: AlertMessage | string;
  };
}

const initialState: State = {
  joinMMRequestInProgress: false,
  alert: {
    show: false,
    type: undefined,
    message: '',
  },
};

export const mmUtilsReducer = createReducer(
  initialState,
  on(fromActions.sendRequestToJoinMMProgram, state => ({
    ...state,
    joinMMRequestInProgress: true,
  })),
  on(fromActions.sendRequestToJoinMMProgramComplete, state => ({
    ...state,
    joinMMRequestInProgress: false,
    alert: {
      show: true,
      type: 'success' as const,
      message: `Request to join Migration and Modernization program submitted successfully.`,
    },
  })),
  on(fromActions.sendRequestToJoinMMProgramError, state => ({
    ...state,
    joinMMRequestInProgress: false,
    alert: {
      show: true,
      type: 'danger' as const,
      message: AlertMessage.DefaultErrorMessage,
    },
  })),
);
