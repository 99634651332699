import { ServiceBase } from '@1clickfactory/backend';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { identitySelectors } from '@appState';
import { backend } from '@env/backend';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { Observable, filter, switchMap } from 'rxjs';
import { AppState } from '../app.store';
import { ActivePage } from './active-page.model';

@Injectable()
export class ActivePageService extends ServiceBase<ActivePage> {
  constructor(
    httpClient: HttpClient,
    private store: Store<AppState>,
  ) {
    super(`${environment.highlanderBffUrl}/gateway/askook/${backend.Cms.PageContent}`, httpClient);
  }

  get(id: string): Observable<ActivePage> {
    return this.store.pipe(
      select(identitySelectors.selectIsAuthenticated),
      filter(isAuthenticated => isAuthenticated),
      switchMap(() => super.get('', { id })),
    );
  }
}
