import { createSelector } from '@ngrx/store'
import { getRootState } from '../app.store'
import * as fromReducer from './reducers'

/**
 * Defines actions to read project entities
 */
export const selectModuleState = createSelector(getRootState, p => p?.states)

export const { selectAll } = fromReducer.adapter.getSelectors(selectModuleState)

export const selectCountryStates = (countryId: number) =>
  createSelector(selectAll, states => (!!states && states.length && !!countryId && states.filter(s => s.CountryCode === countryId)) || [])
