import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap } from 'rxjs/operators';

import { NotificationMessage } from '@1clickfactory/notifications/notification-message';

import * as fromActions from './notifications.actions';
import { showCustomNotification } from './notifications.actions';

const defaultSnackOptions: Partial<MatSnackBarConfig> = {
  duration: 10000,
};

@Injectable()
export class NotificationsEffects {
  onShowNotification = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.showNotification),
      map(({ message, redirectLink }) =>
        showCustomNotification({
          message: NotificationsEffects.getNotificationMessage(message),
          redirectLink,
        }),
      ),
    ),
  );

  onShowCustomNotification = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.showCustomNotification),
        map(({ message, redirectLink }) => {
          const action = redirectLink ? 'OPEN' : 'OK';
          return {
            redirectLink,
            ref: this.snackBar.open(message, action, defaultSnackOptions),
          };
        }),
        // Handle navigation if snackbar contains a link
        filter(snackBar => !!snackBar.redirectLink),
        switchMap(snackBar =>
          snackBar.ref.onAction().pipe(
            map(() => {
              const route = snackBar.redirectLink!.Url;
              if (snackBar.redirectLink!.IsInternal) {
                this.router.navigate([route]);
              } else {
                window.location.href = route;
              }
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  private static getNotificationMessage(type: NotificationMessage): string {
    switch (type) {
      case NotificationMessage.SaveError:
        return 'Error while saving the request. Please try again later or contact Companial support.';

      case NotificationMessage.BraintreeError:
        return 'Something went wrong. Please try again later or contact Companial support.';

      case NotificationMessage.SubmitError:
        return 'Error while submitting the request. Please try again later or contact Companial support.';

      case NotificationMessage.DownloadError:
        return 'Error while downloading the file. Please try again later or contact Companial support.';

      case NotificationMessage.GeneralError:
        return 'We could not complete your request at this time. Please try again later or contact Companial support.';

      case NotificationMessage.AddToCartError:
        return 'Error while adding item to cart. Please try again later or contact Companial support.';

      case NotificationMessage.SaveSuccess:
        return 'Saved successfully!';

      case NotificationMessage.SubmitSuccess:
        return 'Submitted successfully!';

      case NotificationMessage.GeneralSuccess:
        return 'Operation completed successfully!';

      case NotificationMessage.DeleteSuccess:
        return 'Deleted successfully!';

      case NotificationMessage.ResetTwoFaSuccess:
        return 'Reseted successfully!';

      case NotificationMessage.AddToCartSuccess:
        return 'Added to cart successfully!';

      case NotificationMessage.CopyExternalLinkSuccess:
        return 'Copied successfully!';

      case NotificationMessage.SubscriptionToggleAutoRenew:
        return 'Changes to auto-renew for the subscription have been queued for processing. This may take a few minutes to complete.';

      case NotificationMessage.SubscriptionScheduleQuantityChange:
        return 'Scheduled quantity change for the subscription has been queued for processing. This may take a few minutes to complete.';
    }
  }
}
