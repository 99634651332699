import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { roleGroupsActions } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as fromActions from './roles.actions';
import { AuthenticatedUserRole, UserRole, UserWithAdminRole } from './roles.model';
import { RolesService } from './roles.service';

@Injectable()
export class RolesEffects {
  onGetUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUserRoles),
      mergeMap(({ userId, partnerId }) =>
        this.rolesService.getUserRoles(userId, partnerId).pipe(
          map((userRoles: UserRole[]) => fromActions.getUserRolesComplete({ userRoles })),
          catchError(error => of(fromActions.getUserRolesError(error))),
        ),
      ),
    ),
  );

  getAuthenticatedUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAuthenticatedUserRoles),
      mergeMap(() =>
        this.rolesService.getAuthenticatedUserRoles().pipe(
          map((userRoles: AuthenticatedUserRole[]) =>
            fromActions.getUserRolesComplete({ userRoles: userRoles.map(item => ({ ...item }) as UserRole) }),
          ),
          catchError(error => of(fromActions.getUserRolesError(error))),
        ),
      ),
    ),
  );

  onUpdateUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateUserRoles),
      mergeMap(({ newUserRoles, userId, partnerId }) =>
        this.rolesService.updateUserRoles(newUserRoles, userId, partnerId).pipe(
          map((updatesForUserRoles: UserRole[]) => {
            this.store.dispatch(roleGroupsActions.getUserGroupedRoles({ userId, partnerId }));
            const updatedUserRoles: Update<UserRole>[] = updatesForUserRoles.map(({ id, enabled }) => ({ id, changes: { enabled } }));
            return fromActions.updateUserRolesComplete({ updatedUserRoles });
          }),
          catchError(error => of(fromActions.updateUserRolesError(error))),
        ),
      ),
    ),
  );

  getUserAdmins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUserAdmins),
      mergeMap(() =>
        this.rolesService.getUserAdmins().pipe(
          map((admins: UserWithAdminRole[]) => fromActions.getUserAdminsComplete({ admins })),
          catchError(error => of(fromActions.getUserAdminsError(error))),
        ),
      ),
    ),
  );

  onError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUserRolesError, fromActions.updateUserRolesError, fromActions.getUserAdminsError),
      map(({ error }) => showCustomNotification({ message: error.errorMessage })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private rolesService: RolesService,
  ) {}
}
