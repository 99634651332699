import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as fromActions from './agreements.actions';
import { AgreementsService } from './agreements.service';

@Injectable()
export class AgreementsEffects {
  onGetSignedAgreements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSignedAgreements),
      switchMap(() => this.agreementsService.getSignedAgreements()),
      map(agreements => fromActions.getSignedAgreementsComplete({ agreements })),
      catchError(({ error }) => of(fromActions.getSignedAgreementsError({ error }))),
    ),
  );

  onGetSignedAgreementVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSignedAgreementVersion),
      switchMap(({ agreementId, versionId }) => this.agreementsService.getSignedAgreementVersion(agreementId, versionId)),
      map(agreement => fromActions.getSignedAgreementVersionComplete({ agreement })),
      catchError(({ error }) => of(fromActions.getSignedAgreementVersionError({ error }))),
    ),
  );

  onGetPublishedAgreement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPublishedAgreement),
      switchMap(({ publicLink }) => this.agreementsService.getPublishedAgreement(publicLink)),
      map(agreement => fromActions.getPublishedAgreementComplete({ agreement })),
      catchError(({ error }) => of(fromActions.getPublishedAgreementError({ error }))),
    ),
  );

  onGetAgreementContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAgreementContent),
      switchMap(({ agreementId }) => this.agreementsService.getAgreementContent(agreementId)),
      map(content => fromActions.getAgreementContentComplete({ content })),
      catchError(({ error }) => of(fromActions.getAgreementContentError({ error }))),
    ),
  );

  onShowError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.getSignedAgreementsError,
        fromActions.getSignedAgreementVersionError,
        fromActions.getPublishedAgreementError,
        fromActions.getAgreementContentError,
      ),
      map(({ error }) =>
        showCustomNotification({
          message: `Status: ${error.errorCode ?? 'Unknown'}. Message: ${error.errorMessage ?? 'Unknown error occured.'}`,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private agreementsService: AgreementsService,
  ) {}
}
