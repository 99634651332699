import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from './identity.models';

@Injectable()
export class IdentityService {
  url = `auth`;

  constructor(private readonly http: HighlanderService) {}

  login(): Observable<void> {
    return this.http.get<void>(`${this.url}/login`);
  }

  logout(): Observable<void> {
    return this.http.get<void>(`${this.url}/logout`);
  }

  refresh(): Observable<{ expires_at: number }> {
    return this.http.get<{ expires_at: number }>(`${this.url}/refresh`);
  }

  checkAuth(): Observable<UserModel> {
    return this.http.get<UserModel>(`${this.url}/authStatus`, {
      headers: { 'x-skip-interceptor': '' },
    });
  }
}
