import { createAction, props } from '@ngrx/store';
import { AdditionalPartnerRespose } from './impersonation.model';
import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';

const NAMESPACE = '[Impersonation]';

export const loadPartner = createAction(`${NAMESPACE} LoadPartner`);
export const setPartner = createAction(`${NAMESPACE} SetPartner`, props<{ id: string; name: string }>());
export const clearPartner = createAction(`${NAMESPACE} ClearPartner`);

export const queryPartners = createAction(`${NAMESPACE} QueryPartners`);
export const queryPartnersSuccess = createAction(`${NAMESPACE} QueryPartnersSuccess`, props<{ partners: AdditionalPartnerRespose[] }>());
export const queryPartnersError = createAction(`${NAMESPACE} QueryPartnersError `, props<{ error: HighlanderCoreErrorResponse }>());
