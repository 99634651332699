import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import * as fromActions from './partner.actions'
import { PartnerService } from './partner.service'

@Injectable()
export class PartnerEffects {
  onQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.query),
      mergeMap(() =>
        this.partnerService.getAll().pipe(
          map(partners => fromActions.queryComplete({ partners })),
          catchError(() => of(fromActions.queryError())),
        ),
      ),
    ),
  )

  constructor(private actions$: Actions, private partnerService: PartnerService) {}
}
