import { createReducer, on } from '@ngrx/store';
import * as fromActions from './identity.actions';
import { MappedUserModel, mapClaimsIdentityToIdentity } from './identity.models';

export interface State {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: MappedUserModel | null;
}

const initialState: State = {
  isAuthenticated: false,
  isLoading: false,
  user: null,
};

export const reducer = createReducer(
  initialState,
  on(fromActions.checkAuth, state => ({
    ...state,
    isAuthenticated: false,
    isLoading: true,
  })),
  on(fromActions.checkAuthCompleted, (state, { userinfo, permissions, expires_at }) => ({
    ...state,
    user: { userinfo: mapClaimsIdentityToIdentity(userinfo), permissions, expires_at },
    isAuthenticated: true,
    isLoading: false,
  })),
  on(fromActions.refreshCompleted, (state, { expires_at }) => ({
    ...state,
    user: { ...state.user!, expires_at },
    isAuthenticated: true,
  })),
  on(fromActions.userUnauthorized, state => ({
    ...state,
    isAuthenticated: false,
    isLoading: false,
  })),
);
