import { ServiceBase } from '@1clickfactory/backend';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BillingInformation } from '@appState';
import { backend } from '@env/backend';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable()
export class UserCompanyService extends ServiceBase<BillingInformation> {
  constructor(httpClient: HttpClient) {
    super(`${environment.highlanderBffUrl}/gateway/askook/${backend.Users.Companies}`, httpClient);
  }

  get(): Observable<BillingInformation> {
    return super.get('-1');
  }
}
