import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import * as fromActions from './partner.actions'
import { Partner } from './partner.model'

export interface State extends EntityState<Partner> {
  isLoading: boolean
  isLoaded: boolean
}

export const adapter: EntityAdapter<Partner> = createEntityAdapter<Partner>({
  selectId: (partner: Partner) => partner.UId,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
})

export const partnerReducer = createReducer(
  initialState,
  on(fromActions.query, state => ({ ...state, isLoading: true })),
  on(fromActions.queryComplete, (state, { partners }) => ({ ...adapter.addMany(partners, state), isLoading: false, isLoaded: true })),
  on(fromActions.queryError, state => ({ ...state, isLoading: false })),
)

export function reducer(state = initialState, action: Action): State {
  return partnerReducer(state, action)
}
