import { createReducer, on } from '@ngrx/store';
import * as fromActions from './impersonation.actions';
import { AdditionalPartnerRespose } from './impersonation.model';

export interface State {
  isLoading: boolean;
  partners: AdditionalPartnerRespose[];
  selectedPartnerId: string | null;
  selectedPartnerName: string | null;
}

export const initialState: State = {
  isLoading: false,
  partners: [],
  selectedPartnerId: null,
  selectedPartnerName: null,
};

export const impersonationReducer = createReducer(
  initialState,

  on(fromActions.queryPartners, state => ({ ...state, isLoading: true })),
  on(fromActions.queryPartnersSuccess, (state, { partners }) => ({ ...state, isLoading: false, partners })),
  on(fromActions.queryPartnersError, state => ({ ...state, isLoading: false, partners: [] })),

  on(fromActions.setPartner, (state, { id, name }) => ({ ...state, selectedPartnerId: id, selectedPartnerName: name })),
  on(fromActions.clearPartner, state => ({ ...state, selectedPartnerId: null, selectedPartnerName: null })),
);
