import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import * as formActions from './form.actions'
import { Form } from './form.model'

export interface State extends EntityState<Form> {}

export const adapter: EntityAdapter<Form> = createEntityAdapter<Form>({
  selectId: (form: Form) => form.UId,
})

export const initialState: State = adapter.getInitialState({})

export function reducer(state = initialState, action: formActions.All): State {
  switch (action.type) {
    case formActions.Actions.CreateForm: {
      // ToDo: Jurgis: Fix this hack
      const partial = adapter.removeOne(action.payload.UId, state)
      return adapter.addOne(action.payload, partial)
    }

    case formActions.Actions.UpdateForm:
      return adapter.updateOne(action.payload, state)

    case formActions.Actions.SetValidatable:
      return adapter.updateOne({ id: action.payload, changes: { IsValidatable: true } }, state)

    case formActions.Actions.SubmitForm:
      return adapter.updateOne(
        { id: action.payload.UId, changes: { IsProcessing: true, HasBackendErrors: false, IsSubmitted: false } },
        state,
      )

    case formActions.Actions.SubmitFormComplete:
      return adapter.updateOne({ id: action.payload, changes: { IsProcessing: false, HasBackendErrors: false, IsSubmitted: true } }, state)

    case formActions.Actions.SubmitFormError:
      return adapter.updateOne({ id: action.payload, changes: { IsProcessing: false, HasBackendErrors: true, IsSubmitted: false } }, state)

    case formActions.Actions.SetAskookErrorCode:
      return adapter.updateOne(
        {
          id: action.payload.UId,
          changes: {
            IsProcessing: false,
            HasBackendErrors: true,
            IsSubmitted: false,
            AskookErrorCode: action.payload.ErrorCode,
          },
        },
        state,
      )

    default:
      return state
  }
}
