import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ICustomer } from '../customer.model';
import * as fromActions from './customers-list.actions';

export interface State extends EntityState<ICustomer> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<ICustomer> = createEntityAdapter<ICustomer>();

const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const customersListReducer = createReducer(
  initialState,
  on(fromActions.getCustomersList, state => ({ ...state, isLoading: true })),
  on(fromActions.getCustomersListComplete, (state, { customers }) => adapter.setAll(customers, { ...state, isLoading: false })),
  on(fromActions.getCustomersListError, state => ({ ...state, isLoading: false })),
);
