import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { GroupedRole } from '../roles/roles.model';
import * as fromActions from './role-groups.actions';
import { RoleGroupsService } from './role-groups.service';

@Injectable()
export class RoleGroupsEffects {
  onGetAuthenticatedUserGroupedRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAuthenticatedUserGroupedRoles),
      switchMap(() =>
        this.roleGroupsService.getAuthenticatedUserGroupedRoles().pipe(
          map((groupedRoles: GroupedRole[]) => fromActions.getUserGroupedRolesComplete({ groupedRoles })),
          catchError(error => of(fromActions.getUserGroupedRolesError(error))),
        ),
      ),
    ),
  );

  onGetUserGroupedRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUserGroupedRoles),
      switchMap(({ userId, partnerId }) =>
        this.roleGroupsService.getUserGroupedRoles(userId, partnerId).pipe(
          map((groupedRoles: GroupedRole[]) => fromActions.getUserGroupedRolesComplete({ groupedRoles })),
          catchError(error => of(fromActions.getUserGroupedRolesError(error))),
        ),
      ),
    ),
  );

  onError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUserGroupedRolesError),
      map(({ error }) => showCustomNotification({ message: error.errorMessage })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly roleGroupsService: RoleGroupsService,
  ) {}
}
