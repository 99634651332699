import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { createAction, props } from '@ngrx/store';
import { PartnerBusinessDataResponse, PartnerResponse, PartnerUpdateRequest, SendUpdatePartnerResponse } from './partners.model';

const NAMESPACE = '[Partner]';

export const getPartner = createAction(`${NAMESPACE} GetPartner`);
export const getPartnerComplete = createAction(`${NAMESPACE} GetPartnerComplete`, props<{ partner: PartnerResponse }>());
export const getPartnerError = createAction(`${NAMESPACE} GetPartnerError`, props<{ error: HighlanderCoreErrorResponse }>());

export const sendUpdateRequest = createAction(`${NAMESPACE} SendUpdateRequest`, props<{ model: PartnerUpdateRequest }>());
export const sendUpdateRequestComplete = createAction(`${NAMESPACE} SendUpdateRequestComplete`, props<SendUpdatePartnerResponse>());
export const sendUpdateRequestError = createAction(`${NAMESPACE} SendUpdateRequestError`, props<{ error: HighlanderCoreErrorResponse }>());

export const getPartnerBusinessData = createAction(`${NAMESPACE} GetPartnerBusinessData`);
export const getPartnerBusinessDataComplete = createAction(
  `${NAMESPACE} GetPartnerBusinessDataComplete`,
  props<{ businessData: PartnerBusinessDataResponse }>(),
);
export const getPartnerBusinessDataError = createAction(
  `${NAMESPACE} GetPartnerBusinessDataError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);
