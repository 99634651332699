import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { AskookErrorCode } from './askook-error-code';
import { DTO } from './backend.models';

export const getAskookErrorCode = (e?: HttpErrorResponse): AskookErrorCode =>
  e?.error?.message ? (Number(e.error.message) as AskookErrorCode) : AskookErrorCode.SystemUnknownError;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getURI = (dto: DTO<any>): string => `${environment.highlanderBffUrl}/gateway/askook/${dto.Target}`;
