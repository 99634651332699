import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ICustomer } from '../customer.model';
import * as fromActions from './customer.actions';

export interface State extends EntityState<ICustomer> {
  isLoading: boolean;
  selectedCustomerId: string | null;
}

export const adapter: EntityAdapter<ICustomer> = createEntityAdapter<ICustomer>();

const initialState: State = adapter.getInitialState({
  isLoading: false,
  selectedCustomerId: null,
});

export const customerReducer = createReducer(
  initialState,

  on(fromActions.getCustomer, (state, { id }) => ({ ...state, selectedCustomerId: id, isLoading: true })),
  on(fromActions.getCustomerComplete, (state, { customer }) => adapter.setOne(customer, { ...state, isLoading: false })),
  on(fromActions.getCustomerError, state => ({ ...state, isLoading: false })),

  on(fromActions.addCustomer, state => ({ ...state, isLoading: true })),
  on(fromActions.addCustomerComplete, (state, { customer }) => adapter.addOne(customer, { ...state, isLoading: false })),
  on(fromActions.addCustomerError, state => ({ ...state, isLoading: false })),

  on(fromActions.updateCustomer, state => ({ ...state, isLoading: true })),
  on(fromActions.updateCustomerComplete, (state, { customer }) =>
    adapter.updateOne({ id: customer.id, changes: customer }, { ...state, isLoading: false }),
  ),
  on(fromActions.updateCustomerError, state => ({ ...state, isLoading: false })),

  on(fromActions.resetSelectedCustomerId, state => ({ ...state, selectedCustomerId: null })),
);
