import { NgModule } from '@angular/core';
import { OldHighlanderService } from './old-highlander.service';
import { HttpClientModule } from '@angular/common/http';
import { HighlanderService } from './highlander.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [OldHighlanderService, HighlanderService],
})
export class DataAccessModule {}
