import { environment } from '@env/environment';

export class CookieAuth {
  static logout(): void {
    document.location.href = `${environment.highlanderBffUrl}/auth/logout`;
  }

  static login(): void {
    document.location.href = `${environment.highlanderBffUrl}/auth/login`;
  }
}
