import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { ICustomer } from '../customer.model';
import { CustomersService } from '../customers.service';
import * as fromActions from './customers-list.actions';

@Injectable()
export class CustomersListEffects {
  onGetCustomersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCustomersList),
      switchMap(() =>
        this.customersService.getAll().pipe(
          map((customers: ICustomer[]) => fromActions.getCustomersListComplete({ customers })),
          catchError(({ error }) => of(showCustomNotification({ message: error.errorMessage }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly customersService: CustomersService,
  ) {}
}
