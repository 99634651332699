import { CartItemType } from '@appState';
import { defaultCurrencySymbol, getCurrencySymbol } from '@env/currency';
import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';
import * as fromReducer from './cart-items.reducer';

export const selectModuleState = createSelector(getRootState, cart => cart?.cartItems);
export const { selectAll } = fromReducer.adapter.getSelectors(selectModuleState);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsProcessingCartPayment = createSelector(selectModuleState, state => state.isProcessingCartPayment);
export const selectCartPaymentError = createSelector(selectModuleState, state => state.cartPaymentError);
export const selectCartItems = createSelector(selectAll, cartItems => cartItems ?? []);
export const selectCanCheckOut = createSelector(
  selectCartItems,
  selectIsProcessingCartPayment,
  selectCartPaymentError,
  (cartItems, isProcessingPayment, paymentError) =>
    cartItems.length ? !cartItems.some(({ error }) => error) && !isProcessingPayment && !paymentError : false,
);
export const selectTooltipText = createSelector(selectCartItems, cartItems => cartItems.find(({ error }) => error)?.error ?? '');
export const selectCurrency = createSelector(selectCartItems, cartItems =>
  getCurrencySymbol(cartItems[0]?.currency || defaultCurrencySymbol),
);

export const selectCanPayForCartWithInvoice = createSelector(
  selectCartItems,
  cartItems => !cartItems.some(cartItem => !cartItem.canPayByInvoice),
);

export const selectOnlyInvoicesInCart = createSelector(selectCartItems, cartItems =>
  cartItems.every(item => item.itemType === CartItemType.Invoice),
);
