import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';
import { TicketFilterStatus, TicketListItem, TicketStatus } from './support.model';
import * as fromReducer from './support.reducer';

export const selectModuleState = createSelector(getRootState, rootState => rootState.support);
export const { selectAll } = fromReducer.adapter.getSelectors(selectModuleState);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsLoadingNote = createSelector(selectModuleState, state => state.isNoteLoading);

export const selectTicketsList = createSelector(selectModuleState, state => state.tickets ?? []);
export const selectCurrentTicket = createSelector(selectModuleState, state => state.ticket);
export const selectNotesList = createSelector(selectModuleState, state => state.notes ?? []);
export const selectNote = createSelector(selectModuleState, state => state.note);
export const selectTicketListFilteredByStatus = createSelector(selectModuleState, selectTicketsList, (state, tickets) =>
  filterByTicketStatus(tickets, state.filterByTicketStatus),
);

export const selectSetupIsLoading = createSelector(selectModuleState, state => state.supportSetup.isLoading);

export const selectMapTypesListWithChoices = createSelector(selectModuleState, state =>
  state.supportSetup.types.map(c => ({ value: c.id, text: c.name, categoryId: c.categoryId })),
);
export const selectMapCategoriesListWithChoices = createSelector(selectModuleState, state =>
  state.supportSetup.categories.map(c => ({ value: c.id, text: c.name })),
);
export const selectMapSeveritiesListWithChoices = createSelector(selectModuleState, state =>
  state.supportSetup.severities.map(s => ({ value: s.id, text: s.name })),
);

export const selectUploadedAttachment = createSelector(selectModuleState, state => state.attachments ?? []);
export const selectBlobInfo = createSelector(selectModuleState, state => state.attachmentsURL ?? []);
export const selecetSetup = createSelector(selectModuleState, state => state.supportSetup);

const filterByTicketStatus = (tickets: TicketListItem[], status: TicketFilterStatus): TicketListItem[] => {
  const ACTIVE_TICKET_STATUSES: TicketStatus[] = [
    'New',
    'In Progress',
    'On Hold',
    'Waiting for Details',
    'Researching',
    'Waiting on Customer',
    'Waiting on Supplier',
    'Waiting on Closure confirmation',
    'Reply Received',
  ];

  return status === 'Open Only' ? tickets.filter(ticket => ACTIVE_TICKET_STATUSES.includes(ticket.status)) : tickets;
};
