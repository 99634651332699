import { createReducer, on } from '@ngrx/store';
import * as fromActions from './partners.actions';
import { IPartner, IPartnerBusinessData } from './partners.model';

export interface State {
  isLoading: boolean;
  selectedPartner: IPartner | null;
  partnerBusinessData: IPartnerBusinessData | null;
}

export const initialState: State = {
  isLoading: false,
  selectedPartner: null,
  partnerBusinessData: null,
};

export const partnersReducer = createReducer(
  initialState,
  on(fromActions.getPartner, state => ({ ...state, isLoading: true })),
  on(fromActions.getPartnerComplete, (state, { partner }) => ({ ...state, selectedPartner: partner, isLoading: false })),
  on(fromActions.getPartnerError, state => ({ ...state, userRoles: null, isLoading: false })),

  on(fromActions.sendUpdateRequest, state => ({ ...state, isLoading: true })),
  on(fromActions.sendUpdateRequestComplete, state => ({ ...state, isLoading: false })),
  on(fromActions.sendUpdateRequestError, state => ({ ...state, isLoading: false })),

  on(fromActions.getPartnerBusinessData, state => ({ ...state, isLoading: true })),
  on(fromActions.getPartnerBusinessDataComplete, (state, { businessData }) => ({
    ...state,
    partnerBusinessData: businessData,
    isLoading: false,
  })),
  on(fromActions.getPartnerBusinessDataError, state => ({ ...state, userRoles: null, isLoading: false })),
);
