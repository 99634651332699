import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ChoiceTranslate } from './choice-translate';

@NgModule({
  imports: [TranslateModule],
  providers: [ChoiceTranslate],
})
export class OCFTranslationsModule {}
