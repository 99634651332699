type KnownCurrencies = 'USD' | 'GBP' | 'EUR' | 'CAD' | 'AUD'

export const currencySymbol: Record<KnownCurrencies, string> = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  CAD: 'C$',
  AUD: 'A$',
}

export const defaultCurrencySymbol = currencySymbol.EUR

export const defaultCurrency: KnownCurrencies = 'EUR'

export const getCurrencySymbol = (code: string) => {
  const currencyCode = code as KnownCurrencies
  const symbol = currencySymbol[currencyCode]

  return !symbol ? code : symbol
}
