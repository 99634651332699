import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

export const selectModuleState = createSelector(getRootState, rootState => rootState.agreements);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectSignedAgreements = createSelector(selectModuleState, state => state.signedAgreements ?? []);
export const selectAgreementVersion = createSelector(selectModuleState, state => state.signedAgreementVersion);
export const selectPublicAgreement = createSelector(selectModuleState, state => state.publishedAgreement);
export const selectPublicAgreementContent = createSelector(selectModuleState, state => state.publishedAgreementContent);
