import { CanActivate } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, first, map, share } from 'rxjs/operators';
export class ReduxActivator implements CanActivate {
  protected actions?: Action[];
  protected selectorContracts?: ((value: object) => unknown)[];

  protected constructor(protected store: Store) {}

  isResolved(data: unknown[]): boolean {
    return !!data && !!data.length && data.every(d => !!d);
  }

  onAccessDenied(): boolean {
    return false;
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.dispatchActions();

    return this.resolveContracts();
  }

  private dispatchActions(): void {
    if (this.actions && this.actions.length) {
      this.actions.forEach(a => this.store.dispatch(a));
    }
  }

  private resolveContracts(): Observable<boolean> {
    if (this.selectorContracts && this.selectorContracts.length) {
      return combineLatest(...this.selectorContracts.map(s => this.store.select(s))).pipe(
        filter(data => (data as unknown as any)?.reduce((acc: boolean, datum: unknown) => acc && !!datum, true)),
        first(),
        share(),
        map((data: unknown[]) => (this.isResolved(data) ? true : this.onAccessDenied())),
      );
    }

    return of(true);
  }
}
