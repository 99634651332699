import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { AuthenticatedUserRole, NewUserRole, UserRole, UserWithAdminRole } from '@appState';
import { Observable } from 'rxjs';

@Injectable()
export class RolesService {
  private readonly path = (url = ''): string => `authorization/roles/${url}`;

  constructor(private highlanderService: HighlanderService) {}

  getUserRoles(userId: string, partnerId: string): Observable<UserRole[]> {
    return this.highlanderService.get<UserRole[]>(this.path(`${userId}/${partnerId}`));
  }

  getAuthenticatedUserRoles(): Observable<AuthenticatedUserRole[]> {
    return this.highlanderService.get<AuthenticatedUserRole[]>(this.path());
  }

  updateUserRoles(model: NewUserRole[], userId: string, partnerId: string): Observable<UserRole[]> {
    return this.highlanderService.patch<NewUserRole[], UserRole[]>(this.path(`${userId}/${partnerId}`), model);
  }

  getUserAdmins(): Observable<UserWithAdminRole[]> {
    return this.highlanderService.get<UserWithAdminRole[]>(this.path(`show-admins`));
  }
}
