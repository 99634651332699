import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[MM Util]';

export const sendRequestToJoinMMProgram = createAction(`${NAMESPACE} SendRequestToJoinMMProgram`);
export const sendRequestToJoinMMProgramComplete = createAction(`${NAMESPACE} SendRequestToJoinMMProgramComplete`);
export const sendRequestToJoinMMProgramError = createAction(
  `${NAMESPACE} SendRequestToJoinMMProgramError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);
