import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { createAction, props } from '@ngrx/store';
import { CustomerCreateRequest, CustomerUpdateRequest, ICustomer } from '../customer.model';

const NAMESPACE = '[Customer]';

export const getCustomer = createAction(`${NAMESPACE} GetCustomer`, props<{ id: string }>());
export const getCustomerComplete = createAction(`${NAMESPACE} GetCustomerComplete`, props<{ customer: ICustomer }>());
export const getCustomerError = createAction(`${NAMESPACE} GetCustomerError`, props<{ error: HighlanderCoreErrorResponse }>());

export const addCustomer = createAction(`${NAMESPACE} AddCustomer`, props<{ customer: CustomerCreateRequest }>());
export const addCustomerComplete = createAction(`${NAMESPACE} AddCustomerComplete`, props<{ customer: ICustomer; redirectUrl: string }>());
export const addCustomerError = createAction(`${NAMESPACE} AddCustomerError`, props<{ error: HighlanderCoreErrorResponse }>());

export const updateCustomer = createAction(`${NAMESPACE} UpdateCustomer`, props<{ id: string; customer: CustomerUpdateRequest }>());
export const updateCustomerComplete = createAction(`${NAMESPACE} UpdateCustomerComplete`, props<{ customer: ICustomer }>());
export const updateCustomerError = createAction(`${NAMESPACE} UpdateCustomerError`, props<{ error: HighlanderCoreErrorResponse }>());

export const resetSelectedCustomerId = createAction(`${NAMESPACE} ResetSelectedCustomerId`);
