import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Announcement, AnnouncementResponse, UpdateAnnouncementStatus } from './announcements.model';

@Injectable()
export class AnnouncementsService {
  constructor(private highlanderService: HighlanderService) {}

  getAll(): Observable<Announcement[]> {
    return this.highlanderService
      .getAll<AnnouncementResponse>('announcements')
      .pipe(map(response => response.map(r => ({ ...r, isRead: false }))));
  }

  changeStatus(id: string, status: UpdateAnnouncementStatus): Observable<void> {
    return this.highlanderService.post(`announcements/${id}/status`, { status });
  }
}
