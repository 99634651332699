import { ServiceBase } from '@1clickfactory/backend';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backend } from '@env/backend';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Country } from './models';

@Injectable()
export class CountryService extends ServiceBase<Country> {
  constructor(httpClient: HttpClient) {
    super(`${environment.highlanderBffUrl}/gateway/askook/${backend.Location.Countries}`, httpClient);
  }

  getAll(): Observable<Country[]> {
    return super.getAll();
  }
}
