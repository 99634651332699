import { createReducer, on } from '@ngrx/store';
import * as fromActions from './announcements.actions';
import { Announcement } from './announcements.model';

export interface State {
  announcements: Announcement[];
  isLoading: boolean;
}

export const initialState: State = {
  announcements: [],
  isLoading: false,
};

export const announcementsReducer = createReducer(
  initialState,
  on(fromActions.get, state => ({ ...state, isLoading: true })),
  on(fromActions.getComplete, (state, { announcements }) => ({ ...state, isLoading: false, announcements })),
  on(fromActions.getError, state => ({ ...state, isLoading: false })),

  on(fromActions.changeStatus, (state, { id }) => ({
    ...state,
    announcements: updateAnnouncementList(id, state.announcements),
  })),
  on(fromActions.changeStatusComplete, state => ({ ...state })),
  on(fromActions.changeStatusError, state => ({ ...state })),
);

const updateAnnouncementList = (id: string, announcements: Announcement[]): Announcement[] => {
  const ann = announcements.map(ann => {
    if (ann.id === id) {
      return { ...ann, isRead: true };
    }

    return ann;
  });

  return [...ann];
};
