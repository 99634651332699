import { createReducer, on } from '@ngrx/store';
import { AgreementContent, PublicAgreement, SignedAgreemenVersion, SignedAgreementListItem } from './agreement.model';
import * as fromActions from './agreements.actions';

export interface State {
  isLoading: boolean;
  signedAgreements: SignedAgreementListItem[];
  signedAgreementVersion: SignedAgreemenVersion | null;
  publishedAgreement: PublicAgreement | null;
  publishedAgreementContent: AgreementContent | null;
}

export const initialState: State = {
  isLoading: false,
  signedAgreements: [],
  signedAgreementVersion: null,
  publishedAgreement: null,
  publishedAgreementContent: null,
};

export const agreementsReducer = createReducer(
  initialState,
  on(fromActions.getSignedAgreements, state => ({ ...state, isLoading: true })),
  on(fromActions.getSignedAgreementsComplete, (state, { agreements }) => ({ ...state, signedAgreements: agreements, isLoading: false })),
  on(fromActions.getSignedAgreementsError, state => ({ ...state, isLoading: false })),

  on(fromActions.getSignedAgreementVersion, state => ({ ...state, isLoading: true })),
  on(fromActions.getSignedAgreementVersionComplete, (state, { agreement }) => ({
    ...state,
    signedAgreementVersion: agreement,
    isLoading: false,
  })),
  on(fromActions.getSignedAgreementVersionError, state => ({ ...state, isLoading: false })),

  on(fromActions.getPublishedAgreement, state => ({ ...state, isLoading: true })),
  on(fromActions.getPublishedAgreementComplete, (state, { agreement }) => ({
    ...state,
    publishedAgreement: agreement,
  })),
  on(fromActions.getPublishedAgreementError, state => ({ ...state, isLoading: false })),

  on(fromActions.getAgreementContent, state => ({ ...state, isLoading: true })),
  on(fromActions.getAgreementContentComplete, (state, { content }) => ({
    ...state,
    publishedAgreementContent: content,
    isLoading: false,
  })),
  on(fromActions.getAgreementContentError, state => ({ ...state, isLoading: false })),
);
