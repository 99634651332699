import { Injectable } from '@angular/core';
import { CookieAuth } from '@highlander/common/helpers/authentication';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as fromActions from './identity.actions';
import { IdentityService } from './identity.service';

@Injectable()
export class IdentityEffects {
  onCheckAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.checkAuth),
      switchMap(() => {
        return this.identityService.checkAuth().pipe(
          map(({ userinfo, permissions, expires_at }) => fromActions.checkAuthCompleted({ userinfo, permissions, expires_at })),
          catchError(() => of(fromActions.userUnauthorized())),
        );
      }),
    ),
  );

  onRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.refresh),
      switchMap(() =>
        this.identityService.refresh().pipe(
          map(({ expires_at }) => fromActions.refreshCompleted({ expires_at })),
          catchError(err => of(fromActions.refreshError({ err }))),
        ),
      ),
    ),
  );

  onRefreshError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.refreshError),
        tap(() => CookieAuth.logout()),
      ),
    { dispatch: false },
  );

  onUserUnauthorized$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.userUnauthorized),
        tap(() => CookieAuth.login()),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private readonly identityService: IdentityService,
  ) {}
}
