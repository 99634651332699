import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OcfFormButtonRowComponent } from '@1clickfactory/simple-ui/components/form-button-row/ocf-form-button-row.component';
import { OcfFormReadonlyButtonRowComponent } from '@1clickfactory/simple-ui/components/form-button-row/ocf-form-readonly-button-row.component';
import { CuiButtonsModule } from 'cui-components';

@NgModule({
  imports: [CommonModule, CuiButtonsModule],
  declarations: [OcfFormButtonRowComponent, OcfFormReadonlyButtonRowComponent],
  exports: [OcfFormButtonRowComponent, OcfFormReadonlyButtonRowComponent],
})
export class OcfFormButtonRowModule {}
