import { RequestOptions } from '@1clickfactory/backend/services';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RequestParams } from './types';

export abstract class ServiceBase<TEntity> {
  protected constructor(
    protected uri: string,
    protected client: HttpClient,
  ) {}

  protected getAll(params?: RequestParams): Observable<TEntity[]> {
    return this.client.get<TEntity[]>(this.uri, this.getOptions(params));
  }

  protected get(id: string | number, params?: RequestParams): Observable<TEntity> {
    return this.client.get<TEntity>(`${this.uri}/${id}`, this.getOptions(params));
  }

  protected post(model: TEntity, params?: RequestParams): Observable<TEntity> {
    return this.client.post<TEntity>(this.uri, model, this.getOptions(params));
  }

  protected put(id: string | number, model: TEntity | TEntity[], params?: RequestParams): Observable<TEntity> {
    return this.client.put<TEntity>(`${this.uri}/${id}`, model, this.getOptions(params));
  }

  protected delete(id: string | number, params?: RequestParams): Observable<void> {
    return this.client.delete<void>(`${this.uri}/${id}`, this.getOptions(params));
  }

  protected getOptions(params?: RequestParams): RequestOptions {
    if (!params) {
      return {};
    }
    return {
      params,
    };
  }
}
