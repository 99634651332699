import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { customersListActions } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { ICustomer } from '../customer.model';
import { CustomersService } from '../customers.service';
import * as fromActions from './customer.actions';

@Injectable()
export class CustomerEffects {
  onGetCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCustomer),
      switchMap(({ id }) =>
        this.customersService.get(id).pipe(
          map((customer: ICustomer) => fromActions.getCustomerComplete({ customer })),
          catchError(({ error }) => of(fromActions.getCustomerError({ error }))),
        ),
      ),
    ),
  );

  onUpdateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCustomer),
      switchMap(({ id, customer }) =>
        this.customersService.update(id, { ...customer }).pipe(
          map((customer: ICustomer) => fromActions.updateCustomerComplete({ customer })),
          catchError(({ error }) => of(fromActions.updateCustomerError({ error }))),
        ),
      ),
    ),
  );

  onUpdateCustomerComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCustomerComplete),
      map(() => customersListActions.getCustomersList()),
    ),
  );

  onAddCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCustomer),
      switchMap(({ customer }) =>
        this.customersService.create({ ...customer }).pipe(
          map((customer: ICustomer) => fromActions.addCustomerComplete({ customer, redirectUrl: `customers/${customer.id}/details` })),
          catchError(({ error }) => of(fromActions.addCustomerError({ error }))),
        ),
      ),
    ),
  );

  onAddCustomerComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCustomerComplete),
      tap(({ redirectUrl }) => this.router.navigate([redirectUrl])),
      map(() => customersListActions.getCustomersList()),
    ),
  );

  onShowAddCustomerComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCustomerComplete),
      map(() => showCustomNotification({ message: 'Customer Added: Please, review the details.' })),
    ),
  );

  onShowError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCustomerError, fromActions.addCustomerError, fromActions.updateCustomerError),
      map(({ error }) => showCustomNotification({ message: error.errorMessage })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly customersService: CustomersService,
  ) {}
}
