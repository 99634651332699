import { CurrencyPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { defaultCurrency } from '@env/currency';

@Pipe({
  name: 'money',
  pure: true,
})
export class MoneyPipe implements PipeTransform {
  private _currencyPipe: CurrencyPipe;

  constructor(@Inject(LOCALE_ID) public locale: string) {
    this._currencyPipe = new CurrencyPipe(locale);
  }

  transform(value: number, ...args: unknown[]): string {
    const currency = (args?.[0] as string) || defaultCurrency;
    const precisionString = args?.[1] as string;
    const precision = typeof precisionString === 'number' && !isNaN(precisionString) ? precisionString : 2;
    const numberValue = typeof value !== 'number' || isNaN(value) ? 0 : value;
    const result = this._currencyPipe.transform(numberValue, currency, 'symbol-narrow', `1.${precision}-${precision}`);
    return result ?? '';
  }
}
