import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

export type AppConfig = Record<string, string | undefined>;

@Injectable({ providedIn: 'root' })
export class ConfigService {
  config!: AppConfig;

  constructor(private highlanderService: HighlanderService) {}

  loadConfig(): Observable<AppConfig> {
    return this.highlanderService.get<AppConfig>('config').pipe(tap(config => (this.config = config)));
  }
}
