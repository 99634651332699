import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as fromActions from './backend.actions';
import { getAskookErrorCode, getURI } from './backend.helpers';
import { DTO } from './backend.models';

@Injectable()
export class BackendEffects {
  onQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.Actions.Query),
      map((action: fromActions.Query) => action.payload),
      mergeMap(dto =>
        this.client.get<any[]>(this.getURIWithId(dto)).pipe(
          map(response => (response ? { ...dto, Data: [...(dto.Data || []), ...response] } : { ...dto })),
          map(resultDTO => (!!dto.OverrideCompleteAction ? dto.OverrideCompleteAction(resultDTO) : new fromActions.GetComplete(resultDTO))),
          catchError(e =>
            of(!!dto.OverrideErrorAction ? dto.OverrideErrorAction(dto, getAskookErrorCode(e)) : new fromActions.GetError(dto)),
          ),
        ),
      ),
    ),
  );

  onGet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.Actions.Get),
      map((action: fromActions.Get) => action.payload),
      mergeMap(dto =>
        this.client.get<DTO<any>>(this.getURIWithId(dto)).pipe(
          map(response => (response ? { ...dto, Data: { ...(dto.Data || {}), ...response } } : { ...dto })),
          map(resultDTO => (!!dto.OverrideCompleteAction ? dto.OverrideCompleteAction(resultDTO) : new fromActions.GetComplete(resultDTO))),
          catchError(e =>
            of(!!dto.OverrideErrorAction ? dto.OverrideErrorAction(dto, getAskookErrorCode(e)) : new fromActions.GetError(dto)),
          ),
        ),
      ),
    ),
  );

  onGetBlob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.Actions.GetBlob),
      map((action: fromActions.GetBlob) => action.payload),
      mergeMap(dto =>
        this.client.get(this.getURIWithId(dto), { responseType: 'blob' }).pipe(
          map(response => (response ? { ...dto, Data: response } : { ...dto })),
          map(resultDTO => (!!dto.OverrideCompleteAction ? dto.OverrideCompleteAction(resultDTO) : new fromActions.GetComplete(resultDTO))),
          catchError(e =>
            of(!!dto.OverrideErrorAction ? dto.OverrideErrorAction(dto, getAskookErrorCode(e)) : new fromActions.GetError(dto)),
          ),
        ),
      ),
    ),
  );

  //#region Create

  onCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.Actions.Create),
      map((action: fromActions.Create) => action.payload),
      mergeMap(dto =>
        this.client.post<DTO<any>>(getURI(dto), dto.Data).pipe(
          map(response => (response ? { ...dto, ...response } : { ...dto })),
          map(resultDTO =>
            !!dto.OverrideCompleteAction ? dto.OverrideCompleteAction(resultDTO) : new fromActions.CreateComplete(resultDTO),
          ),
          catchError(e =>
            of(!!dto.OverrideErrorAction ? dto.OverrideErrorAction(dto, getAskookErrorCode(e)) : new fromActions.CreateError(dto)),
          ),
        ),
      ),
    ),
  );

  //#endregion

  //#region Update

  onUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.Actions.Update),
      map((action: fromActions.Update) => action.payload),
      mergeMap(dto =>
        this.client.put<DTO<any>>(this.getURIWithId(dto), dto.Data).pipe(
          map(response => (response ? { ...dto, ...response } : { ...dto })),
          map(resultDTO =>
            !!dto.OverrideCompleteAction ? dto.OverrideCompleteAction(resultDTO) : new fromActions.UpdateComplete(resultDTO),
          ),
          catchError(e =>
            of(!!dto.OverrideErrorAction ? dto.OverrideErrorAction(dto, getAskookErrorCode(e)) : new fromActions.UpdateError(dto)),
          ),
        ),
      ),
    ),
  );

  //#endregion

  //#region Delete

  onDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.Actions.Delete),
      map((action: fromActions.Delete) => action.payload),
      mergeMap(dto =>
        this.client.delete<DTO<string>>(this.getURIWithId(dto)).pipe(
          map(() => (!!dto.OverrideCompleteAction ? dto.OverrideCompleteAction(dto) : new fromActions.GetComplete(dto))),
          catchError(e =>
            of(!!dto.OverrideErrorAction ? dto.OverrideErrorAction(dto, getAskookErrorCode(e)) : new fromActions.GetError(dto)),
          ),
        ),
      ),
    ),
  );

  //#endregion

  constructor(
    private actions$: Actions,
    private client: HttpClient,
  ) {}

  private getURIWithId(dto: DTO<any>): string {
    let queryParams: string[] = [];

    if (!!dto.UId) {
      queryParams.push(`id=${encodeURIComponent(dto.UId)}`);
    }

    if (!!dto.RouteParams) {
      const pairs = dto.RouteParams.map(p => `${p[0]}=${encodeURIComponent(p[1].toString())}`);
      queryParams = [...queryParams, ...pairs];
    }

    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

    return `${getURI(dto)}${queryString}`;
  }
}
