import { Component } from '@angular/core';
@Component({
  selector: 'ocf-anonymous-template-component',
  styleUrls: ['./anonymous-template.component.scss'],
  template: `
    <div class="cui-app-main" id="cui-app-main">
      <div class="logo">
        <a href="https://companial.com">
          <img alt="Companial" src="../../../../../assets/companialLogo.svg" />
        </a>
      </div>
      <router-outlet></router-outlet>
    </div>
  `,
})
export class AnonymousTemplateComponent {}
