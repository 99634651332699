import { NotificationMessage } from '@1clickfactory/notifications';
import { showCustomNotification, showNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { UserInfoService } from './user-info.service';
import { UserResponse } from './user.model';
import * as fromActions from './users.actions';
import { UsersService } from './users.service';

@Injectable()
export class UsersEffects {
  onGetUsersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUsersList),
      mergeMap(() =>
        this.usersService.getAll().pipe(
          map((users: UserResponse[]) => fromActions.getUsersListComplete({ users })),
          catchError(({ error }) => of(fromActions.getUserListError({ error }))),
        ),
      ),
    ),
  );

  onGetUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUser),
      mergeMap(({ id }) =>
        this.usersService.get(id).pipe(
          map((user: UserResponse) => fromActions.getUserComplete({ user })),
          catchError(({ error }) => of(fromActions.getUserError({ error }))),
        ),
      ),
    ),
  );

  onCreateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createUser),
      mergeMap(({ model }) =>
        this.usersService.create(model).pipe(
          map((createdUser: UserResponse) => fromActions.createUserComplete({ createdUser, redirectUrl: `users/${createdUser.id}/roles` })),
          catchError(({ error }) => of(fromActions.createUserError({ error }))),
        ),
      ),
    ),
  );

  onUpdateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateUser),
      mergeMap(({ id, model }) =>
        this.usersService.update(id, model).pipe(
          map((updatedUser: UserResponse) => fromActions.updateUserComplete({ updatedUser })),
          catchError(({ error }) => of(fromActions.updateUserError({ error }))),
        ),
      ),
    ),
  );

  onChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changePassword),
      mergeMap(({ model }) =>
        this.userInfoService.changePassword(model).pipe(
          map(() => fromActions.changePasswordComplete({ redirectUrl: 'settings' })),
          catchError(({ error }) => of(fromActions.changePasswordError({ error }))),
        ),
      ),
    ),
  );

  onMarkforDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.markforDeletion),
      switchMap(({ id }) =>
        this.usersService.markforDeletion(id).pipe(
          map(() => fromActions.markforDeletionComplete({ redirectUrl: 'users' })),
          catchError(({ error }) => of(fromActions.markforDeletionError({ error }))),
        ),
      ),
    ),
  );

  onResetTwoFactor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetTwoFactor),
      switchMap(({ id }) =>
        this.usersService.resetTwoFactoy(id).pipe(
          map(() => fromActions.resetTwoFactorComplete({ redirectUrl: 'users' })),
          catchError(({ error }) => of(fromActions.resetTwoFactorError({ error }))),
        ),
      ),
    ),
  );

  onBlocking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.blockUnblockUser),
      switchMap(({ id, isBlocking }) =>
        this.usersService.blocking(id, isBlocking).pipe(
          map(() => fromActions.getUsersList()),
          catchError(({ error }) => of(fromActions.blockUnblockUserError({ error }))),
        ),
      ),
    ),
  );

  onChangeDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeDevice),
      switchMap(() =>
        this.usersService.changeDevice().pipe(
          map(data => fromActions.changeDeviceComplete({ data })),
          catchError(({ error }) => of(fromActions.changeDeviceError({ error }))),
        ),
      ),
    ),
  );

  onVerifyCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.verifyCode),
      switchMap(({ code }) =>
        this.usersService.verifyCode({ code }).pipe(
          map(({ isValid }) => {
            if (isValid) {
              return fromActions.verifyCodeComplete({ redirectUrl: 'settings' });
            } else {
              return fromActions.verifyCodeError({ error: { errorCode: '400', errorMessage: 'Code is not valid.' } });
            }
          }),
          catchError(({ error }) => of(fromActions.verifyCodeError({ error }))),
        ),
      ),
    ),
  );

  onSuccessComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changePasswordComplete, fromActions.verifyCodeComplete),
      tap(({ redirectUrl }) => this.router.navigate([redirectUrl])),
      map(() => showNotification({ message: NotificationMessage.SubmitSuccess })),
    ),
  );

  onCreateUserComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createUserComplete),
      tap(({ redirectUrl }) => this.router.navigate([redirectUrl])),
      map(() => showCustomNotification({ message: 'User Created: Please, assign permissions.' })),
    ),
  );

  onDeleteComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.markforDeletionComplete),
      tap(({ redirectUrl }) => this.router.navigate([redirectUrl])),
      map(() => showNotification({ message: NotificationMessage.DeleteSuccess })),
    ),
  );

  onResetTwoFactorComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetTwoFactorComplete),
      tap(({ redirectUrl }) => this.router.navigate([redirectUrl])),
      map(() => showNotification({ message: NotificationMessage.ResetTwoFaSuccess })),
    ),
  );

  onShowError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.getUserError,
        fromActions.createUserError,
        fromActions.updateUserError,
        fromActions.changePasswordError,
        fromActions.markforDeletionError,
        fromActions.blockUnblockUserError,
        fromActions.resetTwoFactorError,
        fromActions.changeDeviceError,
        fromActions.verifyCodeError,
      ),
      map(({ error }) => showCustomNotification({ message: error.errorMessage })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly usersService: UsersService,
    private readonly userInfoService: UserInfoService,
  ) {}
}
