import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ocf-alert',
  template: `
    <ng-container [ngSwitch]="type">
      <mat-icon *ngSwitchCase="'warning'">warning</mat-icon>
      <mat-icon *ngSwitchCase="'info'">info</mat-icon>
      <mat-icon *ngSwitchCase="'success'">check_circle</mat-icon>
      <mat-icon *ngSwitchCase="'danger'">error</mat-icon>
    </ng-container>

    <ng-content></ng-content>
  `,
  styles: [
    `
      :host {
        width: 100%;
        border: 1px solid #fff;
        border-radius: 3px;
        display: flex;
        justify-content: left;
        align-items: center;
      }

      mat-icon {
        min-width: 25px;
        margin: 0.25em 0.5em;
      }

      :host[type='warning'] {
        color: var(--color-warning);
        border-color: var(--color-warning);
      }

      :host[type='danger'] {
        color: var(--color-warning);
        border-color: var(--color-warning);
      }

      :host[type='success'] {
        color: var(--color-success);
        border-color: var(--color-success);
      }

      :host[type='info'] {
        color: var(--color-info);
        border-color: var(--color-info);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @Input()
  text: string = '';

  @Input()
  type: 'warning' | 'info' | 'success' | 'danger' = 'info';
}
