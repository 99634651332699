import { OldHighlanderService } from '@1clickfactory/data-access';
import { Injectable } from '@angular/core';
import { CartPaymentRequest, CartPaymentResponse } from '@appState';
import { Observable } from 'rxjs';

@Injectable()
export class CartPaymentService {
  constructor(private oldHighlanderService: OldHighlanderService) {}

  post(model: CartPaymentRequest): Observable<CartPaymentResponse> {
    return this.oldHighlanderService.post<CartPaymentRequest, CartPaymentResponse>('cartPayment', model);
  }
}
