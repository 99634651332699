import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import * as fromActions from './announcements.actions';
import { AnnouncementsService } from './announcements.service';
@Injectable()
export class AnnouncementsEffects {
  onGetAnnouncements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.get),
      switchMap(() => this.announcementsService.getAll()),
      map(announcements => fromActions.getComplete({ announcements })),
      catchError(({ error }) => of(fromActions.getError({ err: error }))),
    ),
  );

  onChangeAnnouncementStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeStatus),
      mergeMap(({ id, status }) => this.announcementsService.changeStatus(id, status)),
      map(() => fromActions.changeStatusComplete()),
      catchError(({ error }) => of(fromActions.changeStatusError({ err: error }))),
    ),
  );

  onGetAnnouncementsError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getError),
      map(({ err }) =>
        showCustomNotification({
          message: `Status: ${err.errorCode ?? 'Unknown'}. Message: ${err.errorMessage ?? 'Unknown error occured.'}`,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private announcementsService: AnnouncementsService,
  ) {}
}
