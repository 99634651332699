// noinspection SpellCheckingInspection

export const ENDPOINTS = {
  cart: 'cart',
  cartBulk: 'cart/bulk',
  cartPayment: 'cart/pay',
  credits: 'credits',
  documents: 'documents',
  authorization: 'authorization',
  supportTickets: 'support/tickets',
  supportAttachments: 'support/attachments',
  supportAttachmentUploadUrl: 'support/attachment-upload-url',
  supportCategories: 'support/categories',
  supportSeverities: 'support/severities',
  commonData: 'common-data',
  paymentToken: 'paymenttokens',
};
