import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { AdditionalPartnerRespose } from './impersonation.model';

@Injectable()
export class ImpersonationService {
  constructor(private highlanderService: HighlanderService) {}

  getUserAdditionalPartners(): Observable<AdditionalPartnerRespose[]> {
    return this.highlanderService.get<AdditionalPartnerRespose[]>('user-info/additional-partners');
  }
}
