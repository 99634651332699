import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangePasswordRequest } from './user.model';

@Injectable()
export class UserInfoService {
  private readonly path = (url = ''): string => `user-info/${url}`;

  constructor(private highlanderService: HighlanderService) {}

  changePassword(model: ChangePasswordRequest): Observable<void> {
    return this.highlanderService.post<ChangePasswordRequest, void>(this.path(`change-password`), model);
  }
}
