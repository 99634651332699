import { OldHighlanderService } from '@1clickfactory/data-access';
import { Injectable } from '@angular/core';
import { Credit } from '@appState';
import { Observable } from 'rxjs';

@Injectable()
export class CreditService {
  constructor(private oldHighlanderService: OldHighlanderService) {}

  get(): Observable<Credit> {
    return this.oldHighlanderService.get('credits', '');
  }
}
