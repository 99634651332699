import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';
import { PermissionTypes } from '../authorization/permissions/permissions.model';

export const selectModuleState = createSelector(getRootState, p => p.identity);
export const selectIdentity = createSelector(selectModuleState, s => s.user && s.user.userinfo);

export const selectIsLoading = createSelector(selectModuleState, s => s.isLoading);
export const selectIsAuthenticated = createSelector(selectModuleState, s => !!s.user);
export const selectUserId = createSelector(selectIdentity, identity => identity?.id ?? null);
export const selectPartnerId = createSelector(selectIdentity, identity => identity?.partnerId ?? null);
export const selectIsMapped = createSelector(selectIdentity, identity => identity?.isMapped ?? false);
export const selectIsSr = createSelector(selectIdentity, identity => identity?.isTr ?? false);
export const selectAgreements = createSelector(selectIdentity, identity => identity?.agreements ?? []);
export const selectTokenExpiration = createSelector(selectModuleState, state => state.user?.expires_at ?? null);
export const selectUserPermissions = createSelector(selectModuleState, state => state.user?.permissions ?? []);
export const selectUserPermissionName = createSelector(selectUserPermissions, permissions => permissions.map(p => p.name));

export const selectHasPermission = (requiredPermissions: PermissionTypes[]) =>
  createSelector(selectUserPermissionName, userPermissions =>
    userPermissions.some(userPermission => requiredPermissions.includes(userPermission)),
  );
