import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentListFilterByDateRange, DocumentResponse } from '../document.model';

@Injectable()
export class DocumentsService {
  constructor(private highlanderService: HighlanderService) {}

  getAll(range: DocumentListFilterByDateRange): Observable<DocumentResponse[]> {
    return this.highlanderService.getAll<DocumentResponse>('documents', { params: { range: range.toLowerCase() } });
  }

  getDownloadUrlById(id: string): Observable<{ URL: string }> {
    return this.highlanderService.get<{ URL: string }>(`documents/${id}/download`);
  }

  shareDocument(userFriendlyName: string): Observable<{ URL: string; document: DocumentResponse }> {
    return this.highlanderService.get<{ URL: string; document: DocumentResponse }>(`share-document/${userFriendlyName}`);
  }
}
