import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CuiButtonsModule, CuiCardModule } from 'cui-components';
import { ConfirmationModalComponent } from './confirmation-modal.component';
import { ConfirmationModalEffects } from './state/confirmation-modal.effects';

@NgModule({
  imports: [CommonModule, CuiCardModule, CuiButtonsModule, EffectsModule.forFeature([ConfirmationModalEffects])],
  declarations: [ConfirmationModalComponent],
  providers: [ConfirmationModalEffects],
})
export class ConfirmationModalModule {}
