import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { createAction, props } from '@ngrx/store';
import { DocumentListFilterByDateRange, IDocument } from '../document.model';

const NAMESPACE = '[Documents]';

export const query = createAction(`${NAMESPACE} Query`, props<{ range: DocumentListFilterByDateRange }>());
export const queryComplete = createAction(`${NAMESPACE} QueryComplete`, props<{ documentsList: IDocument[] }>());
export const queryError = createAction(`${NAMESPACE} QueryError`, props<{ err: HighlanderCoreErrorResponse }>());

export const copyExternalLink = createAction(`${NAMESPACE} CopyExternalLink`, props<{ userFriendlyName: string }>());

export const downloadFile = createAction(`${NAMESPACE} DownloadFile`, props<{ id: string }>());
export const downloadFileComplete = createAction(`${NAMESPACE} DownloadFileComplete`, props<{ url: string }>());
export const downloadFileLinkError = createAction(`${NAMESPACE} DownloadFileLinkError`);

export const shareDocument = createAction(`${NAMESPACE} ShareDocument`, props<{ userFriendlyName: string }>());
export const shareDocumentComplete = createAction(`${NAMESPACE} ShareDocumentComplete`, props<{ url: string }>());
export const shareDocumentError = createAction(`${NAMESPACE} ShareDocumentLinkError`);
