import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { impersonationActions } from '@appState';
import { CookieAuth } from '@highlander/common/helpers/authentication';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { manageCurrentAddressSessionStorage } from '../cookies/cookies';

@Injectable()
export class TokenExpirationInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401 && error.error.errorCode !== '401-999' && request.url.endsWith('refresh')) {
              // 401-999 means that refresh token failed and this step needs to be skipped.
              this.store.dispatch(impersonationActions.clearPartner());
              manageCurrentAddressSessionStorage(undefined);
              CookieAuth.logout();
              return of();
            }
          }

          if (error.status === 401) {
            manageCurrentAddressSessionStorage(location.pathname);

            CookieAuth.login();
            return of();
          }
        },
      }),
    );
  }
}
