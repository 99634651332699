import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PermissionTypes, identitySelectors } from '@appState';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { AppState } from '../../app.store';

@Injectable()
export class PermissionsGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const requiredPermissions: PermissionTypes[] = route.data['permissions'];

    if (!requiredPermissions?.length) {
      return true;
    }

    return this.store.select(identitySelectors.selectIsLoading).pipe(
      filter(isLoading => !isLoading),
      first(),
      switchMap(() =>
        this.store.select(identitySelectors.selectHasPermission(requiredPermissions)).pipe(
          first(),
          map((hasUserPermissions: boolean) => {
            console.assert(hasUserPermissions, 'Unauthorized - permission requirement not matched. Access denied.');

            if (!hasUserPermissions) {
              this.router.navigate(['/', 'page', '403-forbidden']);
            }

            return hasUserPermissions;
          }),
        ),
      ),
    );
  }
}
