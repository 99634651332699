import { AlertComponent } from '@1clickfactory/simple-ui/components/alert/alert.component'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { CommonModule } from '@angular/common'

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [AlertComponent],
  exports: [AlertComponent],
})
export class OcfAlertModule {}
