import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { createAction, props } from '@ngrx/store';
import { PublicAgreement, SignedAgreemenVersion, SignedAgreementListItem } from './agreement.model';

const NAMESPACE = '[Agreements]';

export const getSignedAgreements = createAction(`${NAMESPACE} GetSignedAgreements`);
export const getSignedAgreementsComplete = createAction(
  `${NAMESPACE} GetSignedAgreementsComplete`,
  props<{ agreements: SignedAgreementListItem[] }>(),
);
export const getSignedAgreementsError = createAction(
  `${NAMESPACE} GetSignedAgreementsError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const getSignedAgreementVersion = createAction(
  `${NAMESPACE} GetSignedAgreementVersion`,
  props<{ agreementId: string; versionId: string }>(),
);
export const getSignedAgreementVersionComplete = createAction(
  `${NAMESPACE} GetSignedAgreementVersionComplete`,
  props<{ agreement: SignedAgreemenVersion }>(),
);
export const getSignedAgreementVersionError = createAction(
  `${NAMESPACE} GetSignedAgreementVersionError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const getPublishedAgreement = createAction(`${NAMESPACE} GetPublishedAgreement`, props<{ publicLink: string }>());
export const getPublishedAgreementComplete = createAction(
  `${NAMESPACE} GetPublishedAgreementComplete`,
  props<{ agreement: PublicAgreement }>(),
);
export const getPublishedAgreementError = createAction(
  `${NAMESPACE} GetPublishedAgreementError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const getAgreementContent = createAction(`${NAMESPACE} GetAgreementContent`, props<{ agreementId: string }>());
export const getAgreementContentComplete = createAction(`${NAMESPACE} GetAgreementContentComplete`, props<{ content: any }>());
export const getAgreementContentError = createAction(
  `${NAMESPACE} GetAgreementContentError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);
