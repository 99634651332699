import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IDocument } from '../document.model';
import * as fromActions from './documents-list.actions';

export interface State extends EntityState<IDocument> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<IDocument> = createEntityAdapter<IDocument>({
  selectId: (document: IDocument) => document.id.toLocaleLowerCase(),
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const documentsReducer = createReducer(
  initialState,
  on(fromActions.query, state => ({ ...state, isLoading: true })),
  on(fromActions.queryComplete, (state, { documentsList }) => adapter.setAll(documentsList, { ...state, isLoading: false })),
  on(fromActions.queryError, state => adapter.removeAll({ ...state, isLoading: false })),
);
