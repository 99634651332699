import { Action } from '@ngrx/store';
import { DTO } from './backend.models';

export enum Actions {
  // Query - GET
  Query = '[Backend] Query',
  QueryComplete = '[Backend] QueryComplete',
  QueryError = '[Form] QueryError',

  // GET - GET
  Get = '[Backend] Get',
  GetBlob = '[Backend] GetBlob',
  GetComplete = '[Backend] GetComplete',
  GetError = '[Form] GetError',

  // Create - POST
  Create = '[Backend] Post',
  CreateComplete = '[Backend] PostComplete',
  CreateError = '[Form] PostError',

  // Update - PUT
  Update = '[Backend] Update',
  UpdateComplete = '[Backend] UpdateComplete',
  UpdateError = '[Form] UpdateError',

  // Delete - POST
  Delete = '[Backend] Delete',
  DeleteComplete = '[Backend] DeleteComplete',
  DeleteError = '[Backend] DeleteError',
}

//#region Query
export class Query implements Action {
  readonly type = Actions.Query;

  constructor(public payload: DTO<any>) {}
}

export class QueryComplete implements Action {
  readonly type = Actions.QueryComplete;

  constructor(public payload: DTO<any>) {}
}

export class QueryError implements Action {
  readonly type = Actions.QueryError;

  constructor(public payload: DTO<any>) {}
}

//#endregion

//#region GET
export class Get implements Action {
  readonly type = Actions.Get;

  constructor(public payload: DTO<any>) {}
}

export class GetBlob implements Action {
  readonly type = Actions.GetBlob;

  constructor(public payload: DTO<Blob>) {}
}

export class GetComplete implements Action {
  readonly type = Actions.GetComplete;

  constructor(public payload: DTO<any>) {}
}

export class GetError implements Action {
  readonly type = Actions.GetError;

  constructor(public payload?: DTO<any>) {}
}

//#endregion

//#region POST
export class Create implements Action {
  readonly type = Actions.Create;

  constructor(public payload: DTO<any>) {}
}

export class CreateComplete implements Action {
  readonly type = Actions.CreateComplete;

  constructor(public payload: DTO<any>) {}
}

export class CreateError implements Action {
  readonly type = Actions.CreateError;

  constructor(public payload: DTO<any>) {}
}

//#endregion

//#region PUT
export class Update implements Action {
  readonly type = Actions.Update;

  constructor(public payload: DTO<any>) {}
}

export class UpdateComplete implements Action {
  readonly type = Actions.UpdateComplete;

  constructor(public payload: DTO<any>) {}
}

export class UpdateError implements Action {
  readonly type = Actions.UpdateError;

  constructor(public payload: DTO<any>) {}
}

//#endregion

//#region Delete
export class Delete implements Action {
  readonly type = Actions.Delete;

  constructor(public payload: DTO<string>) {}
}

export class DeleteComplete implements Action {
  readonly type = Actions.DeleteComplete;

  constructor(public payload: string) {}
}

export class DeleteError implements Action {
  readonly type = Actions.DeleteError;

  constructor(public payload: string) {}
}

//#endregion

export type All =
  | Query
  | QueryComplete
  | QueryError
  | Get
  | GetBlob
  | GetComplete
  | GetError
  | Create
  | CreateComplete
  | CreateError
  | Update
  | UpdateComplete
  | UpdateError
  | Delete
  | DeleteComplete
  | DeleteError;
