import { stringSortFn } from '@1clickfactory/common/helpers/sort.helpers';

interface State {
  id: number;
  countryId: number;
  name: string;
}

/**
 * ATTENTION!
 * NAV also uses this list - please notify them before making any changes!
 */
export const OcfStatesRepository: State[] = [
  {
    id: 1,
    countryId: 248,
    name: 'Alabama',
  },
  {
    id: 2,
    countryId: 248,
    name: 'Alaska',
  },
  {
    id: 3,
    countryId: 248,
    name: 'Arizona',
  },
  {
    id: 4,
    countryId: 248,
    name: 'Arkansas',
  },
  {
    id: 5,
    countryId: 248,
    name: 'California',
  },
  {
    id: 6,
    countryId: 248,
    name: 'Colorado',
  },
  {
    id: 7,
    countryId: 248,
    name: 'Connecticut',
  },
  {
    id: 8,
    countryId: 248,
    name: 'Delaware',
  },
  {
    id: 9,
    countryId: 248,
    name: 'Florida',
  },
  {
    id: 10,
    countryId: 248,
    name: 'Georgia',
  },
  {
    id: 11,
    countryId: 248,
    name: 'Hawaii',
  },
  {
    id: 12,
    countryId: 248,
    name: 'Idaho',
  },
  {
    id: 13,
    countryId: 248,
    name: 'Illinois',
  },
  {
    id: 14,
    countryId: 248,
    name: 'Iowa',
  },
  {
    id: 15,
    countryId: 248,
    name: 'Kansas',
  },
  {
    id: 16,
    countryId: 248,
    name: 'Kentucky',
  },
  {
    id: 17,
    countryId: 248,
    name: 'Louisiana',
  },
  {
    id: 18,
    countryId: 248,
    name: 'Maine',
  },
  {
    id: 19,
    countryId: 248,
    name: 'Maryland',
  },
  {
    id: 20,
    countryId: 248,
    name: 'Massachusetts',
  },
  {
    id: 21,
    countryId: 248,
    name: 'Michigan',
  },
  {
    id: 22,
    countryId: 248,
    name: 'Minnesota',
  },
  {
    id: 23,
    countryId: 248,
    name: 'Mississippi',
  },
  {
    id: 24,
    countryId: 248,
    name: 'Missouri',
  },
  {
    id: 25,
    countryId: 248,
    name: 'Montana',
  },
  {
    id: 26,
    countryId: 248,
    name: 'Nebraska',
  },
  {
    id: 27,
    countryId: 248,
    name: 'Nevada',
  },
  {
    id: 28,
    countryId: 248,
    name: 'New Hampshire',
  },
  {
    id: 29,
    countryId: 248,
    name: 'New Jersey',
  },
  {
    id: 30,
    countryId: 248,
    name: 'New Mexico',
  },
  {
    id: 31,
    countryId: 248,
    name: 'New York',
  },
  {
    id: 32,
    countryId: 248,
    name: 'North Carolina',
  },
  {
    id: 33,
    countryId: 248,
    name: 'North Dakota',
  },
  {
    id: 34,
    countryId: 248,
    name: 'Ohio',
  },
  {
    id: 35,
    countryId: 248,
    name: 'Oklahoma',
  },
  {
    id: 36,
    countryId: 248,
    name: 'Oregon',
  },
  {
    id: 37,
    countryId: 248,
    name: 'Pennsylvania',
  },
  {
    id: 38,
    countryId: 248,
    name: 'Rhode Island',
  },
  {
    id: 39,
    countryId: 248,
    name: 'South Carolina',
  },
  {
    id: 40,
    countryId: 248,
    name: 'South Dakota',
  },
  {
    id: 41,
    countryId: 248,
    name: 'Tennessee',
  },
  {
    id: 42,
    countryId: 248,
    name: 'Texas',
  },
  {
    id: 43,
    countryId: 248,
    name: 'Utah',
  },
  {
    id: 44,
    countryId: 248,
    name: 'Vermont',
  },
  {
    id: 45,
    countryId: 248,
    name: 'Virginia',
  },
  {
    id: 46,
    countryId: 248,
    name: 'Washington',
  },
  {
    id: 47,
    countryId: 248,
    name: 'West Virginia',
  },
  {
    id: 48,
    countryId: 248,
    name: 'Wisconsin',
  },
  {
    id: 49,
    countryId: 248,
    name: 'Wyoming',
  },
  {
    id: 50,
    countryId: 248,
    name: 'Indiana',
  },
  {
    id: 51,
    countryId: 148,
    name: 'Federal District',
  },
  {
    id: 52,
    countryId: 148,
    name: 'Mexico State',
  },
  {
    id: 53,
    countryId: 148,
    name: 'Nuevo Leon',
  },
  {
    id: 54,
    countryId: 148,
    name: 'Jalisco',
  },
  {
    id: 55,
    countryId: 148,
    name: 'Campeche',
  },
  {
    id: 56,
    countryId: 148,
    name: 'Veracruz',
  },
  {
    id: 57,
    countryId: 148,
    name: 'Tabasco',
  },
  {
    id: 58,
    countryId: 148,
    name: 'Guanajuato',
  },
  {
    id: 59,
    countryId: 148,
    name: 'Puebla',
  },
  {
    id: 60,
    countryId: 148,
    name: 'Coahuila',
  },
  {
    id: 61,
    countryId: 148,
    name: 'Tamaulipas',
  },
  {
    id: 62,
    countryId: 148,
    name: 'Chihuahua',
  },
  {
    id: 63,
    countryId: 148,
    name: 'Sonora',
  },
  {
    id: 64,
    countryId: 148,
    name: 'Baja California',
  },
  {
    id: 65,
    countryId: 148,
    name: 'Michoacan',
  },
  {
    id: 66,
    countryId: 148,
    name: 'Sinaloa',
  },
  {
    id: 67,
    countryId: 148,
    name: 'Chiapas',
  },
  {
    id: 68,
    countryId: 148,
    name: 'Queretaro',
  },
  {
    id: 69,
    countryId: 148,
    name: 'San Luis Potosi',
  },
  {
    id: 70,
    countryId: 148,
    name: 'Hidalgo',
  },
  {
    id: 71,
    countryId: 148,
    name: 'Oaxaca',
  },
  {
    id: 72,
    countryId: 148,
    name: 'Guerrero',
  },
  {
    id: 73,
    countryId: 148,
    name: 'Quintana Roo',
  },
  {
    id: 74,
    countryId: 148,
    name: 'Yucatan',
  },
  {
    id: 75,
    countryId: 148,
    name: 'Durango',
  },
  {
    id: 76,
    countryId: 148,
    name: 'Morelos',
  },
  {
    id: 77,
    countryId: 148,
    name: 'Aguascalientes',
  },
  {
    id: 78,
    countryId: 148,
    name: 'Zacatecas',
  },
  {
    id: 79,
    countryId: 148,
    name: 'Nayarit',
  },
  {
    id: 80,
    countryId: 148,
    name: 'Colima',
  },
  {
    id: 81,
    countryId: 148,
    name: 'Baja California Sur',
  },
  {
    id: 82,
    countryId: 148,
    name: 'Tlaxcala',
  },
  {
    id: 83,
    countryId: 33,
    name: 'Amazonas',
  },
  {
    id: 84,
    countryId: 33,
    name: 'Para',
  },
  {
    id: 85,
    countryId: 33,
    name: 'Mato Grosso',
  },
  {
    id: 86,
    countryId: 33,
    name: 'Minas Gerais',
  },
  {
    id: 87,
    countryId: 33,
    name: 'Bahia',
  },
  {
    id: 88,
    countryId: 33,
    name: 'Mato Grosso do Sul',
  },
  {
    id: 89,
    countryId: 33,
    name: 'Goias',
  },
  {
    id: 90,
    countryId: 33,
    name: 'Maranhao',
  },
  {
    id: 91,
    countryId: 33,
    name: 'Rio Grande do Sul',
  },
  {
    id: 92,
    countryId: 33,
    name: 'Tocantins',
  },
  {
    id: 93,
    countryId: 33,
    name: 'Piaui',
  },
  {
    id: 94,
    countryId: 33,
    name: 'Sao Paolo',
  },
  {
    id: 95,
    countryId: 33,
    name: 'Rondonia',
  },
  {
    id: 96,
    countryId: 33,
    name: 'Roraima',
  },
  {
    id: 97,
    countryId: 33,
    name: 'Parana',
  },
  {
    id: 98,
    countryId: 33,
    name: 'Acre',
  },
  {
    id: 99,
    countryId: 33,
    name: 'Ceara',
  },
  {
    id: 100,
    countryId: 33,
    name: 'Amapa',
  },
  {
    id: 101,
    countryId: 33,
    name: 'Pernambruco',
  },
  {
    id: 102,
    countryId: 33,
    name: 'Santa Catarina',
  },
  {
    id: 103,
    countryId: 33,
    name: 'Paraiba',
  },
  {
    id: 104,
    countryId: 33,
    name: 'Rio Grande do Norte',
  },
  {
    id: 105,
    countryId: 33,
    name: 'Epirito Santo',
  },
  {
    id: 106,
    countryId: 33,
    name: 'Rio De Janeiro',
  },
  {
    id: 107,
    countryId: 33,
    name: 'Algoas',
  },
  {
    id: 108,
    countryId: 33,
    name: 'Sergipe',
  },
  {
    id: 109,
    countryId: 33,
    name: 'Distrito Federal',
  },
  {
    id: 110,
    countryId: 109,
    name: 'Andaman and Nicobar Islands',
  },
  {
    id: 111,
    countryId: 109,
    name: 'Andhra Pradesh',
  },
  {
    id: 112,
    countryId: 109,
    name: 'Arunachal Pradesh',
  },
  {
    id: 113,
    countryId: 109,
    name: 'Assam',
  },
  {
    id: 114,
    countryId: 109,
    name: 'Bihar',
  },
  {
    id: 115,
    countryId: 109,
    name: 'Chadigarh',
  },
  {
    id: 116,
    countryId: 109,
    name: 'Chhattisgarth',
  },
  {
    id: 117,
    countryId: 109,
    name: 'Dadra and Nagar Haveli',
  },
  {
    id: 118,
    countryId: 109,
    name: 'Daman and Diu',
  },
  {
    id: 119,
    countryId: 109,
    name: 'National Capital Territory of ',
  },
  {
    id: 120,
    countryId: 109,
    name: 'Goa',
  },
  {
    id: 121,
    countryId: 109,
    name: 'Gujarat',
  },
  {
    id: 122,
    countryId: 109,
    name: 'Haryana',
  },
  {
    id: 123,
    countryId: 109,
    name: 'Himachal Pradesh',
  },
  {
    id: 124,
    countryId: 109,
    name: 'Jammu and Kashmir',
  },
  {
    id: 125,
    countryId: 109,
    name: 'Jharkhand',
  },
  {
    id: 126,
    countryId: 109,
    name: 'Karnataka',
  },
  {
    id: 127,
    countryId: 109,
    name: 'Kerala',
  },
  {
    id: 128,
    countryId: 109,
    name: 'Lakshadweep',
  },
  {
    id: 129,
    countryId: 109,
    name: 'Madhya Pradesh',
  },
  {
    id: 130,
    countryId: 109,
    name: 'Maharashtra',
  },
  {
    id: 131,
    countryId: 109,
    name: 'Manipur',
  },
  {
    id: 132,
    countryId: 109,
    name: 'Meghalaya',
  },
  {
    id: 133,
    countryId: 109,
    name: 'Mizoram',
  },
  {
    id: 134,
    countryId: 109,
    name: 'Nagaland',
  },
  {
    id: 135,
    countryId: 109,
    name: 'Odisha',
  },
  {
    id: 136,
    countryId: 109,
    name: 'Puducherry',
  },
  {
    id: 137,
    countryId: 109,
    name: 'Punjab',
  },
  {
    id: 138,
    countryId: 109,
    name: 'Rajasthan',
  },
  {
    id: 139,
    countryId: 109,
    name: 'Sikkim',
  },
  {
    id: 140,
    countryId: 109,
    name: 'Tamil Nadu',
  },
  {
    id: 141,
    countryId: 109,
    name: 'Telangana',
  },
  {
    id: 142,
    countryId: 109,
    name: 'Tripura',
  },
  {
    id: 143,
    countryId: 109,
    name: 'Uttar Pradesh',
  },
  {
    id: 144,
    countryId: 109,
    name: 'Uttarakhand',
  },
  {
    id: 145,
    countryId: 109,
    name: 'West Bengal',
  },
  {
    id: 146,
    countryId: 15,
    name: 'New South Wales',
  },
  {
    id: 147,
    countryId: 15,
    name: 'Victoria',
  },
  {
    id: 148,
    countryId: 15,
    name: 'Queensland',
  },
  {
    id: 149,
    countryId: 15,
    name: 'Western Australia',
  },
  {
    id: 150,
    countryId: 15,
    name: 'South Australia',
  },
  {
    id: 151,
    countryId: 15,
    name: 'Australian Capital Territory',
  },
  {
    id: 152,
    countryId: 15,
    name: 'Tasmania',
  },
  {
    id: 153,
    countryId: 15,
    name: 'Northern Territory',
  },
  {
    id: 154,
    countryId: 90,
    name: 'North Rhine-Westphalia',
  },
  {
    id: 155,
    countryId: 90,
    name: 'Bavaria',
  },
  {
    id: 156,
    countryId: 90,
    name: 'Baden-Wuttemberg',
  },
  {
    id: 157,
    countryId: 90,
    name: 'Lower Saxony',
  },
  {
    id: 158,
    countryId: 90,
    name: 'Hesse',
  },
  {
    id: 159,
    countryId: 90,
    name: 'Rhineland-Palatinate',
  },
  {
    id: 160,
    countryId: 90,
    name: 'Berlin',
  },
  {
    id: 161,
    countryId: 90,
    name: 'Saxony',
  },
  {
    id: 162,
    countryId: 90,
    name: 'Hamburg',
  },
  {
    id: 163,
    countryId: 90,
    name: 'Schleswig-Holstein',
  },
  {
    id: 164,
    countryId: 90,
    name: 'Brandenburg',
  },
  {
    id: 165,
    countryId: 90,
    name: 'Saxony-Anhalt',
  },
  {
    id: 166,
    countryId: 90,
    name: 'Thuringia',
  },
  {
    id: 167,
    countryId: 90,
    name: 'Meckenburg-Vorpommem',
  },
  {
    id: 168,
    countryId: 90,
    name: 'Saarland',
  },
  {
    id: 169,
    countryId: 90,
    name: 'Bremen',
  },
  {
    id: 170,
    countryId: 157,
    name: 'Thaninthayi',
  },
  {
    id: 171,
    countryId: 157,
    name: 'Mon',
  },
  {
    id: 172,
    countryId: 157,
    name: 'Yangon',
  },
  {
    id: 173,
    countryId: 157,
    name: 'Ayeyarwaddy',
  },
  {
    id: 174,
    countryId: 157,
    name: 'Kayin',
  },
  {
    id: 175,
    countryId: 157,
    name: 'Bago',
  },
  {
    id: 176,
    countryId: 157,
    name: 'Rakhine',
  },
  {
    id: 177,
    countryId: 157,
    name: 'Magwe',
  },
  {
    id: 178,
    countryId: 157,
    name: 'Mandalay',
  },
  {
    id: 179,
    countryId: 157,
    name: 'Kayah',
  },
  {
    id: 180,
    countryId: 157,
    name: 'Shan',
  },
  {
    id: 181,
    countryId: 157,
    name: 'Sagain',
  },
  {
    id: 182,
    countryId: 157,
    name: 'Chin',
  },
  {
    id: 183,
    countryId: 157,
    name: 'Kachin',
  },
  {
    id: 184,
    countryId: 248,
    name: 'Other',
  },
  {
    id: 185,
    countryId: 148,
    name: 'Other',
  },
  {
    id: 186,
    countryId: 33,
    name: 'Other',
  },
  {
    id: 187,
    countryId: 109,
    name: 'Other',
  },
  {
    id: 188,
    countryId: 15,
    name: 'Other',
  },
  {
    id: 189,
    countryId: 90,
    name: 'Other',
  },
  {
    id: 190,
    countryId: 157,
    name: 'Other',
  },
].sort((a, b) => {
  // "Other" should the last item
  const name1 = a.name === 'Other' ? null : a.name;
  const name2 = b.name === 'Other' ? null : b.name;
  return stringSortFn(name1, name2);
});

export const getState = (id: number): State => OcfStatesRepository.find(state => state.id === id)!;
