import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

export const selectModuleState = createSelector(getRootState, rootState => rootState.announcements);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectAnnouncements = createSelector(selectModuleState, ({ announcements }) => announcements ?? []);

export const selectNotShownAnnouncements = createSelector(selectModuleState, ({ announcements }) => {
  return announcements.filter(ann => !ann.isRead) ?? [];
});
