import { createAction, props } from '@ngrx/store';
import { Account } from './account.model';

const namespace = '[Account]';

export const get = createAction(`${namespace} Get`, props<{ id: string }>());
export const getComplete = createAction(`${namespace} GetComplete`, props<{ account: Account }>());
export const getError = createAction(`${namespace} GetError`);

export const selectAccount = createAction(`${namespace} SelectAccount`, props<{ id: string }>());

export const deleteAccount = createAction(`${namespace} Delete`, props<{ id: string }>());
export const deleteAccountError = createAction(`${namespace} DeleteError`);
export const deleteAccountComplete = createAction(`${namespace} DeleteComplete`, props<{ id: string }>());
