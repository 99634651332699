export function stringSortFn(a: string | null, b: string | null): number {
  const string1 = a?.toLowerCase();
  const string2 = b?.toLowerCase();
  if (!string1) {
    return 1;
  }
  if (!string2) {
    return 1; //TODO FIGURE OUT IF THIS IS CORRECT, MIGHT NEED TO BE REVERSED
  }
  if (string1 < string2) {
    return -1;
  }

  if (string1 > string2) {
    return 1;
  }

  return 0;
}

export function stringSortingDataAccessor<T>(data: T, sortHeaderId: string): string | number {
  const value: unknown = data[sortHeaderId as keyof T];

  switch (typeof value) {
    case 'string':
      return value.toLocaleLowerCase();
    case 'number':
      return value;
    default:
      return value + '';
  }
}

export function customSortingDataAccessor<T>(
  data: T,
  sortHeaderId: string & keyof T,
  customAccessor: { [headerId: string]: (value: unknown) => string },
): string | number {
  if (customAccessor.hasOwnProperty(sortHeaderId)) {
    const callback: (value: unknown) => string = customAccessor[sortHeaderId];
    const sortValue: unknown = data[sortHeaderId];
    return callback(sortValue);
  }
  return stringSortingDataAccessor(data, sortHeaderId);
}
