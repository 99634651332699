import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

import { Choice } from '../../forms2'

@Injectable()
export class ChoiceTranslate {
  constructor(private translateService: TranslateService) {}

  /**
   * Adds translated text to choices
   * @param choices - choice values
   * @param keyFn - function that takes choice value and returns translate key
   */
  translate(choices: string[], keyFn: (choice: string) => string): Observable<Choice[]> {
    return this.translateService.get(choices.map(keyFn)).pipe(
      take(1),
      map(translations =>
        choices.map(c => ({
          value: c,
          text: translations[keyFn(c)],
        })),
      ),
    )
  }
}
