import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import * as fromActions from './active-page.actions'
import { ActivePage } from './active-page.model'

export interface State extends EntityState<ActivePage> {
  isLoading: boolean
}

export const adapter = createEntityAdapter<ActivePage>({
  selectId: (page: ActivePage): string => page?.UId,
})

export const initialState: State = adapter.getInitialState({
  isLoading: false,
})

export const activePageReducer = createReducer(
  initialState,
  on(fromActions.get, state => ({ ...state, isLoading: true })),
  on(fromActions.getComplete, (state, { activePage }) => ({ ...adapter.addOne(activePage, state), isLoading: false })),
  on(fromActions.getError, (state, { id }) => ({ ...adapter.addOne({ UId: id }, state), isLoading: false })),
)

export function reducer(state = initialState, action: Action): State {
  return activePageReducer(state, action)
}
