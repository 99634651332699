import { SubscribingComponent } from '@1clickfactory/common/base-component/subscribing.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonData, commonDataActions, commonDataSelectors } from '@appState';
import { Store, select } from '@ngrx/store';
import { Choice, CuiSimpleSelectComponent } from 'cui-components';
import { Observable, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';

export interface CountryStateForm {
  countryId: FormControl<string>;
  stateId: FormControl<string>;
}

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CuiSimpleSelectComponent],
  selector: 'ocf-country-state',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<ng-container *ngIf="{ countries: (countries$ | async)!, states: (states$ | async)! } as obs$" [formGroup]="formGroup">
    <cui-simple-select formControlName="countryId" label="Country" [choices]="choices.countries" [required]="required"></cui-simple-select>
    <cui-simple-select formControlName="stateId" label="State" [choices]="choices.states"></cui-simple-select>
  </ng-container>`,
})
export class CountryStateComponent extends SubscribingComponent implements OnInit {
  @Input() required: boolean = false;
  @Output() isLoading = new EventEmitter<boolean>();

  countries$: Observable<void> = this.store.pipe(
    select(commonDataSelectors.selectCommonDataList),
    map(data => {
      if (!data.length) {
        this.store.dispatch(commonDataActions.getCommonDataList({ dataType: 'country' }));
      } else {
        const countries = data.filter(object => object.type === 'country');
        const sortedCountries = this.getSortedAlphabetically(countries);
        const countryChoices = this.mapCommonDataToChoices(sortedCountries);
        this.choices = { ...this.choices, countries: [this.choices.countries[0], ...countryChoices] };
      }
    }),
  );

  states$: Observable<void> = this.store.pipe(
    select(commonDataSelectors.selectCommonDataList),
    map(data => {
      if (!data.length) {
        this.store.dispatch(commonDataActions.getCommonDataList({ dataType: 'state' }));
      } else {
        const states = data.filter(object => object.type === 'state');
        this.setStatesChoicesByCountryId(states);
      }
    }),
  );

  choices: { countries: Choice[]; states: Choice[] } = { countries: [{ value: '', text: '' }], states: [{ value: '', text: '' }] };

  get formGroup(): FormGroup<CountryStateForm> {
    return this.cont && (this.cont.control as FormGroup<CountryStateForm>);
  }

  constructor(
    private readonly cont: ControlContainer,
    private readonly store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading.emit(true);
  }

  private setStatesChoicesByCountryId(stateCommonData: CommonData[]): void {
    const countryControl = this.formGroup.controls.countryId;

    countryControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        startWith(countryControl.value),
        takeUntil(this.destroyed$),
        map(countryId => this.mapCommonDataToChoices(stateCommonData.filter(s => s.details.countryId === countryId))),
      )
      .subscribe(filteredStates => {
        this.choices = { ...this.choices, states: [this.choices.states[0], ...filteredStates] };
        this.handleStateControl(filteredStates);
      });
  }

  private handleStateControl(filteredStates: Choice[]): void {
    const stateControl = this.formGroup.controls.stateId;

    if (this.formGroup.status === 'DISABLED' || !filteredStates.length) {
      stateControl.disable();
    } else {
      stateControl.enable();
    }
  }

  private getSortedAlphabetically(data: CommonData[]): CommonData[] {
    return data.sort((a, b) => a.details.name.localeCompare(b.details.name));
  }

  private mapCommonDataToChoices(data: CommonData[]): Choice[] {
    return data.map(d => ({ value: d.id, text: d.details.name }));
  }
}
