import { OldHighlanderService } from '@1clickfactory/data-access';
import { Injectable } from '@angular/core';
import { CartItem } from '@appState';
import { Observable } from 'rxjs';

@Injectable()
export class CartItemsService {
  constructor(private oldHighlanderService: OldHighlanderService) {}

  getAll(): Observable<CartItem[]> {
    return this.oldHighlanderService.getAll<CartItem>('cart');
  }

  post(model: CartItem): Observable<CartItem> {
    return this.oldHighlanderService.post('cart', model);
  }

  delete(id: string): Observable<void> {
    return this.oldHighlanderService.delete('cart', id);
  }
}
