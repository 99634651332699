import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity/src/models'
import { CartItem } from '@appState'

const NAMESPACE = '[CartPayment]'

export const payForCart = createAction(`${NAMESPACE} PayForCart`)
export const payForCartError = createAction(`${NAMESPACE} PayForCartError`)
export const payForCartComplete = createAction(`${NAMESPACE} PayForCartComplete`)
export const payForCartUnsuccessful = createAction(
  `${NAMESPACE} PayForCartUnsuccessful`,
  props<{ cartPaymentError?: string, faultyCartItems?: Update<CartItem>[] }>(),
)
