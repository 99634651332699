import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OCFSimpleUIModule } from '@1clickfactory/simple-ui';
import { FormEffects } from './state/form/form.effects';
import * as fromStore from './state/simple-forms.state';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MaterialDesignModule, PipesModule } from '@1clickfactory/common';
import { StepTextComponent } from '@1clickfactory/forms/components/step-text-component/step-text.component';
import { TextHighlightComponent } from '@1clickfactory/forms/components/text-highlight-component/text-highlight.component';
import { OcfContactsModule } from '@1clickfactory/forms2/ocf-contacts/ocf-contacts.module';
import { CuiErrorsComponent } from 'cui-components';

@NgModule({
  imports: [
    MaterialDesignModule,
    MatButtonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    OCFSimpleUIModule,
    StoreModule.forFeature('forms', fromStore.reducers),
    EffectsModule.forFeature([FormEffects]),
    CuiErrorsComponent,
    PipesModule,
  ],
  declarations: [StepTextComponent, TextHighlightComponent],
  exports: [OcfContactsModule, StepTextComponent, CuiErrorsComponent, TextHighlightComponent],
})
export class SimpleFormsModule {}
