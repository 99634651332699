import { RouterModule, Routes } from '@angular/router';
import { AnonymousTemplateComponent } from './components/anonymous-template/anonymous-template.component';

const routes: Routes = [
  {
    path: '',
    component: AnonymousTemplateComponent,
    children: [
      {
        path: 'forgot-password',
        loadComponent: () => import('./pages/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent),
      },
      {
        path: 'reset-password',
        loadComponent: () => import('./pages/reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
      },
      {
        path: 'application',
        loadComponent: () => import('./pages/user-application/user-application.component').then(m => m.UserApplicationComponent),
      },
      {
        path: 'application/success',
        loadComponent: () =>
          import('./pages/user-application-success/user-application-success.component').then(m => m.UserApplicationSuccessComponent),
      },
    ],
  },
];

export const AnonymousRoutes = RouterModule.forChild(routes);
