import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';
import * as fromReducer from './users.reducer';

export const selectModuleState = createSelector(getRootState, rootState => rootState.users);
export const { selectAll } = fromReducer.adapter.getSelectors(selectModuleState);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectCurrentUser = createSelector(selectModuleState, state =>
  state.selectedUserId ? state.entities[state.selectedUserId] : null,
);
export const selectUsersList = createSelector(selectAll, usersList => usersList ?? []);
export const selectIsUserBlocked = createSelector(selectCurrentUser, user => user?.status === 'Blocked');
export const selectChangeDevice = createSelector(selectModuleState, state => state.changeDevice);
export const selectIsVerifyingCode = createSelector(selectModuleState, state => state.isVerifyingCode);
