import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
// noinspection ES6PreferShortImport
import * as cartPaymentActions from '../cart-payment/cart-payment.actions';
import { CartItem } from './cart-item.model';
import * as fromActions from './cart-items.actions';

export interface State extends EntityState<CartItem> {
  isLoading: boolean;
  cartPaymentError: string;
  isProcessingCartPayment: boolean;
}

export const adapter: EntityAdapter<CartItem> = createEntityAdapter<CartItem>({
  selectId: (cartItem: CartItem) => cartItem.id.toLocaleLowerCase(),
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  cartPaymentError: '',
  isProcessingCartPayment: false,
});

export const cartItemsReducer = createReducer(
  initialState,
  on(fromActions.query, state => ({ ...state, isLoading: true })),
  on(fromActions.queryComplete, (state, { cartItems }) => adapter.setAll(cartItems, { ...state, isLoading: false })),
  on(fromActions.queryError, state => adapter.removeAll({ ...state, isLoading: false })),

  on(fromActions.addToCart, state => ({ ...state, isLoading: true })),
  on(fromActions.addToCartComplete, (state, { cartItem }) => adapter.setOne(cartItem, { ...state, isLoading: false })),
  on(fromActions.addToCartError, state => ({ ...state, isLoading: false })),

  on(fromActions.addToCartBulk, state => ({ ...state, isLoading: true })),
  on(fromActions.addToCartBulkComplete, (state, { cartItems }) => adapter.setAll(cartItems, { ...state, isLoading: false })),
  on(fromActions.addToCartBulkError, state => ({ ...state, isLoading: false })),

  on(fromActions.removeFromCart, (state, { id }) => adapter.removeOne(id, { ...state, isLoading: true })),
  on(fromActions.removeFromCartComplete, fromActions.removeFromCartError, state => ({ ...state, isLoading: false })),

  on(cartPaymentActions.payForCart, state => ({ ...state, isProcessingCartPayment: true })),
  on(cartPaymentActions.payForCartComplete, state => ({ ...state, isProcessingCartPayment: false })),
  on(cartPaymentActions.payForCartUnsuccessful, (state, { cartPaymentError, faultyCartItems }) =>
    adapter.updateMany(faultyCartItems!, { ...state, cartPaymentError: cartPaymentError! }),
  ),
  on(cartPaymentActions.payForCartError, state => ({ ...state, isProcessingCartPayment: false })),
);
