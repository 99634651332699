import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'prefix',
  pure: true,
})
export class PrefixPipe implements PipeTransform {
  transform(value: any, ...args: any[]): string {
    return [...args, value].join('.')
  }
}
