import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { createAction, props } from '@ngrx/store';
import { Announcement, UpdateAnnouncementStatus } from './announcements.model';

const NAMESPACE = '[Announcements]';

export const get = createAction(`${NAMESPACE} Get`);
export const getComplete = createAction(`${NAMESPACE} GetComplete`, props<{ announcements: Announcement[] }>());
export const getError = createAction(`${NAMESPACE} GetError`, props<{ err: HighlanderCoreErrorResponse }>());

export const changeStatus = createAction(`${NAMESPACE} ChangeStatus`, props<{ id: string; status: UpdateAnnouncementStatus }>());
export const changeStatusComplete = createAction(`${NAMESPACE} ChangeStatusComplete`);
export const changeStatusError = createAction(`${NAMESPACE} ChangeStatusError`, props<{ err: HighlanderCoreErrorResponse }>());
