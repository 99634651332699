import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './mm-util.actions';
import { MMUtilService } from './mm-util.service';

@Injectable()
export class MMUtilEffects {
  onSendRequestToJoinMMProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sendRequestToJoinMMProgram),
      switchMap(() =>
        this.mmUtilService.submitRequestToJoinMM().pipe(
          map(() => fromActions.sendRequestToJoinMMProgramComplete()),
          catchError(({ error }) => of(fromActions.sendRequestToJoinMMProgramError({ error }))),
        ),
      ),
    ),
  );

  onShowError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sendRequestToJoinMMProgramError),
      map(({ error }) => showCustomNotification({ message: error.errorMessage })),
    ),
  );

  constructor(
    private readonly actions$: Actions,

    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly mmUtilService: MMUtilService,
  ) {}
}
