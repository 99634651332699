import { createAction, props } from '@ngrx/store'

import { NotificationMessage } from '@1clickfactory/notifications/notification-message'
import { UrlDef } from '@1clickfactory/common'

export const showNotification = createAction(
  '[Notification] ShowStandard',
  props<{ message: NotificationMessage; redirectLink?: UrlDef }>(),
)

export const showCustomNotification = createAction('[Notification] ShowCustom', props<{ message: string; redirectLink?: UrlDef }>())
