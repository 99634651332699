import { ServiceBase } from '@1clickfactory/backend';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backend } from '@env/backend';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { UserApproval } from './user-approval.model';

@Injectable()
export class UserApprovalService extends ServiceBase<UserApproval> {
  constructor(httpClient: HttpClient) {
    super(`${environment.highlanderBffUrl}/gateway/askook/${backend.Users.UserApproval}`, httpClient);
  }

  post(userApproval: UserApproval): Observable<UserApproval> {
    return super.post(userApproval);
  }
}
