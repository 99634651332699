import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserApplication, UserForgotPassword, UserResetPassword } from './user-anonymous.model';

@Injectable()
export class UserAnonymousService {
  constructor(private highlanderService: HighlanderService) {}

  applicate(model: UserApplication, humanCheckToken: string): Observable<void> {
    return this.highlanderService.post<UserApplication, void>('notifications/send-user-application', model, {
      headers: { 'x-recaptcha-token': humanCheckToken },
    });
  }

  sendResetPasswordLink(model: UserForgotPassword, humanCheckToken: string): Observable<void> {
    return this.highlanderService.post<UserForgotPassword, void>('notifications/send-password-reset-link', model, {
      headers: { 'x-recaptcha-token': humanCheckToken },
    });
  }

  resetPassword(model: UserResetPassword, humanCheckToken: string, resetPasswordToken: string): Observable<void> {
    return this.highlanderService.post<UserResetPassword, void>('users/reset-password', model, {
      headers: { 'x-recaptcha-token': humanCheckToken, 'reset-password-token': resetPasswordToken },
    });
  }
}
